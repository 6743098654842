import store from '../../../store/store';
import { CLOSE_PROGRESS_DIALOG, OPEN_PROGRESS_DIALOG } from '../../../constant/actionType';

export const DispatchCloseProgress = () => {
    store.dispatch({
        type: CLOSE_PROGRESS_DIALOG,
        data: {
            isOpen: false
        }
    });
}

export const DispatchOpenProgress = (message) => {
    store.dispatch({
        type: OPEN_PROGRESS_DIALOG,
        data: {
            isOpen: true
        }
    });
}
