import { SignalCellularNullSharp } from '@material-ui/icons';
import {
    ROLES, READ_PERMISSION, CREATE_PERMISSION, UPDATE_PERMISSION, DELETE_PERMISSION, USER_ACCOUNT_DETAIL, EXTERNAL_USER_TECH_ROLE_LAB_IDS,
    CUSTOM_ROLE_JSON, LOGGED_USER_INFO, IS_TECH1_ROLE_ASSIGNED_TO_EXTERNAL_USER
} from '../../../constant/actionType';

const initialState = {
    userInfo: null,
    createPermission: false,
    readPermission: false,
    updatePermission: false,
    deletePermission: false,
    accountInfo: null,
    externalUserTechRoleLabsIds: [],
    customRoleJson: null,
    loggedUser: null,
    isTech1RoleAssignedToExternalUser: false
};

export const RbacReducer = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_PERMISSION:
            return { ...state, createPermission: action.createPermission };

        case UPDATE_PERMISSION:
            return { ...state, updatePermission: action.updatePermission };

        case READ_PERMISSION:
            return { ...state, readPermission: action.readPermission };

        case DELETE_PERMISSION:
            return { ...state, deletePermission: action.deletePermission };

        case ROLES:
            return { ...state, userInfo: action.userInfo };

        case USER_ACCOUNT_DETAIL:
            return { ...state, accountInfo: action.accountDetail };

        case EXTERNAL_USER_TECH_ROLE_LAB_IDS:
            return { ...state, accountInfo: action.labIds };

        case CUSTOM_ROLE_JSON:
            return { ...state, customRoleJson: action.customRoleJson };

        case LOGGED_USER_INFO:
            return { ...state, loggedUser: action.loggedUser };

        case IS_TECH1_ROLE_ASSIGNED_TO_EXTERNAL_USER:
            return { ...state, isTech1RoleAssignedToExternalUser: action.isTech1RoleAssigned };

        default:
            return state;
    }
}
export default RbacReducer;

