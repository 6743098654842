import {
    TEST_REQUESTS, TEST_REQUEST_FORM_SUMMARY_FILTER,
    REFRESH_TEST_REQUESTS_TABLE,
    SELECTED_TEST_REQUESTS_PAGE_OPEN,
    SELECTED_TEST_REQUESTS,
    SAMPLE_GROUP_HISTORY_LOGS,
    SAMPLE_GROUP_HISTORY_LOGS_MODEL
} from "../../../../constant/actionType";
import { DEFAULT_PAGE_NO, GRID_SIZE } from "../../../../shared-component/utilities/dataFormat";

const initialState = {
    selectedRows: [],
    isOpenSelectedViewPage: false,
    isRefreshTable: false,
    sampleGroups: {
        data: [],
        page: 0,
        totalCount: 0
    },
    hisloryLogs: [],
    hisloryLogsModel: false,
    summaryFilter: {
        isFilterEnabled: false,
        filters: [],
        selectedLabs: [],
        selectedSKUs: [],
        pageNo: DEFAULT_PAGE_NO,
        pageSize: GRID_SIZE.DEFAULT_GRID_COUNT
    }
};

function TestRequestSummaryReducers(state = initialState, action) {
    switch (action.type) {

        case TEST_REQUESTS:
            return {
                ...state,
                sampleGroups: action.sampleGroups
            };

        case REFRESH_TEST_REQUESTS_TABLE:
            return {
                ...state,
                isRefreshTable: action.isRefreshTable
            };

        case SAMPLE_GROUP_HISTORY_LOGS:
            return {
                ...state,
                hisloryLogs: action.hisloryLogs
            };

        case SAMPLE_GROUP_HISTORY_LOGS_MODEL:
            return {
                ...state,
                hisloryLogsModel: action.hisloryLogsModel
            };

        case SELECTED_TEST_REQUESTS_PAGE_OPEN:
            return {
                ...state,
                isOpenSelectedViewPage: action.isSelectedPageOpen
            };

        case SELECTED_TEST_REQUESTS:
            return {
                ...state,
                selectedRows: action.selectedRows
            };

        case TEST_REQUEST_FORM_SUMMARY_FILTER:
            return {
                ...state,
                summaryFilter: action.data
            };

        default:
            return state;
    }
}
export default TestRequestSummaryReducers;