import React, { useEffect } from 'react';
import "./App.css"
import { StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import HomePage from "../shared-component/header/homePage";
import { Provider } from "react-redux";
import store from "../store/store.jsx";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, useMsalAuthentication } from "@azure/msal-react";
import { AuthProvider, AuthenticationParameters } from "../shared-component/authentication/authProvider";
import { AUTHENTICATING, ERROR_AUTHENTICATING } from "../shared-component/utilities/alertMessage"
import { CacheManager } from "../cache-manager";
import { GLS_APP_URL } from "../shared-component/utilities/apiConfig";

const generateClassName = createGenerateClassName({
  productionPrefix: "qtil",
  disableGlobal: true
});

const errorComponent = (error) => {
  return <p>{ERROR_AUTHENTICATING} - {error}</p>;
}

const loadingComponent = () => {
  return <p>{AUTHENTICATING}</p>;
}

const GLSApp = (props) => {
  const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, AuthenticationParameters);

  useEffect(() => {
    if (result) {
      AuthProvider.setActiveAccount(result.account);
    }
    if (error) {
      login.Redirect(AuthenticationParameters);
    }
    else if (result &&
      result.state &&
      result.state.startsWith(GLS_APP_URL) &&
      !result.state.endsWith('auth.html') &&
      new URL(result.state).search) {
      window.location = new URL(result.state);
    }
  }, [result, error]);

  return (
    <Provider store={store}>
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StylesProvider generateClassName={generateClassName}>

            <MsalProvider instance={AuthProvider}>

              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={AuthProvider.AuthenticationParameters}
                errorComponent={errorComponent}
                loadingComponent={loadingComponent}
              >
                <HomePage latestBuildDate={props.latestBuildDate} />
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </div>
    </Provider>
  )

}

const CacheManagedGLSApp = CacheManager(GLSApp);


const App = () => (
  <CacheManagedGLSApp />
);

export default App;