import { GLS_API_URL } from "../../../shared-component/utilities/apiConfig";

const LAB_API = 'lab';
const LAB_LIST_ALL = '/get-all'
const API_LAB_SAMPLE_TYPE = '/get-by-sample-type'
const API_LIST_ALL_SITE = 'site';
const LAB_USER_API = "lab-users";
const USER_LAB_API = "lab-users/get-user-labs";
const LAB_SAMPLE_TYPE_MAPPING_API = "lab-sample-types";

export const LabApi = (isActiveOnly) => {
    return GLS_API_URL + LAB_API + LAB_LIST_ALL + (isActiveOnly ? "?isActive=true" : "");
}

export const LabSaveApi = () => {
    return GLS_API_URL + LAB_API;
}

export const ListAllSiteApi = () => {
    return GLS_API_URL + API_LIST_ALL_SITE;
}

export const GetLabSampleTypeIdAPI = (userId, roles, sampleTypeId) => {
    return GLS_API_URL + LAB_API + API_LAB_SAMPLE_TYPE + "?userId=" + userId + " &roles=" + roles +
        "&sampleTypeId=" + sampleTypeId;
}

export const SaveLabUsers = () => {
    return GLS_API_URL + LAB_USER_API;
};

export const GetLabSampleTypes = (labId) => {
    return GLS_API_URL + LAB_SAMPLE_TYPE_MAPPING_API + "?labId=" + labId.toString();
}

export const GetSaveLabSampleTypesUrl = () => {
    return GLS_API_URL + LAB_SAMPLE_TYPE_MAPPING_API;
}

export const GetUserLabs = (userId) => {
    return GLS_API_URL + USER_LAB_API + '?userId=' + userId.toString();
}