export const Features = {
    GLS_DASHBOARD: "DASHBOARD",
    GLS_LAB: "LAB",
    GLS_SAMPLE_TYPE: "SAMPLE_TYPE",
    GLS_VENDOR: "VENDOR",
    GLS_TEST_TYPE: "TEST_TYPE",
    GLS_EQIUPMENT: "EQIUPMENT",
    GLS_SKU: "SKU",
    GLS_QUESTION: "QUESTION",
    GLS_HEADER_FIELD: "HEADER_FIELD",
    GLS_QUESTION_CONSTRAINT: "QUESTION_CONSTRAINT",
    TEST_CREATION_SAMPLE_LANDING: "TEST_CREATION_SAMPLE_LANDING",
    TEST_CREATION_TEST_LANDING: "TEST_LANDING",
    SAMPLE_GROUP: "SAMPLE_GROUP",
    GLS_APPROVAL_LANDING: "APPROVAL_LANDING",
    GLS_APPROVAL_LANDING_SAMPLES: "APPROVAL_LANDING_SAMPLES",
    GLS_TEST_QUESTION: "TEST_QUESTION",
    GLS_TEST_REVIEW: "TEST_REVIEW",
    GLS_CYCLE: "CYCLE",
    TEST_RESULT: 'TEST_RESULT',
    USER_ROLES: 'USER_ROLES',
    GLS_TEST_REQUEST_FORM: 'TEST_REQUEST_FORM',
    GLS_TEST_REQUEST_SUMMARY: 'TEST_REQUEST_SUMMARY',
    GLS_LAB_USER_MAPPING: 'LAB_USER_MAPPING',
    GLS_LAB_EQUIPMENT_MAPPING: 'LAB_EQUIPMENT_MAPPING',
    AUTO_TEST_REQUEST_MAPPING: 'AUTO_TEST_REQUEST_MAPPING',
    APPROVED_SKU_DATABASE: "APPROVED_SKU_DATABASE",
    SAMPLE_GROUP_LAB_EQUIPMENTS: "SAMPLE_GROUP_LAB_EQUIPMENTS"

}

export const ACCESS_DENIED = "We are sorry, You are not autorized to access this page.";
export const ACCESS_DENIED_MESSAGE = "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation";
export const NOT_FOUND = "404: The page you are looking for isn’t here";

