import {
    LIST_ALL_QUESTION, LIST_ALL_UNIT_TYPE, LIST_ALL_DATA_TYPE, LIST_ALL_INPUT_TYPE, SELECTED_QUESTION,
    SELECTED_QUESTION_OPTION, LIST_QUESTION_FORMULA, QUESTION_FORMULA_DIALOG_OPEN_CLOSE, HEADERS, QUESTION_SUMMARY_FILTER,
    API_EXECUTION_STARTED, QUESTION_MODAL_OPEN, VALIDATE_QUESTION_FORMULA, LIST_NUMBER_TYPE_QUESTIONS, LIST_QUESTION_SUMMARY,
    REFRESH_QUESTIONS_TABLE
} from '../../../../constant/actionType';

const initialState = {
    questions: [],
    headers: [],
    unitTypes: [],
    dataTypes: [],
    inputTypes: [],
    selectedQuestion: {},
    questionOptions: [],
    constants: [],
    modelOpen: false,
    questionFormula: {
        questionId: 0,
        questionText: "",
        formulaText: ""
    },
    validationModel: {},
    isOpenFormulaDialog: false,
    isAPIStarted: false,
    numberTypeQuestions: [],
    isRefreshQuestionsTable: false,
    summaryFilter: {
        isFilterEnabled: false,
        filters: []
    }
};

function questionReducers(state = initialState, action) {
    switch (action.type) {
        case LIST_ALL_QUESTION:
            return {
                ...state,
                questions: action.data
            };

        case LIST_QUESTION_SUMMARY:
            return {
                ...state,
                questionSummary: action.data
            };

        case SELECTED_QUESTION:
            return {
                ...state,
                selectedQuestion: action.selectedQuestion
            };

        case QUESTION_MODAL_OPEN:
            return { ...state, modelOpen: action.modelOpen };

        case SELECTED_QUESTION_OPTION:
            return {
                ...state,
                questionOptions: action.questionOptions
            };

        case LIST_ALL_UNIT_TYPE:
            return {
                ...state,
                unitTypes: action.data
            };

        case LIST_ALL_DATA_TYPE:
            return {
                ...state,
                dataTypes: action.data
            };

        case LIST_ALL_INPUT_TYPE:
            return {
                ...state,
                inputTypes: action.data
            };

        case LIST_QUESTION_FORMULA:
            return {
                ...state,
                questionFormula: action.formula
            };

        case VALIDATE_QUESTION_FORMULA:
            return {
                ...state,
                validationModel: action.validateExpression
            };

        case LIST_NUMBER_TYPE_QUESTIONS:
            return {
                ...state,
                numberTypeQuestions: action.numberTypeQuestions
            };


        case QUESTION_FORMULA_DIALOG_OPEN_CLOSE:
            return {
                ...state,
                isOpenFormulaDialog: action.isOpen
            };

        case API_EXECUTION_STARTED:
            return {
                ...state,
                isAPIStarted: action.isAPIStarted
            };

        case HEADERS:
            return {
                ...state,
                headers: action.headers
            };

        case REFRESH_QUESTIONS_TABLE:
            return {
                ...state,
                isRefreshQuestionsTable: action.isRefreshQuestionsTable
            };

        case QUESTION_SUMMARY_FILTER:
            return {
                ...state,
                summaryFilter: action.data
            };

        default:
            return state;
    }
}

export default questionReducers;