import {
  SAMPLE_GROUPS, REQUEST_BY_API_CALLING, SKU_API_CALLING, LAB_API_CALLING, FILTERS_SKUS_POPUP,
  FILTERS_LABS_POPUP, FILTERS_REQUEST_BY_POPUP, CLOSE_MANAGER_NOTES_POPUP, REVIEW_LANDING_FILTER,
  REVIEW_MODULE_FILTERS_LABS, REVIEW_MODULE_FILTERS_SKUS, REVIEW_MODULE_FILTERS_REQUEST_BY,
  REFRESH_SAMPLEGROUP_TABLE, SAMPLE_GROUP_FILES, SELECTED_SAMPLE_GROUPS, SELECTED_SAMPLE_GROUP, SELECTED_SAMPLE_GROUPS_PAGE_OPEN, VENDOR_API_CALLING, FILTERS_VENDORS_POPUP, REVIEW_MODULE_FILTERS_VENDORS 
} from "../../../../constant/actionType";

const initialState = {
  isRefreshTable: false,
  isOpenSelectedViewPage: false,
  sampleGroupFiles: [],
  selectedSampleGroups: [],
  sampleGroups: {
    data: [],
    page: 0,
    totalCount: 0
  },
  closeManagerNotes: false,
  filterFieldLabs: [],
  filterFieldVendors: [],
  filterFieldSkus: [],
  filterFieldRequestBy: [],
  requestByAPICalling: false,
  labAPICalling: false,
  vendorAPICalling: false,
  skuAPICalling: false,
  skuPopupOpen: false,
  labPopupOpen: false,
  vendorPopupOpen: false,
  requestByPopupOpen: false,
  summaryFilter: {
    isFilterEnabled: false,
    filters: [],
    selectedLabs: [],
    selectedVendors: [],
    selectedSKUs: [],
    selectedRequestBy: []
  }
};

function approvalLandingReducers(state = initialState, action) {
  switch (action.type) {
    case SAMPLE_GROUPS:
      return {
        ...state,
        sampleGroups: action.sampleGroups
      };

    case REVIEW_MODULE_FILTERS_LABS:
      return {
        ...state,
        filterFieldLabs: action.filterFieldLabs
      };
    case REVIEW_MODULE_FILTERS_VENDORS:
      return {
        ...state,
        filterFieldVendors: action.filterFieldVendors
      };

    case CLOSE_MANAGER_NOTES_POPUP:
      return {
        ...state,
        closeManagerNotes: action.closeManagerNotes
      };

    case REVIEW_MODULE_FILTERS_SKUS:
      return {
        ...state,
        filterFieldSkus: action.filterFieldSkus
      };

    case REVIEW_MODULE_FILTERS_REQUEST_BY:
      return {
        ...state,
        filterFieldRequestBy: action.filterFieldRequestBy
      };

    case REQUEST_BY_API_CALLING:
      return {
        ...state,
        requestByAPICalling: action.requestByAPICalling
      };

    case SKU_API_CALLING:
      return {
        ...state,
        skuAPICalling: action.skuAPICalling
      };

    case LAB_API_CALLING:
      return {
        ...state,
        labAPICalling: action.labAPICalling
      };
    case VENDOR_API_CALLING:
      return {
        ...state,
        vendorAPICalling: action.vendorAPICalling
      };

    case FILTERS_SKUS_POPUP:
      return {
        ...state,
        skuPopupOpen: action.skuPopupOpen
      };

    case FILTERS_LABS_POPUP:
      return {
        ...state,
        labPopupOpen: action.labPopupOpen
      };

      case FILTERS_VENDORS_POPUP:
        return {
          ...state,
          vendorPopupOpen: action.vendorPopupOpen
        };

    case FILTERS_REQUEST_BY_POPUP:
      return {
        ...state,
        requestByPopupOpen: action.requestByPopupOpen
      };

    case SAMPLE_GROUP_FILES:
      return {
        ...state,
        sampleGroupFiles: action.sampleGroupFiles
      };

    case SELECTED_SAMPLE_GROUPS:
      return {
        ...state,
        selectedSampleGroups: action.selectedSampleGroups ? action.selectedSampleGroups : []
      };

    case SELECTED_SAMPLE_GROUP:
      return {
        ...state,
        selectedSampleGroup: action.selectedSampleGroup
      };

    case REFRESH_SAMPLEGROUP_TABLE:
      return {
        ...state,
        isRefreshTable: action.isRefreshTable
      };

    case SELECTED_SAMPLE_GROUPS_PAGE_OPEN:
      return {
        ...state,
        isOpenSelectedViewPage: action.isSelectedPageOpen
      };

    case REVIEW_LANDING_FILTER:
      return {
        ...state,
        summaryFilter: action.data
      };

    default:
      return state;
  }
}
export default approvalLandingReducers;
