import {
    SAMPLE_GROUP_LAB_EQUIPMENTS
} from "../../../../constant/actionType";

const initialState = {
    sampleGroupLabEquipment: {
        data: [],
        page: 0,
        totalCount: 0
    }
};

function sampleGroupLabEquipmentReducer(state = initialState, action) {
    switch (action.type) {

        case SAMPLE_GROUP_LAB_EQUIPMENTS:
            return {
                ...state,
                sampleGroupLabEquipment: action.sampleGroupLabEquipment
            };

        default:
            return state;
    }
}

export default sampleGroupLabEquipmentReducer;