

import {
    LIST_ALL_SAMPLE_TEST, TEST_CYCLE_TEXT, GET_TEST_QUESTIONS, REFRESH_TESTQUESTIONVIEW_TABLE,
    ENABLE_SAVE_BUTTON, ALL_SAMPLES, LIST_ALL_CONSTANTS, VIEW_BY_SAMPLE_QUESTIONS, IMAGES,
    GET_TEST_QUESTION_FILTERS, QUESTIONS, CYCLES, DELETED_IMAGES, ADDED_IMAGES, NUMERIC_SAMPLEGROUP_HEADERRESULTS, NOTIFICATIONS, IS_STATUS_UPDATE
    , TEST_LOCK_DETAILS
} from '../../../../constant/actionType';


const initialState = {
    sampleTests: [],
    sampleQuestions: [],
    viewByQuestions: [],
    sampleFilterData: [],
    questions: [],
    cycles: [],
    addedImages: [],
    deletedImages: [],
    constants: [],
    images: [],
    enableSaveButton: false,
    testCycleTexts: [],
    testQuestions: [],
    samples: [],
    numericHeaderResults: [],
    isRefreshTestQuestionTable: false,
    notifications: [],
    isStatusUpdate: false,
    testLockDetails: []
};


function TestQuestionViewBySampleReducers(state = initialState, action) {

    switch (action.type) {
        case LIST_ALL_SAMPLE_TEST:
            return {
                ...state, sampleTests: action.sampleTests
            };
        case VIEW_BY_SAMPLE_QUESTIONS:
            return {
                ...state, sampleQuestions: action.sampleQuestions
            };
        case GET_TEST_QUESTION_FILTERS:
            return {
                ...state, sampleFilterData: action.sampleFilterData
            };
        case ENABLE_SAVE_BUTTON:
            return {
                ...state, enableSaveButton: action.enableSaveButton
            };

        case QUESTIONS:
            return {
                ...state, questions: action.questions
            };
        case LIST_ALL_CONSTANTS:
            return {
                ...state, constants: action.constants
            };
        case CYCLES:
            return {
                ...state, cycles: action.cycles
            };
        case ADDED_IMAGES:
            return {
                ...state, addedImages: action.addedImages
            };
        case DELETED_IMAGES:
            return {
                ...state, deletedImages: action.deletedImages
            };
        case IMAGES:
            return {
                ...state, images: action.images
            };
        case TEST_CYCLE_TEXT:
            return {
                ...state, testCycleTexts: action.testCycleTexts
            };
        case GET_TEST_QUESTIONS:
            return {
                ...state, testQuestions: action.testQuestions
            };
        case ALL_SAMPLES:
            return {
                ...state, samples: action.samples
            };

        case REFRESH_TESTQUESTIONVIEW_TABLE:
            return {
                ...state,
                isRefreshTestQuestionTable: action.isRefreshTestQuestionTable
            };

        case NUMERIC_SAMPLEGROUP_HEADERRESULTS:
            return {
                ...state,
                numericHeaderResults: action.numericHeaderResults
            };


        case NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            };
        case IS_STATUS_UPDATE:
            return {
                ...state,
                isStatusUpdate: action.isStatusUpdate
            };
        case TEST_LOCK_DETAILS:
            return {
                ...state,
                testLockDetails: action.testLockDetails
            };

        default:
            return state;
    }
}

export default TestQuestionViewBySampleReducers;