
import { LIST_ALL_HEADER_MAPPING } from '../../../../constant/actionType';



const initialState = {

    listAllHeaderMapping: [{
        id: 1,
        sampleName: "foam",
        headerName: 'target thickness',
        sortingNumber: '1'
    }, {
        id: 1,
        sampleName: "foam",
        headerName: 'thickness',
        sortingNumber: '2'
    }],
};


function headerMappingReducers(state = initialState, action) {
    switch (action.type) {
        case LIST_ALL_HEADER_MAPPING:
            return {
                ...state,
                listAllHeaderMapping: [...state.listAllHeaderMapping, action.payload]
            };

        default:
            return state;
    }
}

export default headerMappingReducers;