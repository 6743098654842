import {
  EQIUPMENT, EQUIPMENT_MODAL_OPEN, INTERVAL
} from "../../../../constant/actionType";

const initialState = {
  equipments: [],
  modelOpen: false,
  intervals: []
};

function equipmentReducers(state = initialState, action) {
  switch (action.type) {
    case EQIUPMENT:
      return {
        ...state,
        equipments: action.equipments
      };
    case INTERVAL:
      return {
        ...state,
        intervals: action.intervals
      };

    case EQUIPMENT_MODAL_OPEN:
      return {
        ...state,
        modelOpen: action.modelOpen
      };
    default:
      return state;
  }
}

export default equipmentReducers;
