var authSettings = require("./authSetting.json");

export const Authority = "https://login.microsoftonline.com/5a9d9cfd-c32e-4ac1-a9ed-fe83df4f9e4d";
export const ApplicationId = authSettings.ApplicationId;

export const GLSReviewModuleAPIScopeURL = authSettings.GLSReviewModuleAPIScopeURL;
export const GLSSampleTestingModuleAPIScopeURL = authSettings.GLSSampleTestingModuleAPIScopeURL;
export const GLSSetupModuleAPIScopeURL = authSettings.GLSSetupModuleAPIScopeURL;

export const QTILAuthAPIScopeURL = authSettings.QTILAuthAPIScopeURL;
export const GLSFileProcessorReadScopeURL = authSettings.GLSFileProcessorReadScopeURL;
export const GLSFileProcessorModifyScopeURL = authSettings.GLSFileProcessorModifyScopeURL;
export const SerialScopeURL = authSettings.SerialScopeURL;
export const PowerBIReadWriteScopeURL= authSettings.PowerBIReadWriteScopeURL;
