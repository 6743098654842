
import {
    QUESTION_CONSTRAINT, LIST_ALL_QUESTION_CONTRAINTS, OPEN_CONTRAINT_CREATE_FORM, API_EXECUTION_STARTED, TEST_TYPE_IS_VISIBLE,
    TEST_TYPE_SKU, SKU_IS_VISIBLE,
} from '../../../../constant/actionType';

const initialState = {
    isOpenCreateForm: false,
    isAPIExecutionStarted: false,
    isTestTypeShow: false,
    skuIsVisible: false,
    questionConstraints: [],
    questionConstraint: null,
    commodities: [],
    apiResponse: { isSuccess: false, isError: false, message: "" }
};

function QuestionConstraintReducer(state = initialState, action) {
    switch (action.type) {

        case QUESTION_CONSTRAINT:
            return {
                ...state,
                questionConstraint: action.questionConstraint
            };

        case LIST_ALL_QUESTION_CONTRAINTS:
            return {
                ...state,
                questionConstraints: action.data
            };

        case OPEN_CONTRAINT_CREATE_FORM:
            return {
                ...state,
                isOpenCreateForm: action.isOpenCreateForm
            };

        case API_EXECUTION_STARTED:
            return {
                ...state,
                isAPIExecutionStarted: action.isAPIStarted
            };

        case TEST_TYPE_IS_VISIBLE:
            return {
                ...state,
                isTestTypeShow: action.isVisible
            };

        case TEST_TYPE_SKU:
            return {
                ...state,
                commodities: action.skus
            };

        case SKU_IS_VISIBLE:
            return {
                ...state,
                skuIsVisible: action.isVisible
            };

        default:
            return state;
    }
}

export default QuestionConstraintReducer;