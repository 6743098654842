import {
  SAMPLE_GROUPS, SELECTED_SAMPLE_GROUPS, FILTERS_LABS_POPUP, SAMPLE_GROUP_STATUS, FILTERS_SKUS_POPUP, SELECTED_SAMPLE_GROUPS_PAGE_OPEN,
  REFRESH_SAMPLEGROUP_TABLE, FILTERS_LABS, FILTERS_SKUS, SKU_API_CALLING, LAB_API_CALLING, SAMPLE_GROUP_SUMMARY_FILTER, VENDOR_API_CALLING, FILTERS_VENDORS_POPUP, FILTERS_VENDORS,IS_UPLOAD_LOADER
} from "../../../../constant/actionType";
 import { DEFAULT_PAGE_NO, GRID_SIZE } from "../../../../shared-component/utilities/dataFormat";

const initialState = {
  selectedRows: [],
  isOpenSelectedViewPage: false,
  isRefreshTable: false,
  sampleGroups: {
    data: [],
    page: 0,
    totalCount: 0
  },
  sampleGroupStatus: [],
  filterFieldLabs: [],
  filterFieldSkus: [],
  filterFieldVendors: [],
  labAPICalling: false,
  vendorAPICalling: false,
  skuAPICalling: false,
  skuPopupOpen: false,
  labPopupOpen: false,
  vendorPopupOpen: false,
  summaryFilter: {
    isFilterEnabled: false,
    filters: [],
    selectedLabs: [],
    selectedVendors: [],
    selectedSKUs: [],
    pageNo: DEFAULT_PAGE_NO,
    pageSize: GRID_SIZE.DEFAULT_GRID_COUNT
  },
  isUploadLoader:false,
};

function sampleGroupSummaryReducers(state = initialState, action) {
  switch (action.type) {

    case SAMPLE_GROUPS:
      return {
        ...state,
        sampleGroups: action.sampleGroups
      };

    case SELECTED_SAMPLE_GROUPS:
      return {
        ...state,
        selectedRows: action.selectedRows ? action.selectedRows : []
      };

    case FILTERS_LABS:
      return {
        ...state,
        filterFieldLabs: action.filterFieldLabs
      };
    case FILTERS_VENDORS:
      return {
        ...state,
        filterFieldVendors: action.filterFieldVendors
      }
    case FILTERS_SKUS:
      return {
        ...state,
        filterFieldSkus: action.filterFieldSkus
      };

    case SKU_API_CALLING:
      return {
        ...state,
        skuAPICalling: action.skuAPICalling
      };

    case FILTERS_SKUS_POPUP:
      return {
        ...state,
        skuPopupOpen: action.skuPopupOpen
      };

    case FILTERS_LABS_POPUP:
      return {
        ...state,
        labPopupOpen: action.labPopupOpen
      };
    case FILTERS_VENDORS_POPUP:
        return {
          ...state,
          vendorPopupOpen: action.vendorPopupOpen
        };
    case LAB_API_CALLING:
      return {
        ...state,
        labAPICalling: action.labAPICalling
      };
    case VENDOR_API_CALLING:
      return {
        ...state,
        vendorAPICalling:action.vendorAPICalling
      }
    case SELECTED_SAMPLE_GROUPS_PAGE_OPEN:
      return {
        ...state,
        isOpenSelectedViewPage: action.isSelectedPageOpen
      };

    case SAMPLE_GROUP_STATUS:
      return {
        ...state,
        sampleGroupStatus: action.sampleGroupStatus
      };

    case REFRESH_SAMPLEGROUP_TABLE:
      return {
        ...state,
        isRefreshTable: action.isRefreshTable
      };

    case SAMPLE_GROUP_SUMMARY_FILTER:
      return {
        ...state,
        summaryFilter: action.data
      };

    case IS_UPLOAD_LOADER:
      return {
        ...state,
        isUploadLoader: action.isUploadLoader
      };

    default:
      return state;
  }
}
export default sampleGroupSummaryReducers;
