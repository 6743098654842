import { RESULT_OPTIONS, STATUS_OPTIONS } from "../../../constant/actionType";

const initialState = {
  resultOptions: [],
  statusOptions: []
};

function SplitButtonReducer(state = initialState, action) {
  switch (action.type) {
    case RESULT_OPTIONS:
      return {
        ...state,
        resultOptions: action.resultOptions
      };

    case STATUS_OPTIONS:
      return {
        ...state,
        statusOptions: action.statusOptions
      };

    default:
      return state;
  }
}
export default SplitButtonReducer;
