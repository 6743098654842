import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { ACCESS_DENIED_MESSAGE, NOT_FOUND } from "../rbac/constants/roleConstant";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
}));

const NoMatch = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box>
                <Typography align="center" color="textSecondary" variant="h3" display="block">
                    {NOT_FOUND}
                </Typography>
                <Typography align="center" color="textSecondary" variant="h6" display="block">
                    {ACCESS_DENIED_MESSAGE}
                </Typography>
            </Box>
        </div>
    );
};



export default NoMatch;
