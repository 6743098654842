import {
  LIST_ALL_TEST_TYPE_QUESTIONS,
  LIST_ALL_TEST_TYPE,
  QUESTION_TEST_TYPES,
  TEST_TYPE_MODAL_OPEN,
  LIST_TEST_TYPE_QUESTIONS,
  API_EXECUTION_STARTED,
  QUESTION_FORMULA_DIALOG_OPEN_CLOSE,
  VALIDATE_QUESTION_FORMULA,
  SELECTED_TEST_TYPE,
  NUMBER_TYPE_TEST_QUESTIONS,
  NUMBER_TYPE_HEADERS, PREDEFINED_CYCLE, REPORT_FORMAT,
  LIST_ALL_AGGREGATE_OPTIONS,REMAINDER_NOTIFICATION_PERIOD
} from "../../../../constant/actionType";

const initialState = {
  listAllTestType: [],
  questionsTestTypes: [],
  modelOpen: false,
  testTypeQuestions: [],
  numberTypeTestQuestions: [],
  numberTypeHeaders: [],
  formula: {},
  formulaValidationModel: {},
  isAPIStarted: false,
  isOpenFormulaDialog: false,
  selectedTest: {},
  predefinedCycle: [],
  reportFormats: [],
  aggregateOptions: [],
  expiryNotifications:[],
};

function testTypeReducers(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_AGGREGATE_OPTIONS:
      return {
        ...state,
        aggregateOptions: action.aggregateOptions
      };
    case LIST_ALL_TEST_TYPE:
      return {
        ...state,
        listAllTestType: action.listAllTestType
      };
    case QUESTION_TEST_TYPES:
      return {
        ...state,
        questionsTestTypes: action.questionsTestTypes
      };
    case PREDEFINED_CYCLE:
      return {
        ...state,
        predefinedCycle: action.predefinedCycle
      };
    case REPORT_FORMAT:
      return {
        ...state,
        reportFormats: action.reportFormats
      };

    case TEST_TYPE_MODAL_OPEN:
      return {
        ...state,
        modelOpen: action.modelOpen
      };

    case LIST_ALL_TEST_TYPE_QUESTIONS:
      return {
        ...state,
        testTypeQuestions: action.testTypeQuestions
      };
    case NUMBER_TYPE_TEST_QUESTIONS:
      return {
        ...state,
        numberTypeTestQuestions: action.numberTypeTestQuestions
      }

    case NUMBER_TYPE_HEADERS:
      return {
        ...state,
        numberTypeHeaders: action.numberTypeHeaders
      }
    case LIST_TEST_TYPE_QUESTIONS:
      return {
        ...state,
        formula: action.formula
      };

    case VALIDATE_QUESTION_FORMULA:
      return {
        ...state,
        formulaValidationModel: action.validateExpression
      };

    case SELECTED_TEST_TYPE:
      return { ...state, selectedTest: action.selectedTest };

    case API_EXECUTION_STARTED:
      return {
        ...state,
        isAPIStarted: action.isAPIStarted
      };

    case QUESTION_FORMULA_DIALOG_OPEN_CLOSE:
      return {
        ...state,
        isOpenFormulaDialog: action.isOpen
      };
      case REMAINDER_NOTIFICATION_PERIOD:
        return {
          ...state,
          expiryNotifications: action.expiryNotifications
        };

    default:
      return state;
  }
}

export default testTypeReducers;
