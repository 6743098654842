import { AuthProvider, AuthenticationParameters } from './authProvider';
import * as authConfig from "./authConfig";
import {
    GLS_API_URL, GLS_QTIL_AUTH_API_URL, FILE_PROCESSOR_API_URL, SERIAL_API_URL, GRAPH_API_URL
} from "../../shared-component/utilities/apiConfig";

const getAuthenticationParameters = (apiUrl, isRedirect = false) => {
    const accounts = AuthProvider.getAllAccounts();

    const authenticationParameters = {
        scopes: [],
        account: accounts && accounts.length > 0 ? accounts[0] : [],
        redirectUri: isRedirect ? window.location.href : window.location.origin + '/auth.html',
        state: window.location.href
    };

    if (apiUrl.startsWith(GLS_API_URL)) {
        authenticationParameters.scopes.push(authConfig.GLSReviewModuleAPIScopeURL,
            authConfig.GLSSampleTestingModuleAPIScopeURL,
            authConfig.GLSSetupModuleAPIScopeURL);
    }
    else if (apiUrl.startsWith(GLS_QTIL_AUTH_API_URL)) {
        authenticationParameters.scopes.push(authConfig.QTILAuthAPIScopeURL);
    }
    else if (apiUrl.startsWith(FILE_PROCESSOR_API_URL)) {
        authenticationParameters.scopes.push(authConfig.GLSFileProcessorReadScopeURL, authConfig.GLSFileProcessorModifyScopeURL);
    }
    else if (apiUrl.startsWith(SERIAL_API_URL)) {
        authenticationParameters.scopes.push(authConfig.SerialScopeURL);
    }
    else if (apiUrl.startsWith(GRAPH_API_URL)) {
        authenticationParameters.scopes.push("User.ReadBasic.All");
    }
    return authenticationParameters;
}

export const FetchAccessToken = async (apiUrl) => {
    return await AuthProvider.acquireTokenSilent(getAuthenticationParameters(apiUrl)).then((response) => {
        return response.accessToken;
    }).catch(async (error) => {
        if ((error.subError && error.subError === 'consent_required') || error.name === 'InteractionRequiredAuthError') {
            return await AuthProvider.acquireTokenPopup({ scopes: getAuthenticationParameters(apiUrl).scopes }).then(response => {
                return response.accessToken;
            });
        }
        LoginRedirection();
    });

};

export const RefreshFileProcessorAPIAccessToken = async () => {
    const accounts = AuthProvider.getAllAccounts();
    const authenticationParameters = {
        scopes: [],
        account: accounts && accounts.length > 0 ? accounts[0] : []
    };

    authenticationParameters.scopes.push(authConfig.GLSFileProcessorReadScopeURL, authConfig.GLSFileProcessorModifyScopeURL);

    return await AuthProvider.acquireTokenSilent(authenticationParameters).then((response) => {
        return response.accessToken;
    }).catch(async error => {
        if ((error.subError && error.subError === 'consent_required') || error.name === 'InteractionRequiredAuthError') {
            return await AuthProvider.acquireTokenPopup({ scopes: authenticationParameters.scopes }).then(response => {
                return response.accessToken;
            });
        }
        LoginRedirection();
    });
};

export const LoginRedirection = async () => {
    const authenticationParameters = {
        scopes: AuthenticationParameters.scopes,
        prompt: 'login'
    };

    await AuthProvider.loginRedirect(authenticationParameters);

}