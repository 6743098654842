import { TEST_CYCLES_TEXT, TEST_CYCLE_SAMPLES, OPEN_CYCLE_CONF_DIALOG, MAPPED_SAMPLES } from '../../../../constant/actionType';

const initialState = {
    isCycleConfDialogOpen: false,
    testTypeId: 0,
    testCyclesText: [],
    testSamples: [],
    selectedCycleText: null,
    selectedSampleNames: [],
    sampleId: 0,
    testQuestionViewBy: ""
};

function testCycleConfReducer(state = initialState, action) {
    switch (action.type) {
        case TEST_CYCLES_TEXT:
            return {
                ...state,
                testTypeId: action.data.testTypeId,
                testCyclesText: action.data.testCyclesText,
                selectedCycleText: action.data.selectedCycleText
            };

        case TEST_CYCLE_SAMPLES:
            return {
                ...state, testSamples: action.testSamples
            };

        case OPEN_CYCLE_CONF_DIALOG:
            return {
                ...state,
                isCycleConfDialogOpen: action.data.isCycleConfDialogOpen,
                sampleId: action.data.sampleId,
                testQuestionViewBy: action.data.testQuestionViewBy
            };

        case MAPPED_SAMPLES:
            return {
                ...state, selectedSampleNames: action.selectedSampleNames
            };

        default:
            return state;
    }
}

export default testCycleConfReducer;