import store from "../../../../store/store";
import {
    OPEN_QR_CODE_DIALOG, CLOSE_QR_CODE_DIALOG, OPEN_QR_CODE_SCANNER_DIALOG, CLOSE_QR_CODE_SCANNER_DIALOG
} from "../../../../constant/actionType"; 
import { FetchApiMethods } from "../../../../shared-component/utilities/fetchApiMethods";
import {
    GetSampleGroupQRCodeDetailsAPI 
} from "../../sample-group-creation/urlConstant"; 


export const CloseQRCodeGenerateDialog = () => {
    store.dispatch({
        type: CLOSE_QR_CODE_DIALOG
    });
};

export const OpenQRCodeScannerDialog = () => {
    store.dispatch({
        type: OPEN_QR_CODE_SCANNER_DIALOG
    });
};

export const CloseQRCodeScannerDialog = () => {
    store.dispatch({
        type: CLOSE_QR_CODE_SCANNER_DIALOG
    });
};

export const DispatchOpenQRCodeGenerateDialog = (data) => {
    store.dispatch({
        type: OPEN_QR_CODE_DIALOG,
        data: data
    });
}  

export const OpenScannedSamplegroup = async (sampleGroupId) => {
    const ajaxCalls = new FetchApiMethods();
    const url = GetSampleGroupQRCodeDetailsAPI(sampleGroupId);
    return await ajaxCalls.getMethod(url);
}