import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import logo from "../../app/Logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import QRCodeIcon from "../../modules/test-creation/qr-code/components/qrCodeIcon";
import Container from '@material-ui/core/Container';
import {
    SAMPLE_TYPE,
    TEST_TYPE,
    QUESTION,
    SKU,
    LABS,
    SAMPLE_GROUP_SUMMARY,
    EQUIPMENT,
    QUESTION_CONSTRAINT,
    SETUP_MODULE,
    TEST_MODULE,
    HEADER_FIELD,
    REVIEW_MODULE,
    USER_ROLE_MODULE,
    LAB_EQUIPMENT,
    TEST_REQUEST_SUMMARY,
    QR_CODE,
    LOGOUT,
    AUTO_TEST_REQUEST_MAPPING,
    VENDOR,
    QUALIFIED_COMPONENT_DATABASE
} from "../../constant/appStringConstant";
import { MenuItem, Hidden, IconButton } from "@material-ui/core";
import { Features } from "../rbac/constants/roleConstant";
import { USER_MANAGEMENT_APP_URL } from "../utilities/apiConfig";
import { OpenQRCodeScannerDialog } from '../../modules/test-creation/qr-code/actions/qrCodeAction';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { useState } from "react";
import { useMsal } from "@azure/msal-react";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    menuButtonToogle: {
        right: "0px",
        position: "absolute"
    },
    toolbar: {
        background: "#3f51b5",
        height: "65px",
        padding: "15px"
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down('md')]: {
            width: '250px',
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    logo: {
        width: "130px",
        cursor: "pointer"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    menuItem: {
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(1),
    },
    menuItemList: {
        padding: theme.spacing(0),
    },
    menuLogoutButton: {
        textAlign: "center",
        color: "white",
        position: "relative"
    }

}));


const MobilelView = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { window } = props;
    const { instance, accounts } = useMsal();

    const [isTestModuleOpen, setIsTestModuleOpen] = useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [setupOpen, setSetupOpen] = React.useState(false);


    const handleDrawerClose = () => {
        setTimeout(() => {
            setMobileOpen(!mobileOpen);
        }, 100);
    }

    const handleSetupClick = () => {
        setSetupOpen(!setupOpen);
    };


    const handleClickTest = () => {
        setIsTestModuleOpen(!isTestModuleOpen);
    };

    const handleClickReview = event => {
        setMobileOpen(false);
    };

    const handleClickUserRoles = event => {
        setMobileOpen(false);
        var win = global.window.open(USER_MANAGEMENT_APP_URL, '_blank');
        win.focus();
    };
    const handleClickDashboard = () => {
        setMobileOpen(false);
    };

    const handleClickSampleGroup = () => {
        setMobileOpen(false);
    };

    const handleClickTestRequestSummary = () => {
        setMobileOpen(false);
    };

    const setUpMenuVisible = () => {
        if (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.findIndex(element =>
            element.featureName === Features.GLS_LAB ||
            element.featureName === Features.GLS_SAMPLE_TYPE ||
            element.featureName === Features.GLS_TEST_TYPE ||
            element.featureName === Features.GLS_SKU ||
            element.featureName === Features.GLS_QUESTION ||
            element.featureName === Features.GLS_HEADER_FIELD ||
            element.featureName === Features.GLS_QUESTION_CONSTRAINT
        ) > -1) {
            return true;
        }
        return false;
    }

    const handleSampleGropQRCodeScanner = () => {
        setMobileOpen(false);
        OpenQRCodeScannerDialog();
    }

    const isTestCreationMenuVisible = () => props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.findIndex(element =>
        element.featureName === Features.SAMPLE_GROUP ||
        element.featureName === Features.GLS_TEST_REQUEST_SUMMARY
    ) > -1;



    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        instance.logoutRedirect();
    }



    const drawer = (
        <div>
            <div className={classes.toolbar} >
                <Typography variant="body1" className={classes.menuLogoutButton}>
                    {accounts.length > 0 && accounts[0].name}
                </Typography>
            </div>
            <Divider />
            <List component="nav" className={classes.MobilelView} >
                {
                    setUpMenuVisible() &&
                    <ListItem button onClick={handleSetupClick} className={classes.menuItem}>

                        <ListItemText primary={SETUP_MODULE} className={classes.menuItem} />
                        {setupOpen ? <IconExpandLess /> : <IconExpandMore />}
                    </ListItem>}
                <Collapse in={setupOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" >
                        {
                            props.setupModuleMenuList.map((menu) => {

                                return (
                                    <ListItem button className={classes.menuItem}>
                                        <ListItemText primary={
                                            menu.isVisible &&
                                            <MenuItem onClick={handleDrawerClose} component={Link} to={menu.to}
                                            > {menu.name}
                                            </MenuItem>
                                        } />
                                    </ListItem>
                                )
                            })
                        }

                    </List>
                </Collapse>

                {
                    isTestCreationMenuVisible() &&
                    <ListItem button onClick={handleClickTest} className={classes.menuItem}>
                        <ListItemText primary={TEST_MODULE} className={classes.menuItem} />

                        {isTestModuleOpen ? <IconExpandLess /> : <IconExpandMore />}
                    </ListItem>}
                <Collapse in={isTestModuleOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" >

                        {
                            props.testCreationMenuList.map((menus) => {
                                if (menus.isVisible) {
                                    return (<ListItem button className={classes.menuItem}>
                                        <ListItemText primary={
                                            <MenuItem
                                                component={Link}
                                                to={menus.to}
                                                onClick={handleClickSampleGroup}

                                            >
                                                {menus.name}
                                            </MenuItem>
                                        } />
                                    </ListItem>)
                                }

                            })
                        }
                    </List>
                </Collapse>

                <ListItem button onClick={handleClickReview} className={classes.menuItemList}>
                    <ListItemText className={classes.menuItemList} primary={
                        <MenuItem
                            component={Link}
                            to="/review/approval-landing"
                            onClick={handleDrawerClose}
                        >
                            {REVIEW_MODULE}
                        </MenuItem>

                    } />
                </ListItem>

                <ListItem button onClick={handleClickReview} className={classes.menuItemList}>
                    <ListItemText className={classes.menuItemList} primary={
                        <MenuItem
                            component={Link}
                            to="/setup/sku-database"
                            onClick={handleDrawerClose}
                        >
                            {QUALIFIED_COMPONENT_DATABASE}
                        </MenuItem>

                    } />
                </ListItem>

                <ListItem button onClick={handleClickUserRoles} className={classes.menuItem}>
                    <ListItemText primary={USER_ROLE_MODULE} className={classes.menuItem} />
                </ListItem>

                <ListItem button onClick={(e) => {
                    handleLogout(e, 1)
                }} className={classes.menuItem}>

                    <ListItemText primary={LOGOUT} className={classes.menuItem} />
                </ListItem>
            </List>
        </div >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />

            <AppBar position="sticky"
                sx={{ pl: 2, pr: 2, width: 1 }}

            >
                <Container
                    maxWidth={false}
                    sx={{ width: 1 }}
                >
                    <Toolbar variant="regular">
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                        >
                            <MenuItem
                                component={Link}
                                to="/dashboard"
                                onClick={handleClickDashboard}
                            >
                                <img src={logo} alt="logo" style={{
                                    maxWidth: 160,
                                    marginRight: "3%",
                                    cursor: "pointer",
                                }} />
                            </MenuItem>
                        </Typography>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            style={{
                                right: "0px",
                                position: "absolute",
                                width: "100px"
                            }}
                        >
                            <IconButton edge="end" position="right" onClick={handleSampleGropQRCodeScanner}>
                                <QRCodeIcon fontSize="12" callFrom="menu" primary={
                                    props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => 
                                        element.featureName === Features.SAMPLE_GROUP) && QR_CODE.qrCodeScanner
                                } />
                            </IconButton>
                            <IconButton edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButtonToogle} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>

                        </Typography>

                    </Toolbar>
                </Container>
            </AppBar>

            <Hidden only={["lg", "md"]} >
                <nav className={classes.drawer} aria-label="mailbox folders">

                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="persistent"
                            open={mobileOpen}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>

            </Hidden>

        </div>
    );
};
const mapStateToProps = state => {
    return {
        userInfo: state.rbacReducer.userInfo
    };
};
export default connect(mapStateToProps, {})(MobilelView);