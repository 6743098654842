import {
    SAMPLE_TYPE_REPORT_DALOG_OPEN_CLOSE, SAMPLE_GROUPS_NAMES, PO_NUMBERS, VENDORS, SAMPLE_GROUP_TEST_TYPES
} from "../../../../constant/actionType";

const initialState = {
    isSampleTypeReportDialogOpen: false,
    sampleGroups: [],
    poNumbers: [],
    vendors: []
};

function sampleTypeReportReducer(state = initialState, action) {
    switch (action.type) {

        case SAMPLE_TYPE_REPORT_DALOG_OPEN_CLOSE:
            return {
                ...state, isSampleTypeReportDialogOpen: action.isDialogOpen
            };

        case SAMPLE_GROUPS_NAMES:
            return {
                ...state, sampleGroups: action.sampleGroups
            };

        case PO_NUMBERS:
            return {
                ...state, poNumbers: action.poNumbers
            };

        case VENDORS:
            return {
                ...state, vendors: action.vendors
            };

        case SAMPLE_GROUP_TEST_TYPES:
            return {
                ...state, testTypes: action.testTypes
            };

        default:
            return state;
    }
}

export default sampleTypeReportReducer;
