import { GLS_QTIL_AUTH_API_URL, APPLICATION_CODE, ACCOUNT_TYPE_ID, EXTERNAL_USER_ROLE_ID } from "../../../shared-component/utilities/apiConfig";

const CURRENT_USER = 'users/get-current-user';

export const GET_CURRENT_USER_INFO_API = () => {
    return GLS_QTIL_AUTH_API_URL + CURRENT_USER + '?applicationCode=' + APPLICATION_CODE.toUpperCase();
}

export const GET_ALL_USERS = () => {
    return GLS_QTIL_AUTH_API_URL + 'users/get-all-users?accountTypeId=' + ACCOUNT_TYPE_ID;
};

export const GET_ROLE_DETAILS_API = (roleId) => {
    return GLS_QTIL_AUTH_API_URL + 'role-features/get-detail?roleId=' + roleId.toString();

};
