import { SAMPLE_GROUP_TEST_TYPES } from "../../../../constant/actionType";

const initialState = {
  listAllSampleTestType: [],
  sampleGroupTestTypes: []
};

function sampleGroupTestsReducers(state = initialState, action) {
  switch (action.type) {   
      case SAMPLE_GROUP_TEST_TYPES:
        return {
            ...state, sampleGroupTestTypes: action.sampleGroupTestTypes
        };
    default:
      return state;
  }
}
 
export default sampleGroupTestsReducers;
