import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { LOGOUT } from "../../constant/appStringConstant";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles(theme => ({
    logoutButton: {
        textTransform: 'none',
        color: 'white'
    }
}));

const LogoutMenu = props => {
    const classes = useStyles();
    const { instance, accounts } = useMsal();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const prevOpen = React.useRef(isMenuOpen);

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    const handleMenuToggle = () => {
        setIsMenuOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setIsMenuOpen(false);
    };

    React.useEffect(() => {
        if (prevOpen.current === true && isMenuOpen === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = isMenuOpen;
    }, [isMenuOpen]);


    return (
        <div>
            <Button className={classes.logoutButton}
                ref={anchorRef}
                aria-controls={isMenuOpen ? 'account-menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleMenuToggle}
            >
                {accounts.length > 0 && accounts[0].name}
            </Button>
            <Popper open={isMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={isMenuOpen} id="account-menu-list-grow">
                                    <MenuItem onClick={handleLogout}>{LOGOUT}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );

}

export default LogoutMenu;