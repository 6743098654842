import { LIST_ALL_SAMPLE_TYPE_QUESTIONS, TEST_TYPE_QUESTIONS, TEST_TYPE_QUESTIONS_SUCCESS_CALLBACK } from "../../../../constant/actionType";

const initialState = {
  listAllSampleTypeQuestions: [],
  testTypeQuestions: {},
  questionSuccessCallback: false
};

function questionMappingReducers(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_SAMPLE_TYPE_QUESTIONS:
      return {
        ...state,
        listAllSampleTypeQuestions: [
          ...state.listAllSampleTypeQuestions,
          action.payload
        ]
      };

    case TEST_TYPE_QUESTIONS:
      return {
        ...state, testTypeQuestions: action.testTypeQuestions
      };
    case TEST_TYPE_QUESTIONS_SUCCESS_CALLBACK:
      return {
        ...state, questionSuccessCallback: action.questionSuccessCallback
      };

    default:
      return state;
  }
}

export default questionMappingReducers;
