import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import logo from "../../app/Logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
    SETUP_MODULE,
    TEST_MODULE,
    REVIEW_MODULE,
    APPROVAL_LANDING,
    USER_ROLE_MODULE,
    QR_CODE,
    QUALIFIED_COMPONENT_DATABASE
} from "../../constant/appStringConstant";
import { Menu, MenuItem, Hidden, IconButton, Tooltip } from "@material-ui/core";
import { Features } from "../rbac/constants/roleConstant";
import { USER_MANAGEMENT_APP_URL } from "../utilities/apiConfig";
import LogoutMenu from "./logoutMenu";
import { OpenQRCodeScannerDialog } from '../../modules/test-creation/qr-code/actions/qrCodeAction';
import QRCodeIcon from "../../modules/test-creation/qr-code/components/qrCodeIcon";

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    root: {
        flexGrow: 1
    },
    menuButton: {
        [theme.breakpoints.down('md')]: {
            fontSize: '0.475rem',
            fontWeight: '300'
        },
        marginRight: theme.spacing(2)
    },
    logo: {
        maxWidth: 160,
        marginRight: "3%",
        cursor: "pointer"
    },
    menuLogoutButton: {
        flexGrow: 1,
    }
}));

const GeneralView = props => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [anchorElSetup, setAnchorElSetup] = React.useState(null);
    const [anchorElTest, setAnchorElTest] = React.useState(null);
    const [anchorElReview, setAnchorElReview] = React.useState(null);

    const handleClick = event => {
        setAnchorElSetup(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElSetup(null);
    };

    const handleClickTest = event => {
        setAnchorElTest(event.currentTarget);
    };

    const handleCloseTest = () => {
        setAnchorElTest(null);
    };

    const handleCloseReview = () => {
        setAnchorElReview(null);
    };

    const handleClickReview = () => {
        navigate("/review/approval-landing");
    };

    const handleClickSkuDatabase = () => {
        navigate("/setup/sku-database");
    };

    const handleClickUserRoles = () => {
        var win = global.window.open(USER_MANAGEMENT_APP_URL, '_blank');
        win.focus();
    };

    const handleClickDashboard = () => {
        navigate("/dashboard");
    };

    const handleSampleGropQRCodeScanner = () => {
        OpenQRCodeScannerDialog();
    }

    const setUpMenuVisible = () => {
        if (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.findIndex(element =>
            element.featureName === Features.GLS_LAB ||
            element.featureName === Features.GLS_SAMPLE_TYPE ||
            element.featureName === Features.GLS_TEST_TYPE ||
            element.featureName === Features.GLS_SKU ||
            element.featureName === Features.GLS_QUESTION ||
            element.featureName === Features.GLS_HEADER_FIELD ||
            element.featureName === Features.GLS_QUESTION_CONSTRAINT
        ) > -1) {
            return true;
        }

        return false;
    }

    const isTestCreationMenuVisible = () => {
        if (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.findIndex(element =>
            element.featureName === Features.SAMPLE_GROUP ||
            element.featureName === Features.GLS_TEST_REQUEST_SUMMARY ||
            element.featureName === Features.SAMPLE_GROUP_LAB_EQUIPMENTS
        ) > -1) {
            return true;
        }

        return false;
    }


    return (
        <AppBar position="sticky" sx={{ pl: 2, pr: 2, width: 1 }}>
            <Container maxWidth={false} sx={{ width: 1 }} >
                <Toolbar disableGutters variant="regular">

                    <img src={logo} alt="logo" onClick={handleClickDashboard} className={classes.logo} />

                    <Hidden only={"xs"}>

                        {

                            setUpMenuVisible() &&
                            <Button
                                className={classes.menuButton}
                                aria-controls="setup-screens"
                                aria-haspopup="true"
                                onClick={handleClick}
                                variant="contained"
                                color="primary"

                            >
                                {SETUP_MODULE}
                                <ArrowDropDownIcon />
                            </Button>

                        }

                        {isTestCreationMenuVisible() &&
                            <Button
                                className={classes.menuButton}
                                aria-controls="test-creation-screens"
                                aria-haspopup="true"
                                onClick={handleClickTest}
                                variant="contained"
                                color="primary"
                            >
                                {TEST_MODULE}
                                <ArrowDropDownIcon />
                            </Button>
                        }
                        {
                            (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element =>
                                element.featureName === Features.GLS_APPROVAL_LANDING
                            )) &&
                            <Button
                                className={classes.menuButton}
                                aria-controls="review-screens"
                                aria-haspopup="true"
                                onClick={handleClickReview}
                                variant="contained"
                                color="primary"
                            >
                                {REVIEW_MODULE}
                            </Button>
                        }
                        {
                            (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element =>
                                element.featureName === Features.APPROVED_SKU_DATABASE
                            )) &&
                            <Button
                                className={classes.menuButton}
                                aria-controls="sku-database"
                                aria-haspopup="true"
                                onClick={handleClickSkuDatabase}
                                variant="contained"
                                color="primary"
                            >
                                {QUALIFIED_COMPONENT_DATABASE}
                            </Button>
                        }
                        {
                            (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element =>
                                element.featureName === Features.USER_ROLES
                            )) &&
                            <Button
                                className={classes.menuButton}
                                aria-controls="user-role-screens"
                                aria-haspopup="true"
                                onClick={handleClickUserRoles}
                                variant="contained"
                                color="primary"
                            >
                                {USER_ROLE_MODULE}
                            </Button>

                        }

                        {
                            (props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.SAMPLE_GROUP)) &&
                            <Tooltip title={QR_CODE.qrCodeScanner}>
                                <IconButton onClick={handleSampleGropQRCodeScanner}>
                                    <QRCodeIcon fontSize="12" callFrom="menu" />
                                </IconButton>
                            </Tooltip>
                        }

                        <Menu
                            id="setup-screens"
                            disableScrollLock={true}
                            anchorEl={anchorElSetup}
                            open={Boolean(anchorElSetup)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                        >
                            {

                                props.setupModuleMenuList.map((menu) => {

                                    if (menu.isVisible) {
                                        return (
                                            <MenuItem component={Link} to={menu.to} onClick={handleClose}
                                            > {menu.name}
                                            </MenuItem>
                                        )
                                    }
                                })
                            }
                        </Menu>
                        <Menu
                            id="test-creation-screens"
                            disableScrollLock={true}
                            anchorEl={anchorElTest}
                            open={Boolean(anchorElTest)}
                            onClose={handleCloseTest}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                        >
                            {
                                props.testCreationMenuList.map((menus) => {
                                    if (menus.isVisible) {
                                        return (<MenuItem
                                            component={Link}
                                            to={menus.to}
                                            onClick={handleCloseTest}
                                        >
                                            {menus.name}
                                        </MenuItem>)
                                    }

                                })
                            }

                        </Menu>
                        <Menu
                            id="review-screens"
                            anchorEl={anchorElReview}
                            keepMounted
                            open={Boolean(anchorElReview)}
                            onClose={handleCloseReview}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                        >
                            <MenuItem
                                component={Link}
                                to="/review/approval-landing"
                                onClick={handleCloseReview}
                            >
                                {APPROVAL_LANDING}
                            </MenuItem>
                        </Menu>
                    </Hidden>
                    <Hidden only={["lg", "md", "xl", "sm"]}>
                        <IconButton
                            edge="end"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                    <Typography variant="h6" className={classes.menuLogoutButton}>
                        &nbsp;
                    </Typography>

                    <LogoutMenu></LogoutMenu>
                </Toolbar>
            </Container>

        </AppBar>);
};
const mapStateToProps = state => {
    return {
        userInfo: state.rbacReducer.userInfo,
    };
};
export default connect(mapStateToProps, {})(GeneralView);