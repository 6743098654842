import {
    MFU_IS_OPEN_FILE_UPLOAD, MFU_DELETED_FILE, MFU_DROP_ZONE_FILE_DROPPED, MFU_FILE_UPLOAD_SUBMIT, MFU_CLEAR_FILE_UPLOAD_SUBMIT,
    MFU_READY_TO_SAVE
} from '../../../constant/actionType';
import { GLS_FILE_TYPES } from "../../utilities/dataFormat";

const initialState = {
    isOpenFileUpload: false,
    uploadRequestMetadata: {},
    uploadedFiles: [],
    deletedFiles: [],
    sampleTestFiles: [],
    fileMaster: [],
    fileIdsTodelete: [],
    fileTypes: [GLS_FILE_TYPES.General],
    readyToSave: {
        uploadRequestMetaData: {},
        isCompleted: false
    }
};

function multiFileUploadReducer(state = initialState, action) {
    switch (action.type) {
        case MFU_IS_OPEN_FILE_UPLOAD:
            return {
                ...state,
                isOpenFileUpload: action.data.isOpenFileUpload,

                uploadRequestMetadata: action.data.uploadRequestMetadata,
                uploadedFiles: action.data.uploadedFiles,
                fileTypes: action.data.fileTypes,
                deletedFiles: []
            };

        case MFU_DELETED_FILE:

            state.deletedFiles.push(action.data.fileId);
            return {
                ...state,
                uploadedFiles: action.data.uploadedFiles
            };

        case MFU_DROP_ZONE_FILE_DROPPED:
            return {
                ...state,
                uploadedFiles: action.data.uploadedFiles
            };

        case MFU_FILE_UPLOAD_SUBMIT:

            let deletedIds = [...state.fileIdsTodelete];

            if (action.data.deletedFileIds && action.data.deletedFileIds.length > 0) {
                for (let deletedFileId of action.data.deletedFileIds) {
                    if (deletedIds.findIndex(col => col === deletedFileId) === -1) {
                        deletedIds.push(deletedFileId);
                    }
                }
            }
            return {
                ...state,
                fileMaster: action.data.fileMaster,
                fileIdsTodelete: deletedIds
            }

        case MFU_CLEAR_FILE_UPLOAD_SUBMIT:
            return {
                ...state,
                fileMaster: action.data.fileMaster,
                fileIdsTodelete: []
            }
        case MFU_READY_TO_SAVE:
            return {
                ...state,
                readyToSave: action.data
            }
        default:
            return state;
    }
}

export default multiFileUploadReducer;