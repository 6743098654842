import store from '../../../../store/store';
import {
    LIST_ALL_LAB, LIST_ALL_USER, LIST_ALL_SITE, LAB_MODEL_OPEN, SELECTED_LAB, LAB_SAMPLE_TYPE_OPEN_CLOSE,
    LAB_USER_OPEN_CLOSE, LAB_SAMPLE_TYPES, USER_LABS
} from '../../../../constant/actionType';
import { FetchApiMethods } from '../../../../shared-component/utilities/fetchApiMethods';
import {
    LabApi, LabSaveApi, ListAllSiteApi, GetLabSampleTypeIdAPI, SaveLabUsers,
    GetLabSampleTypes, GetSaveLabSampleTypesUrl, GetUserLabs
} from '../urlConstant';
import { DispatchAlertSuccess } from '../../../../shared-component/alert/actions/alertAction';
import { LAB_SAVE_SUCCESS, LAB_UPDATE_SUCCESS, LAB_USERS_SAVED, LAB_AND_SAMPLE_TYPES_MAPPED_SUCCESS } from '../../../../shared-component/utilities/alertMessage';
import { DispatchCloseProgress, DispatchOpenProgress } from '../../../../shared-component/progressDialog/actions/progressDialogAction';
import { ExceptionHandler } from "../../../../shared-component/utilities/exceptionHandler";
import { GET_ALL_USERS } from "../../../../shared-component/rbac/constants/urlConstant";

const ajaxCalls = new FetchApiMethods();

export const SummaryModelOpen = (modelOpen) => {

    store.dispatch({
        type: LAB_MODEL_OPEN,
        modelOpen: modelOpen
    });
}
export const ListAllLab = (listAllLab) => {

    store.dispatch({
        type: LIST_ALL_LAB,
        listAllLab: listAllLab
    });
}

export const FetchAllLab = () => {
    DispatchOpenProgress();
    let url = LabApi(false);
    ajaxCalls.getMethod(url).then(json => {
        ListAllLab(json);
        DispatchCloseProgress();

    }).catch((e) => {
        DispatchCloseProgress();
        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
    });
};

export const FetchActiveLabs = () => {
    DispatchOpenProgress();
    let url = LabApi(true);
    ajaxCalls.getMethod(url).then(json => {
        ListAllLab(json);
        DispatchCloseProgress();
    }).catch((e) => {
        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
        DispatchCloseProgress();
    });
};


export const FetchSite = () => {
    DispatchOpenProgress();
    let url = ListAllSiteApi(true);
    ajaxCalls.getMethod(url).then(json => {

        if (json.length > 0) {
            ListAllSite(json);

        } else {
            ListAllSite([]);
        }
        DispatchCloseProgress();
    }).catch((e) => {
        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
        DispatchCloseProgress();
    });
};


export const ListAllSite = (listAllSite) => {

    store.dispatch({
        type: LIST_ALL_SITE,
        listAllSite: listAllSite
    });
}

export const SelectedLab = labId => {
    store.dispatch({ type: SELECTED_LAB, selectedLab: labId });
};

export const OpenLabUserMappingDialog = () => {
    store.dispatch({ type: LAB_USER_OPEN_CLOSE, isOpen: true });
};

export const CloseLabUserMappingDialog = () => {
    store.dispatch({ type: LAB_USER_OPEN_CLOSE, isOpen: false });
};

export const OpenLabSampleTypeMappingDialog = () => {
    store.dispatch({ type: LAB_SAMPLE_TYPE_OPEN_CLOSE, isOpen: true });
};

export const CloseLabSampleTypeMappingDialog = () => {
    store.dispatch({ type: LAB_SAMPLE_TYPE_OPEN_CLOSE, isOpen: false });
    dispatchLabSampleTypes(null);
};

export const SaveLab = (lab) => {
    DispatchOpenProgress();
    let url = LabSaveApi();


    ajaxCalls.postMethod(url, lab).then(json => {
        DispatchAlertSuccess(LAB_SAVE_SUCCESS);
        FetchAllLab();
        FetchSite();
        SummaryModelOpen(false);
        DispatchCloseProgress();
    }).catch((e) => {
        DispatchCloseProgress();
        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
    });
}

export const UpdateLab = (lab) => {
    DispatchOpenProgress();
    let url = LabSaveApi(true);

    ajaxCalls.putMethod(url, lab).then(json => {
        FetchAllLab();
        DispatchAlertSuccess(LAB_UPDATE_SUCCESS);

        SummaryModelOpen(false);
        DispatchCloseProgress();
    }).catch((e) => {
        DispatchCloseProgress();
        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
    });
}


export const FetchLabsSampleTypeId = (userId, roles, sampleTypeId) => {
    DispatchOpenProgress();

    const url = GetLabSampleTypeIdAPI(userId, roles, sampleTypeId);
    ajaxCalls.getMethod(url).then(json => {
        ListAllLab(json);
        DispatchCloseProgress();
    }).catch(e => {
        ListAllLab([]);
        DispatchCloseProgress();

        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
    });
};

export const FetchAllUserData = () => {

    DispatchOpenProgress();
    let ajaxCalls = new FetchApiMethods();
    ajaxCalls
        .getMethod(GET_ALL_USERS())
        .then(json => {
            ListAllUser(json);
            DispatchCloseProgress();
        })
        .catch(e => {
            ListAllUser([]);
            DispatchCloseProgress();
        });

}

export const ListAllUser = (users) => {

    store.dispatch({
        type: LIST_ALL_USER,
        users: users
    });
}

export const SaveUserMapping = async (labUser) => {
    DispatchOpenProgress();
    let ajaxCalls = new FetchApiMethods();
    ajaxCalls
        .putMethod(SaveLabUsers(), labUser)
        .then(json => {
            FetchAllLab();
            FetchSite();
            DispatchAlertSuccess(LAB_USERS_SAVED);
            DispatchCloseProgress();
        })
        .catch(e => {
            DispatchCloseProgress();
        });
};


export const FetchLabSampleTypes = (labId) => {

    DispatchOpenProgress();
    let ajaxCalls = new FetchApiMethods();

    ajaxCalls
        .getMethod(GetLabSampleTypes(labId))
        .then(json => {
            dispatchLabSampleTypes(json);
            DispatchCloseProgress();
        })
        .catch(e => {
            dispatchLabSampleTypes(null);
            const exceptionHandler = new ExceptionHandler();
            exceptionHandler.PushAPIErrorMessage(e);
            DispatchCloseProgress();
        });

}

export const SaveLabSampleTypes = (labSampleTypePatchModel) => {
    DispatchOpenProgress();

    ajaxCalls.putMethod(GetSaveLabSampleTypesUrl(), labSampleTypePatchModel).then(json => {
        DispatchAlertSuccess(LAB_AND_SAMPLE_TYPES_MAPPED_SUCCESS);
        FetchAllLab();
        CloseLabSampleTypeMappingDialog();
        DispatchCloseProgress();
    }).catch((e) => {
        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
        DispatchCloseProgress();
    });
}

const dispatchLabSampleTypes = (labSampleTypes) => {

    store.dispatch({
        type: LAB_SAMPLE_TYPES,
        labSampleTypes: labSampleTypes
    });
}

export const FetchUserLabs = (userId) => {

    DispatchOpenProgress();
    let ajaxCalls = new FetchApiMethods();
    ajaxCalls
        .getMethod(GetUserLabs(userId))
        .then(json => {
            dispatchUserLabs(json);
            DispatchCloseProgress();
        })
        .catch(e => {
            dispatchUserLabs([]);
            DispatchCloseProgress();
        });

}

const dispatchUserLabs = (userLabs) => {

    store.dispatch({
        type: USER_LABS,
        userLabs: userLabs
    });
}