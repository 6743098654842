import {
  API_EXECUTION_STARTED,
  SAMPLE_GROUP_HEADERS,
  SELECTED_TESTS, SAMPLE_TYPES_BY_MAPPED_LABS,
  SELECTED_TESTS_CHANGE,
  VENDORS, TEST_REQUEST_FORM_DIALOG_OPEN_CLOSE,
  PURCHASEORDERS, SAMPLE_TYPES, VENDOR_API_LOADER,
  SERIAL_INFO, SG_UPDATE_STATUS, SERIAL_BASED_SKU, TEST_TYPE_LAB_EQUIPMENTS,
  PURPOSE_OF_TEST
} from "../../../../constant/actionType";

const initialState = {
  serialBasedSku: true,
  isAPIExecutionStarted: false,
  isVendorAPICalling: false,
  isTestRequestFormDialogOpen: false,
  sampleGroupHeaders: null,
  selectedTests: [],
  vendors: [],
  serialInfo: {},
  purchaseOrders: [],
  sampleTypes: [],
  apiResponse: { isSuccess: false, isError: false, message: "" },
  updateStatus: false,
  testTypeLabEquipments: [],
  purposeoftests: [],
  sampleTypesByMappedLabs: []
};

function SampleGroupReducer(state = initialState, action) {
  switch (action.type) {
    case SAMPLE_GROUP_HEADERS:
      return {
        ...state,
        sampleGroupHeaders: action.data
      };

    case TEST_TYPE_LAB_EQUIPMENTS:
      return {
        ...state,
        testTypeLabEquipments: action.testTypeLabEquipments
      };

    case API_EXECUTION_STARTED:
      return {
        ...state,
        isAPIExecutionStarted: action.isAPIStarted
      };

    case SELECTED_TESTS:
      return {
        ...state,
        selectedTests: action.selectedTests
      };

    case SERIAL_BASED_SKU:
      return {
        ...state,
        serialBasedSku: action.serialBasedSku
      };

    case SELECTED_TESTS_CHANGE:
      return {
        ...state,
        selectedTestsChange: action.selectedTestsChange
      };

    case VENDORS:
      return {
        ...state,
        vendors: action.vendors
      };

    case SERIAL_INFO:
      return {
        ...state,
        serialInfo: action.serialInfo
      };

    case VENDOR_API_LOADER:
      return {
        ...state,
        isVendorAPICalling: action.isVendorAPICalling
      };

    case PURCHASEORDERS:
      return {
        ...state,
        purchaseOrders: action.purchaseOrders
      };

    case SAMPLE_TYPES:
      return {
        ...state,
        sampleTypes: action.sampleTypes
      };

    case SAMPLE_TYPES_BY_MAPPED_LABS:
      return {
        ...state,
        sampleTypesByMappedLabs: action.data
      };

    case TEST_REQUEST_FORM_DIALOG_OPEN_CLOSE:
      return {
        ...state,
        isTestRequestFormDialogOpen: action.isOpen
      };

    case SG_UPDATE_STATUS:
      return {
        ...state,
        updateStatus: action.updateStatus
      };

    case PURPOSE_OF_TEST:
      return {
        ...state,
        purposeoftests: action.purposeoftests
      };

    default:
      return state;
  }
}

export default SampleGroupReducer;
