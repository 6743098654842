import {
  VENDOR_MODAL_OPEN,
  VENDORS,
  BULK_UPLOAD_MODAL_OPEN,
  SELECTED_VENDORS,
  MERGE_VENDOR_DIALOG_OPEN,
  ALL_VENDORS_LIST,
  MERGE_VENDOR,
  UNSELECTED_VENDORS
} from "../../../../constant/actionType";

const initialState = {
  vendors: [],
  allVendorsList: [],
  selectedVendors: [],
  modelOpen: false, 
  isMergeVendorDialogOpen: false,
  mergeVendor: null,
  unSelectedVendors: []
};

function vendorReducers(state = initialState, action) {
  switch (action.type) {
    case VENDORS:
      return {
        ...state,
        vendors: action.vendors
      };
    case ALL_VENDORS_LIST:
      return {
        ...state,
        allVendorsList: action.allVendorsList
      };
    case SELECTED_VENDORS:
      return {
        ...state,
        selectedVendors: action.selectedVendors
      };
    case VENDOR_MODAL_OPEN:
      return {
        ...state,
        modelOpen: action.modelOpen
      };
    case MERGE_VENDOR_DIALOG_OPEN:
      return {
        ...state,
        isMergeVendorDialogOpen: action.isMergeVendorDialogOpen
      };
    case MERGE_VENDOR:
      return {
        ...state,
        mergeVendor: action.mergeVendor
      };
    case UNSELECTED_VENDORS:
      return {
        ...state,
        unSelectedVendors: action.unSelectedVendors
      };


    default:
      return state;
  }
}

export default vendorReducers;
