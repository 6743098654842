import {

  TestRequestFormType,
  IS_TEST_REQUEST_COMPLETE,
  TEST_REQUEST_FORM_DETAILS,
  ORGANIZATION_USERS,
  VENDOR_API_LOADER,
  IS_VENDOR_MAPPED
} from "../../../../constant/actionType";

const initialState = {
  isAPIExecutionStarted: false,
  submittedArea: [],
  reasonForTest: [],
  componentType: [],
  typeOfFinishedGood: [],
  organizationUsers: [],
  testRequestDetails: null,
  isTestRequestComplete: false,
  isVendorAPICalling: false,
  isVendorMapped: true
};

function TestRequestReducer(state = initialState, action) {
  switch (action.type) {
    case TestRequestFormType.ListAllSubmittedArea:
      return {
        ...state,
        submittedArea: action.data
      };
    case TestRequestFormType.ListAllReasonForTest:
      return {
        ...state,
        reasonForTest: action.data
      };
    case TestRequestFormType.ListAllComponentType:
      return {
        ...state,
        componentType: action.data
      };
    case TestRequestFormType.ListAllTypeOfFinishedGood:
      return {
        ...state,
        typeOfFinishedGood: action.data
      };

    case VENDOR_API_LOADER:
      return {
        ...state,
        isVendorAPICalling: action.isVendorAPICalling
      };
    case IS_TEST_REQUEST_COMPLETE:
      return {
        ...state,
        isTestRequestComplete: action.isTestRequestComplete
      };
    case IS_VENDOR_MAPPED:
      return {
        ...state,
        isVendorMapped: action.isVendorMapped
      };

    case TEST_REQUEST_FORM_DETAILS:
      return {
        ...state,
        testRequestDetails: action.data
      };
    case ORGANIZATION_USERS:
      return {
        ...state,
        organizationUsers: action.organizationUsers
      };

    default:
      return state;
  }
}

export default TestRequestReducer;