import { RESULT_FILTER_OPTIONS, STATUS_FILTER_OPTIONS } from "../../../constant/actionType";

const initialState = {
  resultFilterOptions: [],
  statusFilterOptions: []
};

function FilterReducer(state = initialState, action) {
  switch (action.type) {
    case RESULT_FILTER_OPTIONS:
      return {
        ...state,
        resultFilterOptions: action.resultFilterOptions
      };

    case STATUS_FILTER_OPTIONS:
      return {
        ...state,
        statusFilterOptions: action.statusFilterOptions
      };

    default:
      return state;
  }
}
export default FilterReducer;
