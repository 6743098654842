import { LIST_ALL_CONSTANTS } from '../../../../constant/actionType';

const initialState = {
    constants: []
};

function ConstantReducers(state = initialState, action) {
    switch (action.type) {

        case LIST_ALL_CONSTANTS:
            return {
                ...state,
                constants: action.constants
            };

        default:
            return state;
    }
}

export default ConstantReducers;