import {
    REVIEW_SAMPLES, REVIEW_SAMPLE_FILES,
    REVIEW_SAMPLE_FILE_DIALOG_OPEN_CLOSE
} from "../../../../constant/actionType";

const initialState = {
    sampleSummary: {},
    files: [],
    isPreviewDialogOpen: false
};

function reviewSampleReducer(state = initialState, action) {
    switch (action.type) {
        case REVIEW_SAMPLES:
            return {
                ...state,
                sampleSummary: action.sampleSummary
            };

        case REVIEW_SAMPLE_FILES:
            return {
                ...state,
                files: action.files
            };

        case REVIEW_SAMPLE_FILE_DIALOG_OPEN_CLOSE:
            return {
                ...state,
                isPreviewDialogOpen: action.isDialogOpen
            };

        default:
            return state;
    }
}

export default reviewSampleReducer;
