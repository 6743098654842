import {
  LIST_ALL_SAMPLE_TYPE,
  SAMPLE_TYPE_MODAL_OPEN,
  SELECTED_TEST_TYPE,
  ORGANIZATION_USERS,
} from "../../../../constant/actionType";
import {
  MAPPED_VENDORS,
  SELECTED_HEADERS,
} from "../../../../constant/appStringConstant";

const initialState = {
  sampleTypes: [],
  modelOpen: false,
  selectedTestType: {},
  selectedHeader: {},
  selectedVendor: {},
  organizationUsers: [],
};

function sampleTypeReducers(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_SAMPLE_TYPE:
      return {
        ...state,
        sampleTypes: action.sampleTypes,
      };
    case SAMPLE_TYPE_MODAL_OPEN:
      return {
        ...state,
        modelOpen: action.modelOpen,
      };

    case SELECTED_TEST_TYPE:
      return { ...state, selectedTestType: action.selectedTestType };
    case SELECTED_HEADERS:
      return { ...state, selectedHeader: action.selectedHeader };
    case MAPPED_VENDORS:
      return { ...state, selectedVendor: action.selectedVendor };
    case ORGANIZATION_USERS:
      return {
        ...state,
        organizationUsers: action.organizationUsers,
      };

    default:
      return state;
  }
}

export default sampleTypeReducers;
