import {
    DispatchAlertError
} from "../alert/actions/alertAction";
import { CUSTOM_MESSAGE } from "./alertMessage";

export class ExceptionHandler {
    PushAPIErrorMessage(exception) {
        let errorMessage = "";

        if (exception.ValidationErrors) {
            if (exception.ValidationErrors.length > 0) {
                errorMessage = exception.ValidationErrors[0].ErrorMessage;
            }
        } else if (exception.ErrorMessage) {
            errorMessage = exception.ErrorMessage
        } else if (exception.title) {
            errorMessage = exception.title
        }

        else if (exception.message) {
            errorMessage = CUSTOM_MESSAGE;
        }
        else {
            if (exception.status) {
                errorMessage = exception.status.toString() + " - "
            } if (exception.statusText) {
                errorMessage += exception.statusText;
            }
            if (errorMessage.length <= 0) {
                errorMessage = CUSTOM_MESSAGE;
            }
        }
        DispatchAlertError(errorMessage);
    }
}