import {
  DASHBOARD_INFO, DASHBOARD_SKU
} from "../../../../constant/actionType";

const initialState = {
  dashboardInfo: {},
  commodities: []
};

function DashboardReducers(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_INFO:
      return {
        ...state,
        dashboardInfo: action.dashboardInfo
      };
    case DASHBOARD_SKU:
      return {
        ...state,
        commodities: action.commodities
      };
    default:
      return state;
  }
}

export default DashboardReducers;
