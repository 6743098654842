import { BULK_UPLOAD_FILE, BULK_UPLOAD_MODAL_OPEN } from "../../../constant/actionType";


const initialState = {
  bulkUploadFile: null,
  bulkUploadModalOpen: false,
};

function bulkUploadReducer(state = initialState, action) {
  switch (action.type) {
    case BULK_UPLOAD_MODAL_OPEN:
      return {
        ...state,
        bulkUploadModalOpen: action.bulkUploadModalOpen
      };
    case BULK_UPLOAD_FILE:
      return {
        ...state,
        bulkUploadFile: action.bulkUploadFile
      };
    default:
      return state;
  }
}

export default bulkUploadReducer;
