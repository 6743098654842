
import {  SAMPLE_TEST_TYPES } from '../../../../constant/actionType';

const initialState = {
    sampleTestTypes: []
};

function SampleTestTypesReducers(state = initialState, action) {     
    switch (action.type) {
        case SAMPLE_TEST_TYPES:
            return {
                ...state, sampleTestTypes: action.sampleTestTypes
            };
        default:
            return state;
    }
}

export default SampleTestTypesReducers;