import store from '../../../store/store';
import {
    ROLES, CREATE, READ, UPDATE, DELETE, READ_PERMISSION, CREATE_PERMISSION, DELETE_PERMISSION, UPDATE_PERMISSION,
    USER_ACCOUNT_DETAIL, EXTERNAL_USER_TECH_ROLE_LAB_IDS, CUSTOM_ROLE_JSON, LOGGED_USER_INFO,
    IS_TECH1_ROLE_ASSIGNED_TO_EXTERNAL_USER
} from '../../../constant/actionType';
import { GET_CURRENT_USER_INFO_API, GET_ROLE_DETAILS_API } from '../constants/urlConstant';
import { FetchApiMethods } from '../../utilities/fetchApiMethods';
import { ORGANISATION_NAME, APPLICATION_CODE } from '../../utilities/apiConfig';
import { DispatchCloseProgress, DispatchOpenProgress } from '../../progressDialog/actions/progressDialogAction';
import { ExceptionHandler } from '../../utilities/exceptionHandler';
import { EXTERNAL_USER_ROLE_ID } from "../../utilities/apiConfig";

const GetExternalUserPermission = (userRoles) => {
    DispatchOpenProgress();

    const ajaxCalls = new FetchApiMethods();
    const url = GET_ROLE_DETAILS_API(EXTERNAL_USER_ROLE_ID);

    ajaxCalls
        .getMethod(url)
        .then(json => {

            let tempAppSystems = [...json.applicationSystems]
            let tempFeatures = [];

            tempAppSystems.forEach(element => {

                let filterFeatures = element.roleFeatures.filter((data) => {
                    return data.featurePermissions.length > 0
                })
                tempFeatures = [{ ...element, roleFeatures: filterFeatures }]

            });
            let tempRoles = {
                ...json, "appSystems": tempFeatures,
                "userRoleId": 0, "roleFeatures": null
            }
            delete tempRoles.applicationSystems;
            let finnalArray = {
                ...userRoles, roles: [tempRoles]
            }

            RoleMapping(finnalArray);
            DispatchCloseProgress();
        })
        .catch(e => {
            DispatchLoggedUserInfo(null);
            const exceptionHandler = new ExceptionHandler();
            exceptionHandler.PushAPIErrorMessage(e);
            DispatchCloseProgress();
        });
}

export const GetRoleDetails = async (roleId) => {

    const ajaxCalls = new FetchApiMethods();
    const url = GET_ROLE_DETAILS_API(roleId);

    ajaxCalls
        .getMethod(url)
        .then(json => {

            let tempAppSystems = [...json.applicationSystems]
            let tempFeatures = [];

            tempAppSystems.forEach(element => {

                let filterFeatures = element.roleFeatures.filter((data) => {
                    return data.featurePermissions.length > 0
                })
                tempFeatures = [{ ...element, roleFeatures: filterFeatures }]

            });
            let tempRoles = {
                ...json, "appSystems": tempFeatures,
                "userRoleId": 0, "roleFeatures": null
            }
            delete tempRoles.applicationSystems;
            let finnalArray = {
                roles: [tempRoles]
            }

            DispatchCustomRoleDetails(finnalArray);
        })
        .catch(e => {
            const exceptionHandler = new ExceptionHandler();
            exceptionHandler.PushAPIErrorMessage(e);
            DispatchCustomRoleDetails(null);
        });
}

export async function GetCurrentUserRoles() {

    const ajaxCalls = new FetchApiMethods();
    const url = GET_CURRENT_USER_INFO_API();

    ajaxCalls
        .getMethod(url)
        .then(json => {
            if (!json.roles || json.roles.length === 0) {
                GetExternalUserPermission(json);
            } else {
                RoleMapping(json);
            }
            DispatchCloseProgress();
        })
        .catch(e => {
            DispatchLoggedUserInfo(null);
            const exceptionHandler = new ExceptionHandler();
            exceptionHandler.PushAPIErrorMessage(e);
            DispatchCloseProgress();
        });
}

export const RoleMapping = (json) => {

    let userInfo = { ...json };
    const featurePermissions = [];
    const glsRoleNames = [];

    json.roles.forEach(role => {

        glsRoleNames.push(role.roleName);

        role.appSystems.forEach(appsystem => {

            appsystem.roleFeatures.forEach(feature => {

                const itemIndex = featurePermissions.findIndex(f => f.featureName === feature.featureName);

                if (itemIndex === -1) {
                    const featureTemp = {
                        featureName: feature.featureName,
                        isRead: feature.featurePermissions.findIndex(permission => permission.operationName === "READ") > -1,
                        isCreate: feature.featurePermissions.findIndex(permission => permission.operationName === "CREATE") > -1,
                        isUpdate: feature.featurePermissions.findIndex(permission => permission.operationName === "UPDATE") > -1,
                        isDelete: feature.featurePermissions.findIndex(permission => permission.operationName === "DELETE") > -1
                    };

                    featurePermissions.push(featureTemp);
                } else {

                    if (!featurePermissions[itemIndex].isRead) {
                        featurePermissions[itemIndex].isRead = feature.featurePermissions.findIndex(permission => permission.operationName === "READ") > -1;
                    }

                    if (!featurePermissions[itemIndex].isCreate) {
                        featurePermissions[itemIndex].isCreate = feature.featurePermissions.findIndex(permission => permission.operationName === "CREATE") > -1;
                    }

                    if (!featurePermissions[itemIndex].isUpdate) {
                        featurePermissions[itemIndex].isUpdate = feature.featurePermissions.findIndex(permission => permission.operationName === "UPDATE") > -1;
                    }

                    if (!featurePermissions[itemIndex].isDelete) {
                        featurePermissions[itemIndex].isDelete = feature.featurePermissions.findIndex(permission => permission.operationName === "DELETE") > -1;
                    }
                }
            });
        });
    });

    userInfo.roles = { glsRoleNames, featurePermissions };
    const userInfoKeys = {
        roles: "Roles",
        loggedUser: "User"
    };

    userInfo = modifyKeyNames(userInfo, userInfoKeys);
    userInfo.User = modifyKeyNames(userInfo.User, { userName: "UserName", userId: "UserId", userEmail: "Email" })

    DispatchLoggedUserInfo(userInfo);
}


export const modifyKeyNames = (oldobj, newKeyValues) => {
    const keyValues = Object.keys(oldobj).map(key => {
        const newKey = newKeyValues[key] || key;
        return {
            [newKey]: oldobj[key]
        };
    });
    return Object.assign({}, ...keyValues);
}

export const DispatchRoles = (userInfo) => {
    store.dispatch({
        type: ROLES,
        userInfo: userInfo
    });
}

export const ResetStateValue = () => {

    UpdatePermission(false);
    ReadPermission(false);
    CreatePermission(false);
}

export const SearchRoles = (roles, featueName) => {

    let featurePermission;
    if (roles?.featurePermissions && roles.featurePermissions.length > 0) {
        featurePermission = roles.featurePermissions.find(element => element.featureName === featueName);
    }

    CreatePermission(featurePermission && featurePermission.isCreate ? true : false);
    ReadPermission(featurePermission && featurePermission.isRead ? true : false);
    UpdatePermission(featurePermission && featurePermission.isUpdate ? true : false);
    DeletePermission(featurePermission && featurePermission.isDelete ? true : false);
}

const getUpdateRoleName = (featureName) => {
    return ORGANISATION_NAME + "." + APPLICATION_CODE.toUpperCase() + "." + featureName + "." + UPDATE;
}

const getDeleteRoleName = (featureName) => {
    return ORGANISATION_NAME + "." + APPLICATION_CODE.toUpperCase() + "." + featureName + "." + DELETE;
}

const getReadRoleName = (featureName) => {
    return ORGANISATION_NAME + "." + APPLICATION_CODE.toUpperCase() + "." + featureName + "." + READ;
}

const getCreateRoleName = (featureName) => {
    return ORGANISATION_NAME + "." + APPLICATION_CODE.toUpperCase() + "." + featureName + "." + CREATE;
}


export const DeletePermission = deletePermission => {
    store.dispatch({ type: DELETE_PERMISSION, deletePermission });
};


export const UpdatePermission = updatePermission => {
    store.dispatch({ type: UPDATE_PERMISSION, updatePermission });
};

export const CreatePermission = createPermission => {
    store.dispatch({ type: CREATE_PERMISSION, createPermission });
};

export const ReadPermission = readPermission => {
    store.dispatch({ type: READ_PERMISSION, readPermission });
};

export const DispatchUserAccountInfo = accountDetail => {
    store.dispatch({
        type: USER_ACCOUNT_DETAIL,
        accountDetail: {
            name: accountDetail.name,
            userName: accountDetail.userName
        }
    });
};

export const DispatchExternalUserTechRoleLabIds = (labIds) => {
    store.dispatch({
        type: EXTERNAL_USER_TECH_ROLE_LAB_IDS,
        labIds: labIds
    });
};

export const DispatchCustomRoleDetails = (roleJson) => {
    store.dispatch({
        type: CUSTOM_ROLE_JSON,
        customRoleJson: roleJson
    });
};

export const DispatchLoggedUserInfo = (userInfo) => {
    store.dispatch({
        type: LOGGED_USER_INFO,
        loggedUser: userInfo
    });
};

export const DispatchIsTech1RoleAssigned = (isTech1RoleAssigned) => {
    store.dispatch({
        type: IS_TECH1_ROLE_ASSIGNED_TO_EXTERNAL_USER,
        isTech1RoleAssigned: isTech1RoleAssigned
    });
};