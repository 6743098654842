import {
    SAMPLE_TYPE_SKU_OPEN_CLOSE, SAMPLE_TYPE_SKU
} from "../../../../constant/actionType";

const initialState = {
    isSampleTypeSkuMappingModalOpen: false,
    selectedSampleType: null,
    mappedSkus: []
};

function sampleTypeSkuReducer(state = initialState, action) {

    switch (action.type) {
        case SAMPLE_TYPE_SKU_OPEN_CLOSE:
            return {
                ...state,
                selectedSampleType: action.data.sampleType,
                isSampleTypeSkuMappingModalOpen: action.data.isOpen
            };

        case SAMPLE_TYPE_SKU:
            return {
                ...state,
                mappedSkus: action.data
            };

        default:
            return state;
    }
}

export default sampleTypeSkuReducer;
