var appSettings = require("./appSettings.json");

export const GLS_APP_URL = appSettings.gls_app_url;
export const GLS_API_URL = appSettings.gls_api_url;
export const SIOC_POWERBI_REPORT_API_URL = appSettings.sioc_powerbi_api_url;
export const GLS_QTIL_AUTH_API_URL = appSettings.gls_auth_api_url;
export const FILE_PROCESSOR_API_URL = appSettings.file_processor_api_url;
export const SERIAL_API_URL = appSettings.serial_api_url;
export const GRAPH_API_URL = appSettings.graph_api_url;
export const APPLICATION_CODE = appSettings.application_code;
export const MODULE_NAME = appSettings.module_name;
export const ADMIN_EMAIL_ID = appSettings.admin_email_id;
export const ORGANISATION_NAME = appSettings.organisation_name;
export const USER_MANAGEMENT_APP_URL = appSettings.user_managment_app_url;
export const POWERBI_API_URL = appSettings.powerbi_api_url;
export const GLS_SIOC_ISTA_PDF_URL = appSettings.gls_sioc_ista_pdf_url;
export const LIMIT_TAG_MAX_LENGTH = appSettings.limitTagMaxlength;
export const GLS_DEFAULT_FILE_PATH = appSettings.gls_file_url; 
export const ACCOUNT_TYPE_ID = appSettings.account_type_id;
export const EXTERNAL_USER_ROLE_ID = parseInt(appSettings.gls_external_user_role_id);
export const EXTERNAL_USER_ROLE_NAME = appSettings.gls_external_user_role_name;
export const TECH1_USER_ROLE_ID = parseInt(appSettings.gls_tech1_user_role_id);
export const TECH1_USER_ROLE_NAME = appSettings.gls_tech1_user_role_name;
export const PUSH_ACCESS_ROLES = appSettings.gls_push_access_roles;

export const GRAPH_FILTER_INCLUDE_DOMAIN_LIST = appSettings.graph_filter_include_domain_list;
export const GRAPH_FILTER_DISCARD_DOMAIN_LIST = appSettings.graph_filter_discard_domain_list;

