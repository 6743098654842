import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Outlet } from "react-router-dom";
import HeaderMenu from "./headerMenu";
import PageRouterView from "./routerView";
import SuccessAlertSnackbar from "../alert/components/successAlertSnackbar";
import WarningAlertSnackbar from "../alert/components/warningAlertSnackbar";
import ErrorAlertSnackbar from "../alert/components/errorAlertSnackbar";
import ProgressDialog from "../progressDialog/components/progressDialog";
import QRCodeScannerDialog from '../../modules/test-creation/qr-code/components/qrCodeScanner';
import { GetCurrentUserRoles, DispatchUserAccountInfo, DispatchExternalUserTechRoleLabIds, GetRoleDetails, DispatchRoles, DispatchIsTech1RoleAssigned } from "../rbac/actions/rbacAction";
import { DispatchOpenProgress, DispatchCloseProgress } from "../progressDialog/actions/progressDialogAction";
import { FetchAllStatus } from "../../modules/test-creation/sample-group/actions/action";
import { AuthProvider } from "../authentication/authProvider";
import { FetchUserLabs } from "../../modules/setup/lab/actions/action";
import { EXTERNAL_USER_ROLE_NAME, TECH1_USER_ROLE_ID } from "../utilities/apiConfig";

const HomePage = props => {

    const [isExternalUserLogged, setIsExternalUserLogged] = React.useState(false);

    useEffect(() => {
        DispatchOpenProgress();
        DispatchIsTech1RoleAssigned(false);
        const accounts = AuthProvider.getAllAccounts();
        DispatchUserAccountInfo(accounts.length > 0 ? accounts[0] : []);

        GetCurrentUserRoles();
        FetchAllStatus();

    }, []);

    useEffect(() => {
        DispatchOpenProgress();

        if (props.loggedUser && props.loggedUser.User && props.loggedUser.User.UserId && props.loggedUser.User.UserId > 0) {

            const isExternalUser = props.loggedUser.Roles && props.loggedUser.Roles.glsRoleNames
                && props.loggedUser.Roles.glsRoleNames.length === 1
                && props.loggedUser.Roles.glsRoleNames[0] === EXTERNAL_USER_ROLE_NAME ? true : false;

            FetchUserLabs(props.loggedUser.User.UserId);

            if (!isExternalUser) {
                const tempUserInfo = {
                    User: { ...props.loggedUser.User },
                    Roles: {
                        glsRoleNames: props.loggedUser.Roles.glsRoleNames.map(roleName => roleName),
                        featurePermissions: props.loggedUser.Roles.featurePermissions.map(col => { return { ...col } })
                    }
                };

                setIsExternalUserLogged(false);
                DispatchRoles(tempUserInfo);
            }
        }

        DispatchCloseProgress();

    }, [props.loggedUser]);


    useEffect(() => {
        DispatchOpenProgress();
        let isExternalUser = false;
        let labIds = [];

        if (props.loggedUser && props.loggedUser.Roles && props.loggedUser.Roles.glsRoleNames
            && props.loggedUser.Roles.glsRoleNames.length === 1 && props.loggedUser.Roles.glsRoleNames[0] === EXTERNAL_USER_ROLE_NAME) {

            isExternalUser = true;
        }

        if (isExternalUser && props.userLabs && props.userLabs.length > 0) {
            labIds = props.userLabs.filter((col) => col.isAssignTechRole).map((col) => col.labId);
        }

        setIsExternalUserLogged(isExternalUser);

        if (labIds.length > 0) {
            GetRoleDetails(TECH1_USER_ROLE_ID);
            DispatchExternalUserTechRoleLabIds(labIds);
        }
        else if (props.loggedUser && props.loggedUser.User && props.loggedUser.User.UserId && props.loggedUser.User.UserId > 0) {
            const tempUserInfo = {
                User: { ...props.loggedUser.User },
                Roles: {
                    glsRoleNames: props.loggedUser.Roles.glsRoleNames.map(roleName => roleName),
                    featurePermissions: props.loggedUser.Roles.featurePermissions.map(col => { return { ...col } })
                }
            };
            DispatchRoles(tempUserInfo);
            DispatchExternalUserTechRoleLabIds([]);
        }

        DispatchCloseProgress();

    }, [props.userLabs, props.loggedUser]);

    useEffect(() => {
        DispatchOpenProgress();

        if (isExternalUserLogged && props.loggedUser && props.tech1Roles && props.tech1Roles.roles && props.tech1Roles.roles.length > 0) {
            const tempLoggedUser = {
                Roles: {
                    glsRoleNames: [EXTERNAL_USER_ROLE_NAME],
                    featurePermissions: props.loggedUser.Roles.featurePermissions.map(col => { return { ...col } })
                }
            };

            for (const tempRole of props.tech1Roles.roles.filter(col => col.roleId === TECH1_USER_ROLE_ID)) {

                if (!tempLoggedUser.Roles.glsRoleNames.includes(tempRole.roleName)) {
                    tempLoggedUser.Roles.glsRoleNames.push(tempRole.roleName);
                }

                for (const appsystem of tempRole.appSystems) {

                    for (const feature of appsystem.roleFeatures) {
                        const itemIndex = tempLoggedUser.Roles.featurePermissions.findIndex(f => f.featureName === feature.featureName);

                        if (itemIndex === -1) {
                            const featureTemp = {
                                featureName: feature.featureName,
                                isRead: feature.featurePermissions.findIndex(permission => permission.operationName === "READ") > -1,
                                isCreate: feature.featurePermissions.findIndex(permission => permission.operationName === "CREATE") > -1,
                                isUpdate: feature.featurePermissions.findIndex(permission => permission.operationName === "UPDATE") > -1,
                                isDelete: feature.featurePermissions.findIndex(permission => permission.operationName === "DELETE") > -1
                            };

                            tempLoggedUser.Roles.featurePermissions.push(featureTemp);
                        } else {

                            if (!tempLoggedUser.Roles.featurePermissions[itemIndex].isRead) {
                                tempLoggedUser.Roles.featurePermissions[itemIndex].isRead = feature.featurePermissions.findIndex(permission => permission.operationName === "READ") > -1;
                            }

                            if (!tempLoggedUser.Roles.featurePermissions[itemIndex].isCreate) {
                                tempLoggedUser.Roles.featurePermissions[itemIndex].isCreate = feature.featurePermissions.findIndex(permission => permission.operationName === "CREATE") > -1;
                            }

                            if (!tempLoggedUser.Roles.featurePermissions[itemIndex].isUpdate) {
                                tempLoggedUser.Roles.featurePermissions[itemIndex].isUpdate = feature.featurePermissions.findIndex(permission => permission.operationName === "UPDATE") > -1;
                            }

                            if (!tempLoggedUser.Roles.featurePermissions[itemIndex].isDelete) {
                                tempLoggedUser.Roles.featurePermissions[itemIndex].isDelete = feature.featurePermissions.findIndex(permission => permission.operationName === "DELETE") > -1;
                            }
                        }
                    }
                }
            }

            const tempUserInfo = {
                User: { ...props.loggedUser.User },
                Roles: {
                    glsRoleNames: tempLoggedUser.Roles.glsRoleNames.map(roleName => roleName),
                    featurePermissions: tempLoggedUser.Roles.featurePermissions.map(col => { return { ...col } })
                }
            };
            DispatchRoles(tempUserInfo);
            DispatchIsTech1RoleAssigned(true);
        }
        DispatchCloseProgress();
    }, [props.loggedUser, props.tech1Roles, isExternalUserLogged]);


    return (
        <React.Fragment>
            {props.userInfo && props.userInfo.Roles &&
                <>
                    <SuccessAlertSnackbar />

                    <ErrorAlertSnackbar />

                    <WarningAlertSnackbar />

                    <ProgressDialog />

                    <QRCodeScannerDialog />

                    <BrowserRouter>

                        <HeaderMenu latestBuildDate={props.latestBuildDate} />

                        <Outlet />
                        <PageRouterView roles={props.userInfo && props.userInfo.Roles ? props.userInfo.Roles : null} />

                    </BrowserRouter>
                </>
            }
        </React.Fragment>
    );
};

const mapStateToProps = state => {

    return {
        userInfo: state.rbacReducer.userInfo,
        userLabs: state.labReducers.userLabs,
        tech1Roles: state.rbacReducer.customRoleJson,
        loggedUser: state.rbacReducer.loggedUser
    };
};

export default connect(mapStateToProps, { GetCurrentUserRoles })(HomePage);