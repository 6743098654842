import { ERROR_ALERT_MESSAGE, SUCCESS_ALERT_MESSAGE, CLEAR_ALERT_MESSAGE, WARNING_ALERT_MESSAGE } from '../../../constant/actionType';

const initialState = {
    data: { isSuccess: false, isError: false, isWarning: false, message: "" }
};

function AlertReducer(state = initialState, action) {
    switch (action.type) {
        case SUCCESS_ALERT_MESSAGE:
        case WARNING_ALERT_MESSAGE:
        case CLEAR_ALERT_MESSAGE:
        case ERROR_ALERT_MESSAGE:
            return {
                ...state,
                data: action.data
            };

        default:
            return state;
    }
}

export default AlertReducer;