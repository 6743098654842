import {
  LIST_ALL_REVIEW, LIST_ALL_FILTER_VALUES
} from "../../../../constant/actionType";

const initialState = {
  samplesGridInfo: null,
  filterInfo: {},
};

function sampleGroupFilterReducers(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_REVIEW: {
      return {
        ...state, samplesGridInfo: action.samplesGridInfo
      };
    }
    case LIST_ALL_FILTER_VALUES:
      return {
        ...state, filterInfo: action.filterInfo
      };
    default:
      return state;
  }
}
export default sampleGroupFilterReducers;
