import { IS_OPEN_FILE_UPLOAD, DELETED_FILE, DROP_ZONE_FILE_DROPPED, FILE_UPLOAD_SUBMIT, CLEAR_FILE_UPLOAD_SUBMIT } from '../../../../constant/actionType';

const initialState = {
    isOpenFileUpload: false,
    isUploadImagesOnly: false,
    sampleGroupId: 0,
    testTypeId: 0,
    sampleTestId: 0,
    cycleId: 0,
    questionId: 0,
    maxFileCount: 0,
    uploadedFiles: [],
    deletedFiles: [],
    sampleTestFiles: [],
    fileIdsTodelete: []
};

function fileConfReducer(state = initialState, action) {
    switch (action.type) {
        case IS_OPEN_FILE_UPLOAD:
            return {
                ...state,
                isOpenFileUpload: action.data.isOpenFileUpload,
                sampleGroupId: action.data.sampleGroupId,
                testTypeId: action.data.testTypeId,
                sampleTestId: action.data.sampleTestId,
                questionId: action.data.questionId ? action.data.questionId : 0,
                maxFileCount: action.data.maxFileCount ? action.data.maxFileCount : 0,
                cycleId: action.data.cycleId,
                uploadedFiles: action.data.uploadedFiles,
                isUploadImagesOnly: action.data.isUploadImagesOnly,
                deletedFiles: []
            };

        case DELETED_FILE:

            state.deletedFiles.push(action.data.fileId);
            return {
                ...state,
                uploadedFiles: action.data.uploadedFiles
            };

        case DROP_ZONE_FILE_DROPPED:
            return {
                ...state,
                uploadedFiles: action.data.uploadedFiles
            };

        case FILE_UPLOAD_SUBMIT:

            let deletedIds = [...state.fileIdsTodelete];

            if (action.data.deletedFileIds && action.data.deletedFileIds.length > 0) {
                for (let deletedFileId of action.data.deletedFileIds) {
                    if (deletedIds.findIndex(col => col === deletedFileId) === -1) {
                        deletedIds.push(deletedFileId);
                    }
                }
            }
            return {
                ...state,
                sampleTestFiles: action.data.sampleTestFiles,
                fileIdsTodelete: deletedIds
            }

        case CLEAR_FILE_UPLOAD_SUBMIT:
            return {
                ...state,
                sampleTestFiles: action.data.sampleTestFiles,
                fileIdsTodelete: []
            }

        default:
            return state;
    }
}

export default fileConfReducer;