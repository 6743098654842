import {
  LIST_ALL_SKU, SAMPLE_TYPE_SKUS, SELECTED_SKUS, OPEN_CREATE_FORM, API_EXECUTION_STARTED, SHOW_SKU_LIST, SAMPLE_TYPE_SAMPLE_GROUP_SKUS, SKU_API_LOADER
} from "../../../../constant/actionType";

const initialState = {
  isOpenCreateForm: false,
  isAPIExecutionStarted: false,
  isShowSKU: false,
  listAllSku: [],
  sampleTypeSkus: [],
  sampleTypeSampleGroupSkus: [],
  isSkuAPICalling:false
};

function skuReducers(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_SKU:
      return {
        ...state,
        listAllSku: action.listAllSku
      };
      
      case SKU_API_LOADER:
      return {
        ...state,
        isSkuAPICalling: action.isSkuAPICalling 
      };
    case SAMPLE_TYPE_SKUS:
      return {
        ...state,
        sampleTypeSkus: action.sampleTypeSkus
      };
    case SAMPLE_TYPE_SAMPLE_GROUP_SKUS:
      return {
        ...state,
        sampleTypeSampleGroupSkus: action.sampleTypeSampleGroupSkus
      };

    case OPEN_CREATE_FORM:
      return {
        ...state,
        isOpenCreateForm: action.isOpen
      };
    case SELECTED_SKUS:
      return {
        ...state,
        selectedSku: action.selectedSku
      };
    case API_EXECUTION_STARTED:
      return {
        ...state,
        isAPIExecutionStarted: action.isAPIStarted
      };

    case SHOW_SKU_LIST:
      return {
        ...state,
        isShowSKU: action.isShowSKU
      };

    default:
      return state;
  }
}

export default skuReducers;
