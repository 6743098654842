import { combineReducers } from "redux";
import sampleTypeReducers from "../modules/setup/sample-type/reducers/sampleTypeReducers";
import testTypeReducers from "../modules/setup/test-type/reducers/testTypeReducers";
import labReducers from "../modules/setup/lab/reducers/labReducers";
import SampleGroupSummaryReducers from "../modules/test-creation/sample-group/reducers/sampleGroupSummaryReducers";
import questionReducer from "../modules/setup/question/reducers/questionReducers";
import headerMappingReducers from "../modules/setup/header-mapping/reducers/headerMappingReducer";
import questionMappingReducers from "../modules/setup/sample-type-question-mapping/reducers/questionMappingReducer";
import headerFieldReducers from "../modules/setup/header-field/reducers/headerFieldReducers";
import skuReducers from "../modules/setup/sku-setup/reducers/skuReducers";
import autoTestRequestReducers from "../modules/setup/auto-test-creation-mapping/reducers/reducers";
import QuestionConstraintReducer from "../modules/setup/question-constraints/reducers/questionConstraintReducer";
import AlertReducer from "../shared-component/alert/reducers/alertReducer";
import ProgressDialogReducer from "../shared-component/progressDialog/reducers/progressDialogReducer";
import SampleGroupReducer from "../modules/test-creation/sample-group-creation/reducers/sampleGroupReducer";
import ConstantReducers from "../modules/setup/constants/reducers/constantReducers";
import SampleReducer from "../modules/test-creation/sample-landing/reducers/sampleReducer";
import sampleGroupTestsReducers from "../modules/test-creation/sample-group-test-types/reducers/samplegrouptestsreducers";
import TestQuestionViewBySampleReducers from "../modules/test-creation/test-question-sample/reducers/testQuestionSampleReducers";
import SampleTestTypesReducers from "../modules/test-creation/sample-test/reducers/sampleTestTypeReducers";
import sampleTestCycleReducers from "../modules/test-creation/sampletest-cycle/reducers/sampleTestCycleReducers";
import approvalLandingReducers from "../modules/review/approval-landing/reducers/approvalLandingReducers";
import reviewReportReducers from "../modules/review/sample-group-review/reducers/reviewReportReducers";
import RbacReducer from "../shared-component/rbac/reducers/rbacReducer";
import SplitButtonReducer from "../shared-component//split-buttons/reducers/splitButtonReducer";
import testCycleConfReducer from "../modules/test-creation/test-question-cycle-conf/reducers/testCycleConfReducer";
import fileUploadReducer from "../modules/test-creation/file-upload/reducers/fileUploadReducer";
import multiFileUploadReducer from "../shared-component/multiFileUpload/reducers/multiFileUploadReducer";
import reviewSampleReducer from "../modules/review/samples-page/reducers/reviewSampleReducer";
import sampleGroupFilterReducers from "../modules/review/sample-group-filters/reducers/sampleGroupFilterReducers";
import TestRequestReducer from "../modules/test-creation/test-request-form/reducers/createTestRequestReducer";
import sampleTypeReportReducer from "../modules/review/export-sample-type-report/reducers/sampleTypeReportReducer";
import TestRequestSummaryReducers from "../modules/test-creation/test-request-summary/reducers/testRequestSummaryReducer";
import DashboardReducers from "../modules/dashboard/status/reducers/dashboardReducers";
import qrCodeReducer from "../modules/test-creation/qr-code/reducers/qrCodeReducer";
import FilterReducer from "../shared-component/filters/reducers/filterReducer";
import barcodeReducer from "../shared-component/barcode/reducers/barcodeReducer";
import equipmentReducers from "../modules/setup/equipment/reducers/equipmentReducers";
import labEquipmentReducers from "../modules/setup/lab-equipment-mapping/reducers/labEquipmentReducers";
import labUserReducers from "../modules/setup/lab-user-mapping/reducer/labuserreducer";
import sampleGroupLabEquipmentReducer from "../modules/test-creation/sample-group-lab-equipments/reducers/sampleGroupLabEquipmentReducer";
import vendorReducers from "../modules/setup/Vendor/reducers/vendorReducers";
import sampleTypeLabReducer from "../modules/setup/sample-type-lab-mapping/reducers/sampleTypeLabReducer";
import approvedSkuReducers from "../modules/setup/sku-database/reducers/approvedSkuReducers";
import sampleTypeSkuReducer from "../modules/setup/sample-type-sku-mapping/reducers/sampleTypeSkuReducer";
import bulkUploadReducer from "../shared-component/bulk-upload/reducers/reducer";

const reducer = combineReducers({
  sampleTypeReducers: sampleTypeReducers,
  testTypeReducers: testTypeReducers,
  labReducers: labReducers,
  questionReducers: questionReducer,
  headerMappingReducers: headerMappingReducers,
  questionMappingReducers: questionMappingReducers,
  headerFieldReducers: headerFieldReducers,
  skuReducers: skuReducers,
  autoTestRequestReducers: autoTestRequestReducers,
  questionConstraintReducer: QuestionConstraintReducer,
  alertReducer: AlertReducer,
  progressDialogReducer: ProgressDialogReducer,
  sampleGroupSummaryReducers: SampleGroupSummaryReducers,
  sampleGroupReducer: SampleGroupReducer,
  constantReducers: ConstantReducers,
  sampleReducer: SampleReducer,
  sampleGroupTestsReducers: sampleGroupTestsReducers,
  testQuestionViewBySampleReducers: TestQuestionViewBySampleReducers,
  sampleTestTypesReducers: SampleTestTypesReducers,
  sampleTestCycleReducers: sampleTestCycleReducers,
  approvalLandingReducers: approvalLandingReducers,
  reviewReportReducers: reviewReportReducers,
  reviewSampleReducer: reviewSampleReducer,
  rbacReducer: RbacReducer,
  approvedSkuReducers: approvedSkuReducers,
  vendorReducers: vendorReducers,
  splitButtonAction: SplitButtonReducer,
  testCycleConfReducer: testCycleConfReducer,
  multifileUploadReducer: multiFileUploadReducer,
  fileUploadReducer: fileUploadReducer,
  sampleGroupFilterReducers: sampleGroupFilterReducers,
  sampleTypeReportReducer: sampleTypeReportReducer,
  TestRequestReducer: TestRequestReducer,
  TestRequestSummaryReducers: TestRequestSummaryReducers,
  DashboardReducers: DashboardReducers,
  qrCodeReducer: qrCodeReducer,
  filterReducer: FilterReducer,
  barcodeReducer: barcodeReducer,
  equipmentReducers: equipmentReducers,
  labEquipmentReducers: labEquipmentReducers,
  labUserReducers: labUserReducers,
  sampleGroupLabEquipmentReducer: sampleGroupLabEquipmentReducer,
  sampleTypeLabReducer: sampleTypeLabReducer,
  sampleTypeSkuReducer: sampleTypeSkuReducer,
  bulkUploadReducer: bulkUploadReducer
});

export default reducer;