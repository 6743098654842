
import {
    LIST_ALL_LAB, LIST_ALL_USER, LIST_ALL_SITE, LAB_MODEL_OPEN, SELECTED_LAB, LAB_SAMPLE_TYPE_OPEN_CLOSE, LAB_USER_OPEN_CLOSE,
    LAB_SAMPLE_TYPES, USER_LABS
} from '../../../../constant/actionType';

const initialState = {
    listAllLab: [],
    listAllSite: [],
    modelOpen: false,
    selectedLab: null,
    isLabUserMappingDialogOpen: false,
    isLabSampleTypeMappingDialogOpen: false,
    labSampleTypes: null,
    userLabs: []
};

function labReducers(state = initialState, action) {
    switch (action.type) {
        case LIST_ALL_LAB:
            return {
                ...state, listAllLab: action.listAllLab
            };

        case LIST_ALL_SITE:
            return {
                ...state,
                listAllSite: action.listAllSite
            };

        case LAB_MODEL_OPEN:
            return {
                ...state, modelOpen: action.modelOpen
            };

        case SELECTED_LAB:
            return {
                ...state, selectedLab: action.selectedLab
            };

        case LIST_ALL_USER:
            return {
                ...state, users: action.users
            };

        case LAB_SAMPLE_TYPE_OPEN_CLOSE:
            return {
                ...state,
                isLabSampleTypeMappingDialogOpen: action.isOpen
            };

        case LAB_USER_OPEN_CLOSE:
            return {
                ...state,
                isLabUserMappingDialogOpen: action.isOpen
            };

        case LAB_SAMPLE_TYPES:
            return {
                ...state,
                labSampleTypes: action.labSampleTypes
            };

        case USER_LABS:
            return {
                ...state,
                userLabs: action.userLabs
            };

        default:
            return state;
    }
}

export default labReducers;