import {
  APPROVED_SKU_MODAL_OPEN, 
  APPROVED_SKUS
} from "../../../../constant/actionType";

const initialState = {
  vendors: [],
  modelOpen: false,   
  approvedSkus: []
};

function approvedSkuReducers(state = initialState, action) {

  switch (action.type) {
    case APPROVED_SKUS:
      return {
        ...state,
        approvedSkus: action.approvedSkus
      };
    case APPROVED_SKU_MODAL_OPEN:
      return {
        ...state,
        modelOpen: action.modelOpen
      };
    default:
      return state;
  }
}

export default approvedSkuReducers;
