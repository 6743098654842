export const LIST_ALL_SAMPLE_TYPE = "LIST_ALL_SAMPLE_TYPE";
export const LIST_ALL_SAMPLE_TYPE_INACTIVE = "LIST_ALL_SAMPLE_TYPE_INACTIVE";
export const BULK_UPLOAD_MODAL_OPEN = "BULK_UPLOAD_MODAL_OPEN";
export const BULK_UPLOAD_FILE = "BULK_UPLOAD_FILE";
export const MERGE_VENDOR = "MERGE_VENDOR";
export const UNSELECTED_VENDORS = "UNSELECTED_VENDORS";
export const CONTACT_PERSON_EMAILS_MODEL = "CONTACT_PERSON_EMAILS_MODEL";
export const INTERVAL = "INTERVAL";
export const LIST_ALL_AGGREGATE_OPTIONS = "LIST_ALL_AGGREGATE_OPTIONS";
export const LIST_ALL_TEST_TYPE = "LIST_ALL_TEST_TYPE";
export const QUESTION_TEST_TYPES = "QUESTION_TEST_TYPES";
export const LIST_ALL_QUESTION = "LIST_ALL_QUESTION";
export const REFRESH_QUESTIONS_TABLE = "REFRESH_QUESTIONS_TABLE";
export const LIST_QUESTION_SUMMARY = "LIST_QUESTION_SUMMARY";
export const LIST_ALL_HEADER_FIELD = "LIST_ALL_HEADER_FIELD";
export const SELECTED_HEADER_FIELD = "SELECTED_HEADER_FIELD";
export const SELECTED_HEADER_FIELD_OPTION = "SELECTED_HEADER_FIELD_OPTION";
export const SAMPLEGROUP_FILECOUNT = "SAMPLEGROUP_FILECOUNT";
export const PREDEFINED_OPTIONS = "PREDEFINED_OPTIONS";
export const LIST_ALL_UNIT_TYPE = "LIST_ALL_UNIT_TYPE";
export const LIST_ALL_LAB = "LIST_ALL_LAB";
export const LIST_ALL_USER = "LIST_ALL_USER";
export const SELECTED_LAB = "SELECTED_LAB";
export const LAB_USERS = "LAB_USERS";
export const LIST_ALL_SITE = "LIST_ALL_SITE";
export const LIST_ALL_SKU = "LIST_ALL_SKU";
export const SKU_API_CALL = "SKU_API_CALL";
export const SAMPLE_TYPE_API_CALL = "SAMPLE_TYPE_API_CALL";
export const LIST_ALL_ATRC_MAPPING = "LIST_ALL_ATRC_MAPPING";
export const SAMPLE_TYPE_SAMPLE_GROUP_SKUS = "SAMPLE_TYPE_SAMPLE_GROUP_SKUS";
export const SKU_SAMPLE_TYPES = "SKU_SAMPLE_TYPES";
export const SAMPLE_TYPE_SKUS = "SAMPLE_TYPE_SKUS";
export const SAMPLE_GROUPS = "SAMPLE_GROUPS";
export const SELECTED_SAMPLE_GROUPS = "SELECTED_SAMPLE_GROUPS";
export const SELECTED_SAMPLE_GROUP = "SELECTED_SAMPLE_GROUP";
export const REFRESH_SAMPLEGROUP_TABLE = "REFRESH_SAMPLEGROUP_TABLE";
export const REFRESH_TESTQUESTIONVIEW_TABLE = "REFRESH_TESTQUESTIONVIEW_TABLE";
export const SELECTED_SAMPLE_GROUPS_PAGE_OPEN = "SELECTED_SAMPLE_GROUPS_PAGE_OPEN";
export const SAMPLE_GROUP_FILES = "SAMPLE_GROUP_FILES";
export const STATUS_CHANGE_SUCCESS = "STATUS_CHANGE_SUCCESS";
export const LIST_ALL_HEADER_MAPPING = "LIST_ALL_HEADER_MAPPING";
export const LIST_ALL_SAMPLE_TYPE_QUESTIONS = "LIST_ALL_SAMPLE_TYPE_QUESTIONS";
export const LIST_ALL_SAMPLE_TYPE_HEADER = "LIST_ALL_SAMPLE_TYPE_HEADER";
export const SAMPLE_TEST_TYPES = "SAMPLE_TEST_TYPES";
export const TEST_TYPE_QUESTIONS = "TEST_TYPE_QUESTIONS";
export const TEST_TYPE_QUESTIONS_SUCCESS_CALLBACK = "TEST_TYPE_QUESTIONS_SUCCESS_CALLBACK";
export const SAMPLE_GROUP_TEST_TYPES = "SAMPLE_GROUP_TEST_TYPES";
export const SELECTED_QUESTION = "SELECTED_QUESTION";
export const SELECTED_QUESTION_OPTION = "SELECTED_QUESTION_OPTION";
export const LIST_ALL_INPUT_TYPE = "LIST_ALL_INPUT_TYPE";
export const LIST_ALL_DATA_TYPE = "LIST_ALL_DATA_TYPE";
export const QUESTION_CONSTRAINT = "QUESTION_CONSTRAINT";
export const LIST_ALL_QUESTION_CONTRAINTS = "LIST_ALL_QUESTION_CONTRAINTS";
export const SAVE_SUCCESS_QUESTION_CONTRAINTS =
  "SAVE_SUCCESS_QUESTION_CONTRAINTS";
export const OPEN_CONTRAINT_CREATE_FORM = "OPEN_CONTRAINT_CREATE_FORM";
export const LIST_ALL_REVIEW = "LIST_ALL_REVIEW";
export const LIST_SAMPLE_GROUP_REVIEW_FILE = "LIST_SAMPLE_GROUP_REVIEW_FILE";
export const REVIEW_MODULE_FILTERS = "REVIEW_MODULE_FILTERS";
export const REVIEW_MODULE_FILTERS_LABS = "REVIEW_MODULE_FILTERS_LABS";
export const REVIEW_MODULE_FILTERS_VENDORS = "REVIEW_MODULE_FILTERS_VENDORS";
export const REVIEW_MODULE_FILTERS_SKUS = "REVIEW_MODULE_FILTERS_SKUS";
export const REVIEW_MODULE_FILTERS_REQUEST_BY = "REVIEW_MODULE_FILTERS_REQUEST_BY";
export const REQUEST_BY_API_CALLING = "REQUEST_BY_API_CALLING";
export const LAB_API_CALLING = "LAB_API_CALLING";
export const VENDOR_API_CALLING = "VENDOR_API_CALLING";
export const SKU_API_CALLING = "SKU_API_CALLING";
export const CLOSE_MANAGER_NOTES_POPUP = "CLOSE_MANAGER_NOTES_POPUP";
export const REMAINDER_NOTIFICATION_PERIOD ="REMAINDER_NOTIFICATION_PERIOD";

export const SAMPLEGROUP_FILE_DIALOG_OPEN_CLOSE = "SAMPLEGROUP_FILE_DIALOG_OPEN_CLOSE";
export const LIST_ALL_FILTER_VALUES = "LIST_ALL_FILTER_VALUES";
export const SUCCESS_ALERT_MESSAGE = "SUCCESS_ALERT_MESSAGE";
export const WARNING_ALERT_MESSAGE = "WARNING_ALERT_MESSAGE";
export const ERROR_ALERT_MESSAGE = "ERROR_ALERT_MESSAGE";
export const CLEAR_ALERT_MESSAGE = "CLEAR_ALERT_MESSAGE";
export const API_EXECUTION_STARTED = "API_EXECUTION_STARTED";
export const SELECTED_TESTS = "SELECTED_TESTS";
export const SELECTED_SKUS = "SELECTED_SKUS";
export const SAMPLE_TYPE_ID = "SAMPLE_TYPE_ID";
export const SELECTED_TESTS_CHANGE = "SELECTED_TESTS_CHANGE";
export const SKU_API_LOADER = "SKU_API_LOADER";
export const TEST_TYPE_LAB_EQUIPMENTS = "TEST_TYPE_LAB_EQUIPMENTS";
export const OPEN_PROGRESS_DIALOG = "OPEN_PROGRESS_DIALOG";
export const CLOSE_PROGRESS_DIALOG = "CLOSE_PROGRESS_DIALOG";

export const RESULT_OPTIONS = "RESULT_OPTIONS";
export const STATUS_OPTIONS = "STATUS_OPTIONS";

export const RESULT_FILTER_OPTIONS = "RESULT_FILTER_OPTIONS";
export const STATUS_FILTER_OPTIONS = "STATUS_FILTER_OPTIONS";

export const OPEN_CREATE_FORM = "OPEN_CREATE_FORM";
export const SAMPLE_GROUP_HEADERS = "SAMPLE_GROUP_HEADERS";
export const SAMPLE_GROUP_SUCCESS = "SAMPLE_GROUP_SUCCESS";
export const SG_UPDATE_STATUS = "SG_UPDATE_STATUS";
export const LIST_ALL_CONSTANTS = "LIST_ALL_CONSTANTS";
export const LIST_QUESTION_FORMULA = "LIST_QUESTION_FORMULA";
export const LIST_NUMBER_TYPE_QUESTIONS = "LIST_NUMBER_TYPE_QUESTIONS";
export const VALIDATE_QUESTION_FORMULA = "VALIDATE_QUESTION_FORMULA";
export const QUESTION_FORMULA_DIALOG_OPEN_CLOSE =
  "QUESTION_FORMULA_DIALOG_OPEN_CLOSE";
export const PURPOSE_OF_TEST = "PURPOSE_OF_TEST";

export const LIST_ALL_TEST_TYPE_QUESTIONS = "LIST_ALL_TEST_TYPE_QUESTIONS";
export const NUMBER_TYPE_TEST_QUESTIONS = "NUMBER_TYPE_TEST_QUESTIONS";
export const NUMBER_TYPE_HEADERS = "NUMBER_TYPE_HEADERS";
export const LIST_TEST_TYPE_QUESTIONS = "LIST_TEST_TYPE_QUESTIONS";

export const LIST_ALL_SAMPLES = "LIST_ALL_SAMPLES";
export const LIST_ALL_SAMPLE_TEST = "LIST_ALL_SAMPLE_TEST";

export const VIEW_BY_SAMPLE_QUESTIONS = "VIEW_BY_SAMPLE_QUESTIONS";

export const VIEW_BY_QUESTIONS = "VIEW_BY_QUESTIONS";
export const QUESTIONS = "QUESTIONS";
export const CYCLES = "CYCLES";
export const ALL_SAMPLES = "ALL_SAMPLES";
export const DELETED_IMAGES = "DELETED_IMAGES";
export const IMAGES = "IMAGES";
export const ADDED_IMAGES = "ADDED_IMAGES";
export const GET_TEST_QUESTION_FILTERS = "GET_TEST_QUESTION_FILTERS";
export const ENABLE_SAVE_BUTTON = "ENABLE_SAVE_BUTTON";
export const TEST_CYCLE_TEXT = "TEST_CYCLE_TEXT";
export const GET_TEST_QUESTIONS = "GET_TEST_QUESTIONS";
export const NUMERIC_SAMPLEGROUP_HEADERRESULTS = "NUMERIC_SAMPLEGROUP_HEADERRESULTS";

export const SAMPLE_TEST_CYCLES = "SAMPLE_TEST_CYCLES";
export const TEST_REVIEW_DATA = "TEST_REVIEW_DATA";
export const TEST_CYCLE_SAMPLES = "TEST_CYCLE_SAMPLES";
export const TEST_CYCLES_TEXT = "TEST_CYCLES_TEXT";
export const OPEN_CYCLE_CONF_DIALOG = "OPEN_CYCLE_CONF_DIALOG";
export const MAPPED_SAMPLES = "MAPPED_SAMPLES";
export const REVIEW_SAMPLES = "REVIEW_SAMPLES";
export const REVIEW_SAMPLE_FILES = "REVIEW_SAMPLE_FILES";
export const REVIEW_SAMPLE_FILE_DIALOG_OPEN_CLOSE = "REVIEW_SAMPLE_FILE_DIALOG_OPEN_CLOSE";

export const ORGANIZATION_USERS = "ORGANIZATION_USERS";
export const VENDORS = "VENDORS";
export const ALL_VENDORS_LIST = "ALL_VENDORS_LIST";
export const SELECTED_VENDORS = "SELECTED_VENDORS";
export const MERGE_VENDOR_DIALOG_OPEN = "MERGE_VENDOR_DIALOG_OPEN";
export const IS_VENDOR_MAPPED = "IS_VENDOR_MAPPED";
export const SERIAL_INFO = "SERIALINFO";
export const PURCHASEORDERS = "PURCHASEORDERS";
export const SAMPLE_TYPES = "SAMPLE_TYPES";
export const SAMPLE_TYPES_BY_MAPPED_LABS = "SAMPLE_TYPES_BY_MAPPED_LABS";
export const SERIAL_BASED_SKU = "SERIAL_BASED_SKU";

export const USER_ACCOUNT_DETAIL = 'USER_ACCOUNT_DETAIL';
export const ROLES = "ROLES";
export const IS_TECH1_ROLE_ASSIGNED_TO_EXTERNAL_USER = "IS_TECH1_ROLE_ASSIGNED_TO_EXTERNAL_USER";
export const READ_PERMISSION = "READ_PERMISSION";
export const CREATE_PERMISSION = "CREATE_PERMISSION";
export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const CREATE = "CREATE";
export const READ = "READ";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const SELECTED_TEST_TYPE = "SELECTED_TEST_TYPE";
export const PREDEFINED_CYCLE = "PREDEFINED_CYCLE";
export const REPORT_FORMAT = "REPORT_FORMAT";
export const IS_OPEN_FILE_UPLOAD = "IS_OPEN_FILE_UPLOAD";
export const DELETED_FILE = "DELETED_FILE";
export const DROP_ZONE_FILE_DROPPED = "DROP_ZONE_FILE_DROPPED";
export const FILE_UPLOAD_SUBMIT = "FILE_UPLOAD_SUBMIT";
export const CLEAR_FILE_UPLOAD_SUBMIT = "CLEAR_FILE_UPLOAD_SUBMIT";
export const READY_TO_SAVE = "READY_TO_SAVE";
export const EQIUPMENT = "EQIUPMENT";


export const MFU_IS_OPEN_FILE_UPLOAD = "MFU_IS_OPEN_FILE_UPLOAD";
export const MFU_DELETED_FILE = "MFU_DELETED_FILE";
export const MFU_DROP_ZONE_FILE_DROPPED = "MFU_DROP_ZONE_FILE_DROPPED";
export const MFU_FILE_UPLOAD_SUBMIT = "MFU_FILE_UPLOAD_SUBMIT";
export const MFU_CLEAR_FILE_UPLOAD_SUBMIT = "MFU_CLEAR_FILE_UPLOAD_SUBMIT";
export const MFU_READY_TO_SAVE = "MFU_READY_TO_SAVE";

export const VENDOR_API_LOADER = "VENDOR_API_LOADER";
export const TEST_TYPE_IS_VISIBLE = "TEST_TYPE_IS_VISIBLE";
export const TEST_TYPE_SKU = "TEST_TYPE_SKU";
export const SKU_IS_VISIBLE = "SKU_IS_VISIBLE";
export const HEADERS = "HEADERS";
export const SHOW_SKU_LIST = "SHOW_SKU_LIST";
export const SAMPLE_TYPE_REPORT_DALOG_OPEN_CLOSE = "SAMPLE_TYPE_REPORT_DALOG_OPEN_CLOSE";
export const PO_NUMBERS = "PO_NUMBERS";
export const SAMPLE_GROUPS_NAMES = "SAMPLE_GROUPS_NAMES";
export const TestRequestFormType = {
  ListAllSubmittedArea: "SUBMITTED_AREA",
  ListAllReasonForTest: "REASON_FOR_TEST",
  ListAllComponentType: "COMPONENT_TYPE",
  ListAllTypeOfFinishedGood: "TYPE_OF_FINISHEDGOOD",
  ListAllLabRequested: "LAB_REQUESTED"
}
export const TEST_REQUEST_FORM_DETAILS = "TEST_REQUEST_FORM_DETAILS";
export const IS_TEST_REQUEST_COMPLETE = "IS_TEST_REQUEST_COMPLETE";
export const TEST_REQUESTS = "TEST_REQUESTS";
export const SELECTED_TEST_REQUESTS = "SELECTED_TEST_REQUESTS";
export const SELECTED_TEST_REQUESTS_PAGE_OPEN = "SELECTED_TEST_REQUESTS_PAGE_OPEN";
export const REFRESH_TEST_REQUESTS_TABLE = "REFRESH_TEST_REQUESTS_TABLE";
export const TEST_REQUEST_FORM_DIALOG_OPEN_CLOSE = "TEST_REQUEST_FORM_DIALOG_OPEN_CLOSE";
export const SAMPLE_GROUP_HISTORY_LOGS = "SAMPLE_GROUP_HISTORY_LOGS";
export const SAMPLE_GROUP_HISTORY_LOGS_MODEL = "SAMPLE_GROUP_HISTORY_LOGS_MODEL";
export const DASHBOARD_INFO = "DASHBOARD_INFO";
export const DASHBOARD_SKU = "DASHBOARD_SKU";
export const OPEN_QR_CODE_DIALOG = "OPEN_QR_CODE_DIALOG";
export const CLOSE_QR_CODE_DIALOG = "CLOSE_QR_CODE_DIALOG";
export const OPEN_QR_CODE_SCANNER_DIALOG = "OPEN_QR_CODE_SCANNER_DIALOG";
export const CLOSE_QR_CODE_SCANNER_DIALOG = "CLOSE_QR_CODE_SCANNER_DIALOG";
export const SAMPLE_GROUP_STATUS = "SAMPLE_GROUP_STATUS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const FILTERS_LABS = "FILTERS_LABS";
export const FILTERS_VENDORS = "FILTERS_VENDORS";
export const FILTERS_SKUS = "FILTERS_SKUS";
export const FILTERS_SKUS_POPUP = "FILTERS_SKUS_POPUP";
export const SEARCH_SKUS = "SEARCH_SKUS";
export const FILTERS_LABS_POPUP = "FILTERS_LABS_POPUP";
export const FILTERS_VENDORS_POPUP = "FILTERS_VENDORS_POPUP";
export const FILTERS_REQUEST_BY_POPUP = "FILTERS_REQUEST_BY_POPUP";
export const IS_STATUS_UPDATE = "IS_STATUS_UPDATE";
export const OPEN_BARCODE_SCANNER_DIALOG = "OPEN_BARCODE_SCANNER_DIALOG";
export const CLOSE_BARCODE_SCANNER_DIALOG = "CLOSE_BARCODE_SCANNER_DIALOG";
export const LAB_EQUIPMENT = "LAB_EQUIPMENT";
export const IS_LAB_EQUIPMENT_EXISTS = "IS_LAB_EQUIPMENT_EXISTS";
export const LABS = "LABS";
export const EQUIPMENTS = "EQUIPMENTS";
export const SAMPLE_GROUP_LAB_EQUIPMENTS = "SAMPLE_GROUP_LAB_EQUIPMENTS";
export const SAMPLE_GROUP_SUMMARY_FILTER = "SAMPLE_GROUP_SUMMARY_FILTER";
export const TEST_REQUEST_FORM_SUMMARY_FILTER = "TEST_REQUEST_FORM_SUMMARY_FILTER";
export const REVIEW_LANDING_FILTER = "REVIEW_LANDING_FILTER";
export const QUESTION_SUMMARY_FILTER = "QUESTION_SUMMARY_FILTER";

export const EQUIPMENT_MODAL_OPEN = "EQUIPMENT_MODAL_OPEN";
export const HEADER_FIELD_MODAL_OPEN = "HEADER_FIELD_MODAL_OPEN";
export const LAB_MODEL_OPEN = "LAB_MODEL_OPEN";
export const LAB_EQUIPMENT_MODAL_OPEN = "LAB_EQUIPMENT_MODAL_OPEN";
export const QUESTION_MODAL_OPEN = "QUESTION_MODAL_OPEN";
export const SAMPLE_TYPE_MODAL_OPEN = "SAMPLE_TYPE_MODAL_OPEN";
export const VENDOR_MODAL_OPEN = "VENDOR_MODAL_OPEN";
export const TEST_TYPE_MODAL_OPEN = "TEST_TYPE_MODAL_OPEN";
export const SG_TEST_CYCLE_MODAL_OPEN = "SG_TEST_CYCLE_MODAL_OPEN";
export const AUTO_TEST_REQUEST_MODAL_OPEN = "AUTO_TEST_REQUEST_MODAL_OPEN"
export const SAMPLE_TYPE_LAB_OPEN_CLOSE = "SAMPLE_TYPE_LAB_OPEN_CLOSE";
export const SAMPLE_TYPE_LABS = "SAMPLE_TYPE_LABS";
export const APPROVED_SKUS = "APPROVED_SKUS";
export const APPROVED_SKU_MODAL_OPEN = "APPROVED_SKU_MODAL_OPEN"
export const SELECTED_SAMPLE_TYPE = "SELECTED_SAMPLE_TYPE";
export const SAMPLE_TYPE_BY_HEADER = "SAMPLE_TYPE_BY_HEADER";
export const HEADER_SAMPLE_TYPE_MODAL_OPEN = "HEADER_SAMPLE_TYPE_MODAL_OPEN";
export const SAMPLE_TYPE_SKU_OPEN_CLOSE = "SAMPLE_TYPE_SKU_OPEN_CLOSE";
export const SAMPLE_TYPE_SKU = "SAMPLE_TYPE_SKU";
export const LAB_SAMPLE_TYPE_OPEN_CLOSE = "LAB_SAMPLE_TYPE_OPEN_CLOSE";
export const LAB_USER_OPEN_CLOSE = "LAB_USER_OPEN_CLOSE";
export const LAB_SAMPLE_TYPES = "LAB_SAMPLE_TYPES";
export const TEST_LOCK_DETAILS = "TEST_LOCK_DETAILS";
export const USER_LABS = "USER_LABS";
export const EXTERNAL_USER_TECH_ROLE_LAB_IDS = "EXTERNAL_USER_TECH_ROLE_LAB_IDS";
export const CUSTOM_ROLE_JSON = "CUSTOM_ROLE_JSON";
export const LOGGED_USER_INFO = "LOGGED_USER_INFO";
export const IS_UPLOAD_LOADER = "IS_UPLOAD_LOADER";