import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ADMIN_EMAIL_ID } from "../utilities/apiConfig";
import { Box } from "@material-ui/core";
import { ACCESS_DENIED_MESSAGE, ACCESS_DENIED } from "../rbac/constants/roleConstant";
import { CONTACT_ADMIN } from "../utilities/alertMessage";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        paddingTop: '7%'
    }
}));

const AccessDenied = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions.length > 0 && <Box >
                    <Typography align="center" color="textSecondary" variant="h4" display="block">
                        {ACCESS_DENIED}
                    </Typography>
                    <Typography align="center" color="textSecondary" display="block">
                        <a href={"mailto:" + ADMIN_EMAIL_ID + "?subject=Request for Need Access - Reg &body=Hi Team , I need access for this page"}>
                            {CONTACT_ADMIN}
                        </a>
                    </Typography>
                </Box>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userInfo: state.rbacReducer.userInfo,
    };
};

export default connect(mapStateToProps, {
})(AccessDenied);
