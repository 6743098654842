import { PublicClientApplication } from "@azure/msal-browser";
import * as authConfig from "./authConfig";
import { UrlHandler } from "../utilities/urlHandler";

const config = {
  auth: {
    clientId: authConfig.ApplicationId,
    authority: authConfig.Authority,
    navigateToLoginRequestUrl: false,
    redirectUri: new UrlHandler().getUrlWithoutQueryParams(window.location.href)
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const AuthenticationParameters = {
  scopes: ["openid", "profile", "offline_access", "User.Read"],
  state: window.location.href
};

export const AuthProvider = new PublicClientApplication(
  config
);
