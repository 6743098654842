import store from '../../../store/store';
import { ERROR_ALERT_MESSAGE, SUCCESS_ALERT_MESSAGE, CLEAR_ALERT_MESSAGE, WARNING_ALERT_MESSAGE } from '../../../constant/actionType';

export const DispatchAlertClear = () => {
    store.dispatch({
        type: CLEAR_ALERT_MESSAGE,
        data: {
            isWarning: false,
            isSuccess: false,
            isError: false,
            message: ''
        }
    });
}

export const DispatchAlertSuccess = (message) => {
    store.dispatch({
        type: SUCCESS_ALERT_MESSAGE,
        data: {
            isSuccess: true,
            isError: false,
            isWarning: false,
            message: message
        }
    });
}


export const DispatchAlertWarning = (message) => {
   
    store.dispatch({
        type: WARNING_ALERT_MESSAGE,
        data: {
            isWarning: true,
            isError: false,
            isSuccess: false,
            message: message
        }
    });
}

export const DispatchAlertError = (message) => {
    store.dispatch({
        type: ERROR_ALERT_MESSAGE,
        data: {
            isSuccess: false,
            isError: true,
            isWarning: false,
            message: message
        }
    });
}