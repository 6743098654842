import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as QRReaderIcon } from '../icons/qr_code-24px.svg';

const useStyles = makeStyles(() => ({
  root: () => ({
    color: '#2196f3',
  }),
  menuColor: () => ({
    color: '#fff',
  }),
  disabled: () => ({
    color: '#727272',
  })
}));

export default function QRCodeIcon(props) {

  const classes = useStyles(props);

  return (

    <SvgIcon component={QRReaderIcon} className={props.disabled ? classes.disabled : (props.callFrom === "menu" ? classes.menuColor : classes.root)} />
  );
}
