import { LAB_USERS } from '../../../../constant/actionType';

const initialState = {
    labUsers: [],
};

function labUserReducers(state = initialState, action) {
    switch (action.type) {
        case LAB_USERS:
            return {
                ...state, labUsers: action.labUsers
            };

        default:
            return state;
    }
}

export default labUserReducers;
