
import {
    OPEN_PROGRESS_DIALOG, CLOSE_PROGRESS_DIALOG
} from '../../../constant/actionType';

const initialState = {
    isOpenProgressDialog: false,
    dialogOpenCount: 0
};

function ProgressDialogReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_PROGRESS_DIALOG:
            return {
                ...state,
                isOpenProgressDialog: action.data.isOpen,
                dialogOpenCount: state.dialogOpenCount + 1
            };

        case CLOSE_PROGRESS_DIALOG:
            return {
                ...state,
                isOpenProgressDialog: (state.dialogOpenCount - 1) > 0,
                dialogOpenCount: state.dialogOpenCount > 0 ? state.dialogOpenCount - 1 : 0
            };

        default:
            return state;
    }
}

export default ProgressDialogReducer;