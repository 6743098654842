var appSettings = require("./appSettings.json");

export const DEFAULT_ZERO = 0;
export const ERROR_COUNT = 0;
export const DATA_TYPE_TEXT = 1;
export const DATA_TYPE_NUMBER = 2;
export const DATA_TYPE_DATE = 3;
export const DATA_TYPE_BOOLEAN = 4;
export const DATA_TYPE_STORAGE_FLAG = 0;
export const BASE_PARAMETER = 8;
export const PAGE_LIST = {
  SampleGroup: 1,
  SampleLanding: 2,
  SampleTestLanding: 3,
  SampleTestQuestionView: 4,
  ReviewLanding: 5,
  ReviewSampleLanding: 6,
  ReviewTestResults: 7,
  ReviewImage: 8,
  TestRequestLanding: 9,
  ReviewLanding_ReviewCompleted: 10,
};

export const REQUIREMENT_LIST = {
  StatusChangeButtonGroup: 1,
  StatusFilter: 2,
  ResultButtonCollection: 3,
  ResultFilter: 4,
};

export const GLS_FILE_TYPES = {
  General: 1,
  SpecificationDocument: 2,
  TestProcedureDocument: 3,
};

export const MONTH_DATE_YEAR = "MM-dd-yyyy";
export const MONTH_DATE_YEAR_FORMAT = "MM-DD-YYYY";
export const MONTH_DATE_YEAR_TIME_FORMAT = "MM-DD-YYYY, h:mm a";

export const MONTH_DATE_YEAR_VALIDATION = "MM-d-YYYY";
export const YEAR_MONTH_DATE = "YYYY-MM-DD";

export const INPUT_TYPE_TEXT_BOX = 1;
export const INPUT_TYPE_DROP_DOWN = 2;
export const INPUT_TYPE_DATE = 3;
export const INPUT_TYPE_RADIO_BUTTON = 4;
export const INPUT_TYPE_ATTACHMENT = 5;
export const CUSTOM_UNIT_TYPE_VALUE = -1;

export const DECIMAL_CONSTANTS = {
  MIN_VALUE: 1,
  MAX_VALUE: 5,
};
export const PDF_FORMATS = {
  LIST_FORMAT: 1,
  TABLE_FORMAT: 2,
  THUMBNAIL: 3,
};

export const ALL_IMAGE_FORMATS = [
  "jpg",
  "jpeg",
  "png",
  "tiff",
  "gif",
  "jfif",
  "tif",
];
export const AGGREGATE_CHOICES = {
  Average: 1,
  Min: 2,
  Max: 3,
  Range: 4,
  StandardDeviation: 5,
  Sum: 6,
};

export const AGGREGATE_CHOICE_CONSTANTS = [
  { id: 1, name: "Average" },
  { id: 2, name: "Min" },
  { id: 3, name: "Max" },
  { id: 4, name: "Range" },
  { id: 5, name: "StandardDeviation" },
  { id: 6, name: "Sum" },
];

export const SG_STATUS_TEST_REQUEST_UNASSIGNED = 8; //New Request - Unassigned
export const STATUS_NEW = 1;
export const STATUS_INPROGRESS = 2;
export const STATUS_COMPLETE = 3;
export const PENDING_APPROVAL = 5;
export const REVIEW_COMPLETED = 6;
export const REVIEW_AUTO_COMPLETED = 9;
export const SG_STATUS_INPROGRESS_NEEDS_FOLLOWUP = 7;
export const RESULT_APPROVED = 5;
export const RESULT_WAIVED = 8;
export const NEARING_EXPIRY = 10;
export const EXPIRED = 11;

export const RADIX_PARAMETER = 10;

export const VIEW_BY_SAMPLE = 1;
export const VIEW_BY_CYCLE = 2;

export const NO_RESULT_VALUE = 1;
export const NO_RESULTS_OPTION = 1;
export const PASS_OPTION = 2;
export const FAIL_OPTION = 3;

export const LEFT_ALIGNMENT = 2;
export const DEFAULT_TOGGLE = 1;

export const SAMPLE_GROUP_SIZE_MIN = parseInt(
  appSettings.sample_group_min_size
);
export const SAMPLE_GROUP_SIZE_MAX = parseInt(
  appSettings.sample_group_max_size
);

export const PO_MAXLENGTH = parseInt(appSettings.po_maxlength);

export const IMAGE_TAB = 0;
export const FILES_TAB = 1;
export const IMAGE_FORMATS = ["jpg", "jpeg", "png"];
export const VIDEO_FORMATS = ["mp4", "webm", "ogg"];

export const PADDING_DIGITS = 6;
export const NUMERIC_SEPERATOR = "_";
export const PADDING_CHARACTER = "0";
export const NEED_POSITION_CHANGE = 1;
export const N0_POSITION_CHANGE = -1;
export const MAX_DATE_RANGE = 30;
export const GRID_SIZE = {
  DEFAULT_GRID_COUNT: 20,
  GRID_DROPDOWN_ONE: 20,
  GRID_DROPDOWN_TWO: 30,
  GRID_DROPDOWN_THREE: 40,
  GRID_DROPDOWN_FOUR: 50,
};

export const DEFAULT_PAGE_NO = 0;

export const MERGE_VENDOR_COUNT = 50;

export const LIST_DIMENSION = {
  LIST_HIEGHT: 600,
  ROW_HIEGHT: 50,
  ROW_WIDTH: 1000,
  OVER_SCAN: 10,
};

export const DROP_ZONE_CONSTANTS = {
  MAX_FILE_SIZE: parseInt(appSettings.max_file_size),
  MAX_BULK_UPLOAD_LIMIT: parseInt(appSettings.max_bulk_upload_limit),
  FILE_LIMIT: parseInt(appSettings.file_limit),
};

export const FILE_UPLOAD = {
  MAX_FILES_COUNT: parseInt(appSettings.max_files_count),
  FOLDER_NAME: "test_request_form",
  PNG_FILE_EXTENSION: ".png",
  PNG_FILE_TYPE: "image/png",
};
export const MAX_CYCLE_SIZE = parseInt(appSettings.max_cycle_size);

export const AUTO_SEARCH_TEXT_LENGTH = parseInt(
  appSettings.auto_search_text_length
);

export const GRID_FILTER_DEBOUNCE_INTERVAL = parseInt(
  appSettings.grid_filter_debounce_interval
);
export const TEXTFIELD_DEBOUNCE_INTERVAL = 500;

export const DEFAULT_IMAGE_URL = appSettings.default_image_url;
export const DEFAULT_IMAGE_NAME = appSettings.default_image_name;

export const SET_UP_MODULE_HEADER_FREEZE_HEIGHT = window.innerHeight - 213;
export const LAB_HEADER_FREEZE_HEIGHT = window.innerHeight - 195;

export const TEST_REVIEW_MODULE_SG_HEADER_FREEZE_HEIGHT =
  window.innerHeight - 215;
export const TEST_REVIEW_MODULE_SL_HEADER_FREEZE_HEIGHT =
  window.innerHeight - 213;
export const TEST_MODULE_TL_HEADER_FREEZE_HEIGHT = window.innerHeight - 205;

export const VIEW_TYPE = {
  WITH_OUT_CYCLE: 1,
  VIEW_BY_SAMPLE: 2,
  VIEW_BY_CYCLE: 3,
};
export const HEADER_STYLE = {
  position: "sticky",
  top: -11,
  fontWeight: "bold",
  padding: "10px 10px 10px 10px",
};

export const EQUIPMENT_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 20,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};
export const SETUP_MODULE_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 20,
  padding: "3px 5px 5px",
  height: 40,
  zIndex: 2,
};
export const SETUP_MODULE_SAMPLE_TYPE_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 60,
  padding: "3px 5px 5px",
  height: 40,
  zIndex: 2,
};

export const SETUP_MODULE_SKU_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 60,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};

export const HEADER_FIELD_MODULE_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 20,
  padding: "3px 5px 5px",
  height: 40,
  zIndex: 2,
};

export const TEST_TYPE_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 45,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};
export const LAB_EQUIPMENT_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 40,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};
export const QUESTION_CONSTRAINT_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 85,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};

export const FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 30,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};

export const REVIEW_MODULE_FILTER_CELL_STYLE = {
  backgroundColor: "white",
  position: "sticky",
  top: 60,
  padding: "3px 5px 5px",
  height: 30,
  zIndex: 2,
};

export const SELECT_ALL_CHECKBOX_STATUS = {
  None: 0,
  All: 1,
  Indeterminate: 2,
};
export const DEFAULT_DECIMAL_DIGIT = parseInt(
  appSettings.default_decimal_digit
);
export const DEFAULT_INTEGER_DIGIT = parseInt(
  appSettings.default_integer_digit
);
export const ROW_ACTIVE = "#EEE";
export const ROW_INACTIVE = "#FFF";
export const HEADER_FORM_FIELD = {
  minValue: "minValue",
  maxValue: "maxValue",
  minDate: "minDate",
  maxDate: "maxDate",
  predefinedOptions: "predefinedOptions",
  isPredefinedDataSet: "isPredefinedDataSet",
  headerOptions: "headerOptions",
  headerField: "headerField",
  description: "description",
  dataType: "dataType",
  inputType: "inputType",
  unitType: "unitType",
  customUnitType: "customUnitType",
  decimalDigits: "decimalDigits",
  isActive: "isActive",
  predefinedDescription: "predefinedDescription",
};

export const NO_DEIMAL_DIGIT = -1;

export const TEST_TYPE_FORM_FIELD = {
  inActive: "in_active",
  isCycle: "is_cycle",
  testType: "test_type",
  cycleOptions: "cycle_options",
  isCyclePredefined: "is_predefined",
  isResultAutoCalculated: "isResultAutoCalculated",
  isCycleLevelGroupingApplicable: "isCycleLevelGroupingApplicable",
  isShowResultInReport: "isShowResultInReport",
  equipmentName: "equipmentName",
  reportFormatId: "reportFormatId",
  isAggregateApplicable: "isAggregateApplicable",
  isEstimatedTestDuration: "isEstimatedTestDuration",
  testDurationDays: "testDurationDays",
  testDurationHrs: "testDurationHrs",
  testDurationMin: "testDurationMin",
  testDescription: "testDescription",
  isAllowedOnlyAttachmentQuestions: "is_allow_attachment_questions",
  aggregationChoices: "aggregationChoices",
  isExpiryOpted: "isExpiryOpted",
  expiryUnit: "expiryUnit",
  expiryPeriod: "expiryPeriod",
  isSetReminder: "isSetReminder",
  expiryNotifications: "expiryNotifications",
  expiryNotificationNotes: "expiryNotificationNotes",
};

export const EQUIPMENT_FORM_FIELD = {
  equipmentName: "equipmentName",
  modelNumber: "modelNumber",
  testObject: "testObject",
  isCalibrationRequired: "isCalibrationRequired",
  frequency: "frequency",
  isActive: "isActive",
};
export const SAMPLE_TYPE_FORM_FIELD = {
  inActive: "in_active",
  sampleType: "sample_type",
  isMultipleSku: "is_muliple_sku",
  isFreeFormText: "is_freeform_vendor_entry",
  isSGMandatoryReview: "is_sg_mandatory_review",
  allowTestRequestCreation: "allow_test_request_creation",
  organizationUser: "organization_user",
  isComparisonRequired: "enable_side_by_side_comparison",
};

export const VENDOR_FORM_FIELD = {
  vendorNo: "vendor_no",
  vendorName: "vendor_Name",
  inActive: "in_active",
  vendorNameMaxLength: 100,
  vendorNoMaxLength: 50,
};

export const SKU_FORM_FIELD = {
  isActive: "isActive",
  sampleType: "sampleType",
  description: "description",
  skuName: "skuName",
};

export const LAB_FORM_FIELD = {
  inActive: "in_active",
  siteName: "site_name",
  lab: "lab",
  location: "location",
  address1: "address1",
  address2: "address2",
  website: "website",
  lastName: "lastName",
  firstName: "firstName",
  title: "title",
  emailAddress: "emailAddress",
  phone: "phone",
  fax: "fax",
  accreditationBodyCertification: "accreditationBodyCertification",
  registeredBody: "registeredBody",
  approvedTestingScope: "approvedTestingScope",
  labMasterId: "labMasterId",
};

export const AUTO_TEST_REQUEST_MAPPING_FORM_FIELD = {
  isActive: "isActive",
  sampleTypeName: "sampleTypeName",
  sampleTypeId: "sampleTypeId",
  labName: "labName",
  labId: "labId",
  commodityId: "commodityId",
  sku: "sku",
  onBehalfOfUsers: "onBehalfOfUsers",
  commodityIds: "commodityIds",
};

export const QUESTION_CONSTRAINT_FORM_FIELD = {
  question: "question",
  sku: "sku",
  sampleType: "sampleType",
  maxAllowedSampleFailures: "maxAllowedSampleFailures",
  minAllowedSampleSuccess: "minAllowedSampleSuccess",
  testType: "testType",
  minValue: "minValue",
  maxValue: "maxValue",
  averageMinValue: "averageMinValue",
  averageMaxValue: "averageMaxValue",
  nominalValue: "nominalValue",
  decimalDigits: "decimalDigits",
  isColorCodingApplicable: "is_color_coding_applicable",
};
export const SAMPLE_GROUP_INPUT_FIELD = {
  DropDown: "DropDown",
  Predefined: "Predefined",
  RadioButton: "RadioButton",
};

export const QUESTION_FORM_FIELD = {
  unitId: "unitId",
  questionText: "questionText",
  description: "description",
  dateTypeId: "dateTypeId",
  inputTypeId: "inputTypeId",
  isRequired: "isRequired",
  isActive: "isActive",
  isConstraintApplicable: "isConstraintApplicable",
  isCalculated: "isCalculated",
  questionOptions: "questionOptions",
  defaultValue: "defaultValue",
  infoUrl: "infoUrl",
};

export const FORMULA_SEPERATOR = {
  Question: "[Question|",
  Constant: "[Constant|",
  Header: "[Header|",
  Math: "[Math|",
};
export const EXPORT_CSV_FORMAT = ".csv";
export const SAMPLE_TYPE_REPORT_TYPES = {
  sampleGroup: 0,
  pendingReview: 1,
  approvalCompleted: 2,
};

export const REPORT_FILE_NAMES = {
  sampleGroup: "Samplegroup.xlsx",
};

export const CSV_FILE_CONTENT_TYPE = "text/csv";
export const PDF_FILE_CONTENT_TYPE = "application/pdf";
export const PDF_FILE_EXTENSION = ".pdf";
export const EXCEL_FILE_CONTENT_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const REVIEW_EXPORT_EXCEL_NAME = "SampleGroups.xlsx";
export const DATA_OBJECT_UNDEFINED = "undefined";
export const SAMPLEGROUP_QUERY = "345";

export const COMMA_SEPERATOR = ",";
export const PIPE_SEPERATOR = "|";
export const VENDOR_EXPORT_EXCEL_NAME = "Vendors.xlsx";

export const BULK_UPLOAD_TEMPLATE_PATH = "BulkUploadTemplates/";
export const QUESTION_BULK_UPLOAD_FILE_NAME = "Questions.xlsx";
export const QUESTION_CONSTRAINT_BULK_UPLOAD_FILE_NAME =
  "QuestionConstraints.xlsx";

export const STATUS_COLOR_CODE = {
  reviewCompletedColor: "#263238",
  inProgressColor: "#fb8c00",
  inProgressNeedsFollowupColor: "#880e4f",
  unassignedColor: "#90caf9",
  skippedColor: "#ffd600",
  pendingReviewColor: "#ff1744",
  completedColor: "#43a047",
  newColor: "#3f51b5",
};
export const RECALCULATION_STATUS = {
  completed: "Completed",
  initiated: "Initiated",
  inProgress: "In Progress",
  failed: "Failed",
};

export const RECALCULATION = "Recalculation";
export const NOTIFICATION_DURATION = 15;
export const SERIAL_NO_BASED_SKU = "serialNoBasedSku";

export const QUESTION_TABLE_WIDTH_HEIGHT = {
  gridContainerQuestionWidth: 250,
  gridContainerQuestionHeight: 75,
  gridContainerQuestionWidthLimit: 255,
  gridContainerQuestionHeightTopLimit: 250,
  gridContainerQuestionHeightBottomLimit: 500,
};

export const REVIEW_LANDING_RESULTS = {
  pending: ["Pass", "Fail", "None"],
  completed: ["Approved", "Rejected"],
};

export const FILTER_FIELD_SEARCH_TEXT = {
  skuSearchTextLength: parseInt(
    appSettings.grid_autocomplete_filter_with_na_option_search_text_length
  ),
  labSearchTextLength: parseInt(
    appSettings.grid_autocomplete_filter_with_na_option_search_text_length
  ),
  vendorSearchTextLength: parseInt(
    appSettings.grid_autocomplete_filter_with_na_option_search_text_length
  ),
  requestedBySearchTextLength: parseInt(appSettings.auto_search_text_length),
};

export const SAMPLES_FAILURE = {
  MIN_COUNT: parseInt(appSettings.min_samples_failure_count),
  MAX_COUNT: parseInt(appSettings.max_samples_failure_count),
};

export const MAX_QUESTION_FAILURE = {
  MAX_COUNT: parseInt(appSettings.max_allowed_Question_failures),
};

export const GROUP_BY_SAMPLES_CYCLES = {
  GroupByCycles: 0,
  GroupBySamples: 1,
};
export const STATUS_FILTER = {
  All: 0,
  PendingReview: 1,
  Completed: 2,
};

export const LAB_EQUIPMENT_FORM_FIELD = {
  labEquipmentId: "labEquipmentId",
  labId: "labId",
  equipmentId: "equipmentId",
  serialNo: "serialNo",
  equipmentNumber: "equipmentNumber",
  quantity: "quantity",
  lastCalibrationDate: "lastCalibrationDate",
  calibrationDueDate: "calibrationDueDate",
  files: "files",
  workCenter: "workCenter",
  assetTag: "assetTag",
  inActive: "inActive",
};
export const DATA_TYPES = {
  Text: "Text",
  Number: "Number",
  Date: "Date",
  Boolean: "Boolean",
};
export const INPUT_TYPES = {
  Text: "Text Field",
  DropDown: "Drop Down",
  Date: "Date",
  RadioButton: "Radio Button",
};
export const MATH_FIELD_VALUES = {
  min: "MIN",
  max: "MAX",
  avg: "AVG",
  sqrt: "SQRT",
  stdDev: "StdDev",
};

export const MATH_FUNCTION_IDENTIFIER = {
  min: "[Math|MIN]",
  max: "[Math|MAX]",
  avg: "[Math|AVG]",
  sqrt: "[Math|SQRT]",
  stdDev: "[Math|StdDev]",
};
export const MATH_FUNCTION = {
  min: "Math.min",
  max: "Math.max",
  avg: "avgArgsToArray",
  sqrt: "Math.sqrt",
  stdDev: "stdDevArgsToArray",
};

export const BULK_UPLOAD_FILE_FORMATS = {
  xls: ".xls",
  xlsx: ".xlsx",
  spreadSheet:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const NUMBER_DATA_TYPE = "number";

export const NOTES_MAX_LENGTH = 500;

export const MaterialTableScrollToTop = () => {
  document.querySelectorAll("[id='qtil-material-table-grid']")[0] &&
    document
      .querySelectorAll("[id='qtil-material-table-grid']")[0]
      .querySelectorAll("[style*='overflow-y:']")[0] &&
    document
      .querySelectorAll("[id='qtil-material-table-grid']")[0]
      .querySelectorAll("[style*='overflow-y:']")[0]
      .scrollTo(0, 0);
};

export const CheckDeviceType = (small, medium, large, extraLarge) => {
  const mediaQuerySmall = window.matchMedia("(max-width: 600px)");
  const mediaQueryMedium = window.matchMedia(
    "(min-width: 601px) and (max-width: 900px)"
  );
  const mediaQueryLarge = window.matchMedia(
    "(min-width: 901px) and (max-width: 1200px)"
  );
  const mediaQueryExtraLarge = window.matchMedia("(min-width:1200px)");
  if (mediaQuerySmall.matches) {
    return small;
  } else if (mediaQueryMedium.matches) {
    return medium;
  } else if (mediaQueryLarge.matches) {
    return large;
  } else if (mediaQueryExtraLarge.matches) {
    return extraLarge;
  }
};

export const VALUE_PERIODS = {
  Day: "day",
  Week: "week",
  Month: "month",
  Year: "year",
};

export const convertDurationToMonths = (unit, period) => {
  const unitParsed = parseInt(unit, 10);
  switch (period) {
    case "Year":
      return unitParsed * 12;
    case "Quarter":
      return unitParsed * 3;
    case "Month":
      return unitParsed;
    case "Week":
      return (unitParsed / 4.33).toFixed(2);
    case "Day":
      return (unitParsed / 30.42).toFixed(2);
    case "Hour":
      return (unitParsed / (24 * 30.42)).toFixed(2);
    default:
      return 0;
  }
};
