import store from "../../../store/store";
import {
  BULK_UPLOAD_FILE,
  BULK_UPLOAD_MODAL_OPEN 
} from '../../../constant/actionType'; 

export const DispatchBulkUploadModalOpen = bulkUploadModalOpen => {
  store.dispatch({
    type: BULK_UPLOAD_MODAL_OPEN,
    bulkUploadModalOpen: bulkUploadModalOpen
  });
};
 
export const DispatchBulkUploadFile = bulkUploadFile => {
  store.dispatch({
    type: BULK_UPLOAD_FILE,
    bulkUploadFile: bulkUploadFile
  });
};
 
       