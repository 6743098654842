import { GLS_API_URL, SERIAL_API_URL } from "../../../shared-component/utilities/apiConfig";

const sampleGroupFetchAPI = "/get-sample-group-by-id";
const sampleGroupAPIbase = "sample-group";
const sampleGroupPostAPI = "/create";
const vendorGetAPI = "sample-type-vendor/fetch-sample-type-vendors";
const testTypeLabEquipment = "test-type-lab-equipment";
const sampleTypeAPI = "sample-type/get-all-orderby-name";
const sampleTypeByMappedLabAPI = "sample-type/get-by-user-mapped-labs";
const serialAPI = "serial";
const sampleGroupQRCodeDetailsAPI = "/get-sample-group-qr-code-details";
const PurposeOfTestAPI = "purpose-of-test";
const vendorValidateAPI="vendor/validate";

export const GetSampleGroupAPI = (sampleTypeId, sampleGroupId) => {
  return (
    GLS_API_URL +
    sampleGroupAPIbase +
    sampleGroupFetchAPI +
    `?sampleTypeId=${sampleTypeId}` +
    `&sampleGroupId=${sampleGroupId ? sampleGroupId : 0}`
  );
};

export const PostSampleGroupAPI = () => {
  return GLS_API_URL + sampleGroupAPIbase + sampleGroupPostAPI;
};

export const PutSampleGroupAPI = () => {
  return GLS_API_URL + sampleGroupAPIbase;
};

export const GetSampleTypeVendorFetchAPI = (pageNumber, searchText, vendorNumber, vendorName, sampleTypeId, vendorId) => {
  return GLS_API_URL + vendorGetAPI + "?pageNumber=" + pageNumber + (searchText && "&searchText=" + encodeURIComponent(searchText)) + (vendorNumber && "&vendorNumber=" + vendorNumber.trim()) + (vendorName && "&vendorName=" + encodeURIComponent(vendorName.trim())) + `&sampleTypeId=${sampleTypeId}` + (vendorId ? '&vendorId=' + vendorId : '');
};

export const GetTestTypeLabEquipmentAPI = (labId, sampleTypeId, sampleGroupId) => {
  return GLS_API_URL + testTypeLabEquipment + "?labId=" + labId + "&sampleTypeId=" + sampleTypeId + "&sampleGroupId=" + sampleGroupId;
};

export const GetSampleTypeAPI = (isActiveOnly) => {
  return GLS_API_URL + sampleTypeAPI + '?isActiveOnly=' + isActiveOnly;
};

export const GetPurposeOfTestAPI = () => {
  return GLS_API_URL + PurposeOfTestAPI;
};

export const GetSerialAPI = () => {
  return SERIAL_API_URL + serialAPI;
};

export const GetSampleGroupQRCodeDetailsAPI = (sampleGroupId) => {
  return GLS_API_URL + sampleGroupAPIbase + sampleGroupQRCodeDetailsAPI + "?sampleGroupId=" + sampleGroupId.toString();
};

export const GetSampleTypeByMappedLabAPI = (userId, isTechRoleOnly, isActiveOnly) => {
  return GLS_API_URL + sampleTypeByMappedLabAPI + '?userId=' + userId.toString() + '&isTechRoleOnly=' + isTechRoleOnly.toString() + '&isActiveOnly=' + isActiveOnly.toString();
};

export const VendorValidatePostAPI = () => {
  return GLS_API_URL + vendorValidateAPI;
};
