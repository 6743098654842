export class UrlHandler {

    getUrlWithoutQueryParams(url) {
        const urlParts = url.split("?");

        if (urlParts.length > 0) {
            return urlParts[0];
        }

        return url;
    }
}