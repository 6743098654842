import {
    SAMPLE_TYPE_LAB_OPEN_CLOSE, SAMPLE_TYPE_LABS
} from "../../../../constant/actionType";

const initialState = {
    isLabMappingModalOpen: false,
    selectedSampleType: null,
    mappedLabs: []
};

function sampleTypeLabReducer(state = initialState, action) {

    switch (action.type) {
        case SAMPLE_TYPE_LAB_OPEN_CLOSE:
            return {
                ...state,
                selectedSampleType: action.data.sampleType,
                isLabMappingModalOpen: action.data.isOpen
            };

        case SAMPLE_TYPE_LABS:
            return {
                ...state,
                mappedLabs: action.data
            };

        default:
            return state;
    }
}

export default sampleTypeLabReducer;
