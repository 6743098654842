import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MobilelView from "./mobilelView";
import GeneralView from "./generalView";
import {
  SAMPLE_TYPE,
  TEST_TYPE,
  QUESTION,
  SKU,
  LABS,
  EQUIPMENT,
  QUESTION_CONSTRAINT,
  HEADER_FIELD,
  LAB_EQUIPMENT,
  AUTO_TEST_REQUEST_MAPPING,
  VENDOR, SAMPLEGROUP_LAB_EQUIPMENTS, SAMPLE_GROUP_SUMMARY, TEST_REQUEST_SUMMARY
} from "../../constant/appStringConstant";
import { Features } from "../rbac/constants/roleConstant";
import { DispatchOpenProgress, DispatchCloseProgress } from "../progressDialog/actions/progressDialogAction";

const HeaderMenu = (props) => {

  const [isMobileView, setIsMobileView] = useState(true);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [setupModuleMenus, setSetupModuleMenus] = useState([]);
  const [testCreationMenus, setTestCreationMenus] = useState([]);

  useEffect(() => {
    DispatchOpenProgress();

    const setupModuleMenuList = [
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_LAB),
        name: LABS,
        to: "/setup/lab"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_VENDOR),
        name: VENDOR,
        to: "/setup/Vendor"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_EQIUPMENT),
        to: "/setup/equipment",
        name: EQUIPMENT
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_LAB_EQUIPMENT_MAPPING),
        to: "/setup/lab-equipment-mapping",
        name: LAB_EQUIPMENT
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_SAMPLE_TYPE),
        name: SAMPLE_TYPE,
        to: "/setup/sample-type"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_SKU),
        name: SKU,
        to: "/setup/sku"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_HEADER_FIELD),
        name: HEADER_FIELD,
        to: "/setup/header-field"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_TEST_TYPE),
        name: TEST_TYPE,
        to: "/setup/test-type"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_QUESTION),
        name: QUESTION,
        to: "/setup/question"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_QUESTION_CONSTRAINT),
        to: "/setup/question-constraint",
        name: QUESTION_CONSTRAINT
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.AUTO_TEST_REQUEST_MAPPING),
        name: AUTO_TEST_REQUEST_MAPPING,
        to: "/setup/auto-test-creation-mapping"
      }
    ];

    const testCreationMenuList = [
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.SAMPLE_GROUP),
        name: SAMPLE_GROUP_SUMMARY,
        to: "/test-creation/sample-group"
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.GLS_TEST_REQUEST_SUMMARY),
        to: "/test-creation/test-request-summary",
        name: TEST_REQUEST_SUMMARY
      },
      {
        isVisible: props.userInfo && props.userInfo.Roles && props.userInfo.Roles.featurePermissions?.find(element => element.featureName === Features.SAMPLE_GROUP_LAB_EQUIPMENTS),
        name: SAMPLEGROUP_LAB_EQUIPMENTS,
        to: "/test-creation/samplegroup-lab-equipments"
      }
    ];

    setSetupModuleMenus([...setupModuleMenuList]);
    setTestCreationMenus([...testCreationMenuList]);

    DispatchCloseProgress();

  }, [props.userInfo]);

  useEffect(() => {
    if (windowSize.width <= 768 || (window.orientation === 90 || window.orientation === -90)) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [windowSize]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (isMobileView ? <MobilelView
    setupModuleMenuList={setupModuleMenus}
    testCreationMenuList={testCreationMenus}
  /> : <GeneralView
    setupModuleMenuList={setupModuleMenus}
    testCreationMenuList={testCreationMenus}
  />);

}

const mapStateToProps = state => {

  return {
    userInfo: state.rbacReducer.userInfo
  };
};

export default connect(mapStateToProps, {})(HeaderMenu);