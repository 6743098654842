import { LIST_ALL_SAMPLES } from "../../../../constant/actionType";

const initialState = {
  sampleSummary: null
};

function SampleReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_SAMPLES:
      return {
        ...state,
        sampleSummary: action.sampleSummary
      };
    default:
      return state;
  }
}

export default SampleReducer;
