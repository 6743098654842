
import { SG_TEST_CYCLE_MODAL_OPEN, SAMPLE_TEST_CYCLES, API_EXECUTION_STARTED } from '../../../../constant/actionType';

const initialState = {
    modelOpen: false,
    isAPIExecutionStarted: false,
    sampleTestCycles: []
};

function sampleTestCycleReducers(state = initialState, action) {
    switch (action.type) {
        case SAMPLE_TEST_CYCLES:
            return {
                ...state, sampleTestCycles: action.sampleTestCycles
            };
        case SG_TEST_CYCLE_MODAL_OPEN:
            return {
                ...state, modelOpen: action.modelOpen
            };

        case API_EXECUTION_STARTED:
            return {
                ...state, isAPIExecutionStarted: action.isAPIStarted
            };

        default:
            return state;
    }
}

export default sampleTestCycleReducers;