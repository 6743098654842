import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";
import QrReader from 'react-qr-reader2'
import {
    REVIEW_COMPLETED, PENDING_APPROVAL, REVIEW_AUTO_COMPLETED
} from "../../../../shared-component/utilities/dataFormat";
import {
    BACKDROP_CLICK,
    QR_CODE
} from "../../../../constant/appStringConstant";
import { CloseQRCodeScannerDialog, OpenScannedSamplegroup } from '../actions/qrCodeAction';
import {
    DispatchOpenProgress,
    DispatchCloseProgress
} from "../../../../shared-component/progressDialog/actions/progressDialogAction";
import { ExceptionHandler } from "../../../../shared-component/utilities/exceptionHandler";
import { useState } from 'react';
import { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                 <Tooltip title="Close">
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton></Tooltip>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const QRCodeScannerDialog = props => {

    let isScanCompleted = false;

    const handleScan = async (result, _) => {
        try {
            if (isScanCompleted || !result || result.trim().length === 0) {
                return;
            }

            isScanCompleted = true;
            DispatchOpenProgress();
            const samplegroup = JSON.parse(result.trim());

            if (samplegroup) {
                if (samplegroup.Type == "TestSummary") {
                    sessionStorage.setItem(QR_CODE.scannedQRCodeTestSGSumaryId, samplegroup.sampleGroupId);

                    let navigateUrl = "/test-creation/test-request-summary";
                    window.location = navigateUrl;
                    CloseQRCodeScannerDialog();
                    return;
                }
                const samplegroupQRCodeDetails = await OpenScannedSamplegroup(samplegroup.sampleGroupId);

                if (samplegroupQRCodeDetails && samplegroupQRCodeDetails.statusId > 0) {

                    let navigateUrl = "";

                    if (samplegroupQRCodeDetails.statusId === REVIEW_COMPLETED || samplegroupQRCodeDetails.statusId === REVIEW_AUTO_COMPLETED
                        || samplegroupQRCodeDetails.statusId === PENDING_APPROVAL) {

                        if (samplegroup.sampleId && samplegroup.sampleId > 0) {
                            navigateUrl = "/review/sample-group-review/";
                            navigateUrl += "?sampleGroupId=" + samplegroup.sampleGroupId.toString().trim();
                            navigateUrl += "&sampleId=" + samplegroup.sampleId.toString().trim();
                        } else {
                            sessionStorage.setItem(QR_CODE.scannedQRCodeSGId, samplegroup.sampleGroupId);
                            sessionStorage.setItem(QR_CODE.scannedQRCodeSGStatus, samplegroupQRCodeDetails.statusId);

                            navigateUrl = "/review/approval-landing";
                        }
                    } else {
                        if (samplegroup.sampleId && samplegroup.sampleId > 0) {
                            navigateUrl = "/test-creation/samplegroup/sample/tests";
                            navigateUrl += "?sampleGroupId=" + samplegroup.sampleGroupId.toString().trim();
                            navigateUrl += "&sampleTypeId=" + samplegroupQRCodeDetails.sampleTypeId.toString().trim();
                            navigateUrl += "&sampleId=" + samplegroup.sampleId.toString().trim();
                        } else {
                            sessionStorage.setItem(QR_CODE.scannedQRCodeSGId, samplegroup.sampleGroupId);
                            sessionStorage.setItem(QR_CODE.scannedQRCodeSGStatus, samplegroupQRCodeDetails.statusId);

                            navigateUrl = "/test-creation/sample-group";
                        }
                    }

                    window.location = navigateUrl;
                    CloseQRCodeScannerDialog();
                }
            }
            isScanCompleted = false;
            DispatchCloseProgress();
        }
        catch (ex) {
            let exceptionHandler = new ExceptionHandler();
            exceptionHandler.PushAPIErrorMessage(ex);

            DispatchCloseProgress();
            isScanCompleted = false;
        }
    }
    useEffect(() => {
        if (props.isQRCodeScannerDialogOpen) {
            setshowViewFinder(true);
        }
    }, [props.isQRCodeScannerDialogOpen])
    const [showViewFinder, setshowViewFinder] = useState(false);
    const handleError = _ => { }

    const handleClose = _ => {
        setshowViewFinder(false);
        CloseQRCodeScannerDialog();
    }

    const previewStyle = {
        height: 400,
        width: 400,
        display: "flex",
        justifyContent: "center",
        padding: "16px"
    }

    const camStyle = {
        display: "flex",
        justifyContent: "center",
        marginTop: '-50px'
    }

    return (
        <div>
            <Dialog

                disableBackdropClick={false}
                disableEscapeKeyDown={true}
                onClose={(event, reason) => {
                    if (reason !== BACKDROP_CLICK) {
                        handleClose()
                    }
                }} open={props.isQRCodeScannerDialogOpen}
                fullWidth={true} maxWidth="sm">
                <DialogTitle onClose={(event, reason) => {
                    if (reason !== BACKDROP_CLICK) {
                        handleClose()
                    }
                }}>
                    {QR_CODE.scanDialogTitle}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} direction="column" justify="center" alignItems="center">
                        <Grid item md={12}>
                            <div style={camStyle}>

                                <QrReader
                                    showViewFinder={showViewFinder}
                                    delay={500}
                                    onError={handleError}
                                    onScan={handleScan}
                                    style={previewStyle}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isQRCodeScannerDialogOpen: state.qrCodeReducer.isQRCodeScannerDialogOpen
    };
};

export default connect(mapStateToProps, {})(QRCodeScannerDialog);


