import {
    LAB_EQUIPMENT, LAB_EQUIPMENT_MODAL_OPEN, LABS, EQUIPMENTS, IS_LAB_EQUIPMENT_EXISTS, CONTACT_PERSON_EMAILS_MODEL
} from "../../../../constant/actionType";

const initialState = {
    labEquipments: [],
    modelOpen: false,
    labs: [],
    equipments: [],
    isLabEquipmentExists: null,
    contactPersonEmailsModel: false
};

function labEquipmentReducers(state = initialState, action) {
    switch (action.type) {
        case LAB_EQUIPMENT:
            return {
                ...state,
                labEquipments: action.labEquipments
            };
        case LAB_EQUIPMENT_MODAL_OPEN:
            return {
                ...state,
                modelOpen: action.modelOpen
            };
        case LABS:
            return {
                ...state,
                labs: action.labs
            };
        case EQUIPMENTS:
            return {
                ...state,
                equipments: action.equipments
            };
        case IS_LAB_EQUIPMENT_EXISTS:
            return {
                ...state,
                isLabEquipmentExists: action.isLabEquipmentExists
            };
        case CONTACT_PERSON_EMAILS_MODEL:
            return {
                ...state,
                contactPersonEmailsModel: action.contactPersonEmailsModel
            };
        default:
            return state;
    }
}

export default labEquipmentReducers;
