import {
    OPEN_BARCODE_SCANNER_DIALOG, CLOSE_BARCODE_SCANNER_DIALOG
} from "../../../constant/actionType";

const initialState = {
    isBarcodeScannerDialogOpen: false
};

function barcodeReducer(state = initialState, action) {
    switch (action.type) {

        case OPEN_BARCODE_SCANNER_DIALOG:
            return {
                ...state,
                message: null,
                isBarcodeScannerDialogOpen: true
            };

        case CLOSE_BARCODE_SCANNER_DIALOG:
            return {
                ...state,
                isBarcodeScannerDialogOpen: false
            };

        default:
            return state;
    }
}

export default barcodeReducer;