import {
  LIST_SAMPLE_GROUP_REVIEW_FILE, SAMPLEGROUP_FILE_DIALOG_OPEN_CLOSE, SAMPLEGROUP_FILECOUNT
} from "../../../../constant/actionType";

const initialState = {
  sampleGroupFilePath: [],
  isFileDialogOpen: false
};

function reviewReportReducers(state = initialState, action) {
  switch (action.type) {

    case LIST_SAMPLE_GROUP_REVIEW_FILE:

      return {

        ...state, sampleGroupFilePath: action.sampleGroupFilePath
      };
    case SAMPLEGROUP_FILECOUNT:

      return {

        ...state, sampleGroupFileCount: action.sampleGroupFileCount
      };
    case SAMPLEGROUP_FILE_DIALOG_OPEN_CLOSE:
      return {
        ...state, isFileDialogOpen: action.isFileDialogOpen
      };

    default:
      return state;
  }
}
export default reviewReportReducers;
