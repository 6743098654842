import {
    OPEN_QR_CODE_DIALOG, CLOSE_QR_CODE_DIALOG, OPEN_QR_CODE_SCANNER_DIALOG, CLOSE_QR_CODE_SCANNER_DIALOG
} from "../../../../constant/actionType";

const initialState = {
    isQRCodeGenDialogOpen: false,
    isQRCodeScannerDialogOpen: false,
};

function qrCodeReducer(state = initialState, action) {
    switch (action.type) {

        case OPEN_QR_CODE_DIALOG:
            return {
                ...state,
                isQRCodeGenDialogOpen: true,
            };

        case CLOSE_QR_CODE_DIALOG:
            return {
                ...state,
                isQRCodeGenDialogOpen: false,
            };

        case OPEN_QR_CODE_SCANNER_DIALOG:
            return {
                ...state,
                isQRCodeScannerDialogOpen: true
            };

        case CLOSE_QR_CODE_SCANNER_DIALOG:
            return {
                ...state,
                isQRCodeScannerDialogOpen: false
            };

        default:
            return state;
    }
}

export default qrCodeReducer;
