import React from "react";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff"
  }
}));

const ProgressDialog = props => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.dialogOpenCount > 0}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const mapStateToProps = state => {
  return {
    isOpenProgressDialog: state.progressDialogReducer.isOpenProgressDialog,
    dialogOpenCount: state.progressDialogReducer.dialogOpenCount
  };
};
export default connect(mapStateToProps, {})(ProgressDialog);
