import store from "../../../../store/store";
import {
  SAMPLE_GROUPS, SELECTED_SAMPLE_GROUPS, SELECTED_SAMPLE_GROUPS_PAGE_OPEN, REFRESH_SAMPLEGROUP_TABLE,
  SAMPLE_GROUP_STATUS, FILTERS_LABS, FILTERS_SKUS, LAB_API_CALLING, SKU_API_CALLING,
  FILTERS_SKUS_POPUP, FILTERS_LABS_POPUP, SAMPLE_GROUP_SUMMARY_FILTER, FILTERS_VENDORS, FILTERS_VENDORS_POPUP, VENDOR_API_CALLING,IS_UPLOAD_LOADER
} from "../../../../constant/actionType";
import {
  SampleGroupApi, SampleGroupResultUpdateApi, SampleGroupStatusUpdateApi,
  SampleGroupExportApi, SampleGroupDeleteApi, fetchAllStatusApi, FilterFieldSkusApi, FilterFieldLabsApi, FilterFieldVendorsApi,SaveSampleGroupUploadUrl
} from "../urlConstant";
import {
  DispatchCloseProgress,
  DispatchOpenProgress
} from "../../../../shared-component/progressDialog/actions/progressDialogAction";
import { FetchApiMethods } from "../../../../shared-component/utilities/fetchApiMethods";
import {
  DispatchAlertSuccess, DispatchAlertWarning
} from "../../../../shared-component/alert/actions/alertAction";
import {
  SAMPLE_GROUP_RESULT_UPDATED,
  SAMPLE_GROUP_STATUS_UPDATED,
  SAMPLE_GROUP_DELETED,
  NO_LAB_FOUND,
  NO_SKU_FOUND,
  NO_VENDOR_FOUND,SAMPLE_GROUP_BULK_UPLOAD_SAVED,UPLOAD_FILE_ERRORS,SAMPLE_GROUP_BULK_UPLOAD_CTMS_UPLOAD
} from "../../../../shared-component/utilities/alertMessage";
import { ExceptionHandler } from "../../../../shared-component/utilities/exceptionHandler";
import { DispatchBulkUploadModalOpen } from '../../../../shared-component/bulk-upload/actions/action';

const ajaxCalls = new FetchApiMethods();
const dispatchSampleGroups = sampleGroups => {
  store.dispatch({
    type: SAMPLE_GROUPS,
    sampleGroups: sampleGroups
  });
};

export const DispatchRefreshTable = (isRefreshTable) => {
  store.dispatch({
    type: REFRESH_SAMPLEGROUP_TABLE,
    isRefreshTable: isRefreshTable
  });
};

export const DispatchSelectedRows = rows => {
  store.dispatch({
    type: SELECTED_SAMPLE_GROUPS,
    selectedRows: rows
  });
};

export const DispatchOpenSelectedPageOpen = isOpen => {
  store.dispatch({
    type: SELECTED_SAMPLE_GROUPS_PAGE_OPEN,
    isSelectedPageOpen: isOpen
  });
};

export const DispatchUploadLoader = (isUploadLoader) => {
  store.dispatch({
    type: IS_UPLOAD_LOADER,
    isUploadLoader: isUploadLoader
  });
};

export const FetchAllSampleGroup = (apiRequestModel) => {
  return new Promise((resolve, reject) => {
    const sampleGroups = {
      data: [],
      page: 0,
      totalCount: 0
    };
    const url = SampleGroupApi();
    const ajaxCalls = new FetchApiMethods();

    ajaxCalls
      .postMethodOKStatus(url, apiRequestModel)
      .then(json => {

        sampleGroups.page = apiRequestModel.pageNo;
        sampleGroups.totalCount = json.totalCount;
        sampleGroups.data = json.data;

        dispatchSampleGroups(sampleGroups);
        resolve(sampleGroups);
      })
      .catch(e => {
        dispatchSampleGroups(sampleGroups);

        const exceptionHandler = new ExceptionHandler();
        exceptionHandler.PushAPIErrorMessage(e);
        resolve(sampleGroups);
      });
  });
};

export const UpdateResult = (apiModel) => {
  DispatchOpenProgress();
  const ajaxCalls = new FetchApiMethods();
  const url = SampleGroupResultUpdateApi();

  ajaxCalls
    .patchMethod(url, apiModel)
    .then(json => {
      DispatchAlertSuccess(SAMPLE_GROUP_RESULT_UPDATED);
      DispatchCloseProgress();
      DispatchRefreshTable(true);
    })
    .catch(e => {
      DispatchCloseProgress();

      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
    });
};

export const UpdateStatus = (apiModel) => {
  const ajaxCalls = new FetchApiMethods();
  const url = SampleGroupStatusUpdateApi();

  ajaxCalls
    .patchMethod(url, apiModel)
    .then(json => {
      DispatchAlertSuccess(SAMPLE_GROUP_STATUS_UPDATED);
      DispatchCloseProgress();
      DispatchSelectedRows([]);
      DispatchRefreshTable(true);
    })
    .catch(e => {
      DispatchCloseProgress();

      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
    });
};

export const ExportSampleGroups = () => {
  DispatchOpenProgress();
  const ajaxCalls = new FetchApiMethods();
  const url = SampleGroupExportApi();

  ajaxCalls
    .downloadFile(url)
    .then(response => response.body)
    .then(stream => new Response(stream, { headers: { 'Content-Type': 'text/csv' } }))
    .then(response => response.blob())
    .then(blob => {
      let fileName = 'SampleGroups.csv';      //TODO: Take from API
      let objectUrl = window.URL.createObjectURL(blob);
      let anchor = document.createElement("a");

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
      DispatchCloseProgress();
    })
    .catch(e => {
      DispatchCloseProgress();

      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
    });


};

export const DeleteSampleGroup = (sampleGroupId) => {
  DispatchOpenProgress();
  const ajaxCalls = new FetchApiMethods();
  const url = SampleGroupDeleteApi(sampleGroupId);
  ajaxCalls
    .deleteMethod(url)
    .then(json => {
      DispatchAlertSuccess(SAMPLE_GROUP_DELETED);
      DispatchCloseProgress();
      DispatchRefreshTable(true);
    })
    .catch(e => {
      DispatchCloseProgress();
      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
    });
}


export const FetchAllStatus = () => {
  DispatchOpenProgress();
  const ajaxCalls = new FetchApiMethods();
  let url = fetchAllStatusApi();
  ajaxCalls
    .getMethod(url)
    .then(json => {
      dispathAllStatus(json);
      DispatchCloseProgress();
    })
    .catch(e => {
      dispathAllStatus([]);
      DispatchCloseProgress();
      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
    });
};


const dispathAllStatus = sampleGroupStatus => {
  store.dispatch({
    type: SAMPLE_GROUP_STATUS,
    sampleGroupStatus: sampleGroupStatus
  });
}



export const FetchFilterFieldLabs = (labModel) => {

  DispatchFilterFieldLabHappen(true);
  const url = FilterFieldLabsApi();
  const ajaxCalls = new FetchApiMethods();
  ajaxCalls
    .postMethodOKStatus(url, labModel)
    .then(json => {


      if (json.length > 0) {
        DispatchFilterFieldLab(json);
      } else {
        DispatchFilterFieldLab([]);
        DispatchAlertWarning(NO_LAB_FOUND);
      }
      DispatchLabAPICalling(false);
    })
    .catch(e => {

      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
      DispatchLabAPICalling(false);
    });
};

export const FetchFilterFieldVendors = (vendorModel) => {
  DispatchFilterFieldVendorHappen(true);
  const url = FilterFieldVendorsApi();
  const ajaxCalls = new FetchApiMethods();
  ajaxCalls
    .postMethodOKStatus(url, vendorModel)
    .then(json => {


      if (json.length > 0) {
        DispatchFilterFieldVendor(json);
      } else {
        DispatchFilterFieldVendor([]);
        DispatchAlertWarning(NO_VENDOR_FOUND);
      }
      DispatchVendorAPICalling(false);
    })
    .catch(e => {

      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
      DispatchVendorAPICalling(false);
    });
};

export const DispatchFilterFieldLab = filterFieldLabs => {
  store.dispatch({
    type: FILTERS_LABS,
    filterFieldLabs: filterFieldLabs
  });
}

export const DispatchFilterFieldVendor = filterFieldVendors => {
  store.dispatch({
    type: FILTERS_VENDORS,
    filterFieldVendors: filterFieldVendors
  });
}

export const FetchFilterFieldSku = (skuModel) => {

  DispatchSkuAPICalling(true);
  DispatchFilterFieldSkuHappen(true);

  const url = FilterFieldSkusApi();
  const ajaxCalls = new FetchApiMethods();

  ajaxCalls
    .postMethodOKStatus(url, skuModel)
    .then(json => {
      if (json.length > 0) {
        DispatchFilterFieldSku(json);
      } else {
        DispatchFilterFieldSku([]);
        DispatchAlertWarning(NO_SKU_FOUND);
      }

      DispatchSkuAPICalling(false);
    })
    .catch(e => {
      const exceptionHandler = new ExceptionHandler();
      exceptionHandler.PushAPIErrorMessage(e);
      DispatchSkuAPICalling(false);
    });
};

export const DispatchFilterFieldSku = filterFieldSkus => {
  store.dispatch({
    type: FILTERS_SKUS,
    filterFieldSkus: filterFieldSkus
  });
}

export const DispatchFilterFieldSkuHappen = skuPopupOpen => {
  store.dispatch({
    type: FILTERS_SKUS_POPUP,
    skuPopupOpen: skuPopupOpen
  });
}

export const DispatchFilterFieldLabHappen = labPopupOpen => {
  store.dispatch({
    type: FILTERS_LABS_POPUP,
    labPopupOpen: labPopupOpen
  });
}

export const DispatchFilterFieldVendorHappen = vendorPopupOpen => {
  store.dispatch({
    type: FILTERS_VENDORS_POPUP,
    vendorPopupOpen: vendorPopupOpen
  });
}

export const DispatchLabAPICalling = labAPICalling => {
  store.dispatch({
    type: LAB_API_CALLING,
    labAPICalling: labAPICalling
  });
}

export const DispatchVendorAPICalling = vendorAPICalling => {
  store.dispatch({
    type: VENDOR_API_CALLING,
    vendorAPICalling: vendorAPICalling
  });
}

export const DispatchSkuAPICalling = skuAPICalling => {
  store.dispatch({
    type: SKU_API_CALLING,
    skuAPICalling: skuAPICalling
  });
}

export const DispatchSummaryFilters = summaryFilterObject => {
  store.dispatch({
    type: SAMPLE_GROUP_SUMMARY_FILTER,
    data: summaryFilterObject
  });
}

export const SaveSampleGroupBulkUpload = async (uploadFile) => {
  // DispatchOpenProgress();
  DispatchUploadLoader(true);
  DispatchBulkUploadModalOpen(false);
  DispatchAlertSuccess(SAMPLE_GROUP_BULK_UPLOAD_CTMS_UPLOAD);
  const url = SaveSampleGroupUploadUrl();
  const formData = new FormData();
  formData.append('File', uploadFile);
  await ajaxCalls
      .downloadFilePostFormDataMethod(url, formData)
      .then(response => response.body)
      .then(stream => new Response(stream, { headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } }))
      .then(response => response.blob())
      .then(blob => {

          if (blob.size == 0) {
              DispatchAlertSuccess(SAMPLE_GROUP_BULK_UPLOAD_SAVED);
              DispatchBulkUploadModalOpen(false);
              DispatchUploadLoader(false);
              DispatchRefreshTable(true);
              DispatchCloseProgress();
          }
          else {
              const objectUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement("a");

              anchor.href = objectUrl;
              anchor.download = "Validate Sample Group.xlsx";
              anchor.click();
              window.URL.revokeObjectURL(objectUrl);

              DispatchAlertWarning(UPLOAD_FILE_ERRORS);
              DispatchUploadLoader(false);
              DispatchCloseProgress();
          }

      })
      .catch(e => {
          DispatchUploadLoader(false);
          DispatchCloseProgress();
          const exceptionHandler = new ExceptionHandler();
          exceptionHandler.PushAPIErrorMessage(e);
      });
}

