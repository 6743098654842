import { FetchAccessToken } from "../authentication/tokenGenerator";

export class FetchApiMethods {
  getFiles = url => {
    return new Promise(function (resolve, reject) {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(function (response) {
          if (response.status === 404 || response.status === 500 || response.status === 504 || response.status === 502) {
            reject(response);
          }
          else {
            resolve(response);
          }
        })
        .catch(function (err) {
          reject(err);
        });
    })
  }

  getMethod(url, consistencyLevel) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url)
        .then(function (accessToken) {
          fetch(url, {
            method: "GET",
            headers: (consistencyLevel === 'eventual') ? {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json",
              'ConsistencyLevel': 'eventual'
            } : {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json"
            }
          })
            .then(function (response) {
              const status = response.status;

              response
                .json()
                .then(json => {
                  if (status === 200) {
                    resolve(json);
                  } else {
                    reject(json);
                  }
                })
                .catch(function (err) {
                  reject(err);
                });
            })
            .catch(function (err) {
              reject({
                StatusText: err
              });
            });
        })
        .catch(function (err) {
          reject({
            StatusText: err
          });
        });
    });
  }

  downloadFile(url) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url)
        .then(function (accessToken) {
          fetch(url, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json"
            }
          })
            .then(function (response) {
              if (response.status === 404 || response.status === 500) {
                response
                  .json()
                  .then(json => {
                    reject(json);
                  })
                  .catch(function (err) {
                    reject(err);
                  });

                return;
              }

              resolve(response);
            })
            .catch(function (err) {
              reject({
                StatusText: err
              });
            });
        })
        .catch(function (err) {
          reject({
            StatusText: err
          });
        });
    });
  }

  downloadPowerBIReportPostMethod(url, requestModel) {
    return new Promise(function (resolve, reject) {
      fetch(url, {
        method: "POST",
        body: JSON.stringify(requestModel),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(function (response) {
          if (response.status === 404 || response.status === 500 || response.status === 504 || response.status === 502) {
            response
              .json()
              .then(json => {
                reject(json);
              })
              .catch(function (err) {
                reject(err);
              });
          }
          else {
            resolve(response);
          }
        })
        .catch(function (err) {
          reject({
            StatusText: err
          });
        });
    })
  }

  downloadFilePostMethod(url, requestModel) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url)
        .then(function (accessToken) {
          fetch(url, {
            method: "POST",
            body: JSON.stringify(requestModel),
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json"
            }
          })
            .then(function (response) {
              if (response.status === 404 || response.status === 500) {
                response
                  .json()
                  .then(json => {
                    reject(json);
                  })
                  .catch(function (err) {
                    reject(err);
                  });

                return;
              }

              resolve(response);
            })
            .catch(function (err) {
              reject({
                StatusText: err
              });
            });
        })
        .catch(function (err) {
          reject({
            StatusText: err
          });
        });
    });
  }

  downloadFilePostFormDataMethod(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url)
        .then(function (accessToken) {
          fetch(url, {
            method: "POST",
            body: param,
            headers: {
              Authorization: "Bearer " + accessToken
            }
          })

            .then(function (response) {
              const status = response.status;

              if (response.status === 400 || response.status === 404 || response.status === 500) {
                response
                  .json()
                  .then(json => {

                    reject(json);
                  })
                  .catch(function (err) {
                    reject(err);
                  });

                return;
              }
              // if (response.status === 201) {
              //   response.json()
              //     .then(json => {
              //     resolve(json);
              //     })
              // }
              resolve(response);
            })
            .catch(function (err) {
              reject({
                StatusText: err
              });
            });
        })
        .catch(function (err) {
          reject({
            StatusText: err
          });
        });
    });
  }

  postFormDataMethod(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "POST",
          body: param,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
          .then(response => {
            const status = response.status;

            response
              .json()
              .then(json => {
                if (status === 201) {
                  resolve(json);
                } else {
                  reject(json);
                }
              })
              .catch(function (err) {
                reject(err);
              });
          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }

  postMethod(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "POST",
          body: JSON.stringify(param),
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            const status = response.status;

            response
              .json()
              .then(json => {
                if (status === 201) {
                  resolve(json);
                } else {
                  reject(json);
                }
              })
              .catch(function (err) {
                reject(err);
              });
          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }

  postMethodOKStatus(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "POST",
          body: JSON.stringify(param),
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            const status = response.status;

            response
              .json()
              .then(json => {
                if (status === 200) {
                  resolve(json);
                } else {
                  reject(json);
                }
              })
              .catch(function (err) {
                reject(err);
              });
          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }


  postMethodWithEmptyResponse(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "POST",
          body: JSON.stringify(param),
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            let status = response.status;
            if (status === 200) {
              resolve(null);
            }
            else {
              reject({
                StatusText: response.status,
                ErrorMessage: response.statusText
              });
            }

          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }

  putMethod(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "PUT",
          body: JSON.stringify(param),
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            let status = response.status;

            if (status === 404) {
              reject({
                StatusText: response.status,
                ErrorMessage: response.statusText
              });
              return;
            }
            response
              .json()
              .then(json => {
                if (status === 200) {
                  resolve(json);
                } else {
                  reject(json);
                }
              })
              .catch(function (err) {
                reject(err);
              });
          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }

  patchMethod(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "PATCH",
          body: JSON.stringify(param),
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => {

            const status = response.status;
            response
              .json()
              .then(json => {
                if (status === 200) {
                  resolve(json);
                } else {
                  reject(json);
                }
              })
              .catch(function (err) {
                reject(err);
              });
          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }

  deleteMethod(url, param) {
    return new Promise(function (resolve, reject) {
      FetchAccessToken(url).then(function (accessToken) {
        fetch(url, {
          method: "DELETE",
          body: JSON.stringify(param),
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            let status = response.status;
            if (status === 404) {
              reject({
                StatusText: response.status,
                ErrorMessage: response.statusText
              });
              return;
            }
            response
              .json()
              .then(json => {
                if (status === 200) {
                  resolve(json);
                } else {
                  reject(json);
                }
              })
              .catch(function (err) {
                reject(err);
              });
          })
          .catch(e => {
            reject({
              StatusText: e
            });
          });
      });
    });
  }
}
