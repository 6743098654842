import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Features } from "../rbac/constants/roleConstant";
import NoMatch from "./noMatch";
import AccessDenied from "./accessDenied";

const Dashboard = React.lazy(() => import("../../modules/dashboard/status/components/index"));
const Lab = React.lazy(() => import("../../modules/setup/lab/components/summaryPages"));
const SampleType = React.lazy(() => import("../../modules/setup/sample-type/components/summaryPages"));
const Equipment = React.lazy(() => import("../../modules/setup/equipment/components/summaryPages"));
const LabEquipment = React.lazy(() => import("../../modules/setup/lab-equipment-mapping/components/summaryPage"));
const Vendor = React.lazy(() => import("../../modules/setup/Vendor/components/summaryPages"));
const SkuSetup = React.lazy(() => import("../../modules/setup/sku-setup/components/skuLandingPage"));
const HeaderFieldSummary = React.lazy(() => import("../../modules/setup/header-field/component/summaryPages"));
const TestType = React.lazy(() => import("../../modules/setup/test-type/components/summaryPages"));
const Question = React.lazy(() => import("../../modules/setup/question/component/summaryPages"));
const QuestionContrainst = React.lazy(() => import("../../modules/setup/question-constraints/components/index"));
const SampleGroup = React.lazy(() => import("../../modules/test-creation/sample-group/components/summaryPages"));
const Sample = React.lazy(() => import("../../modules/test-creation/sample-landing/components/summaryPages"));
const SampleTestTypes = React.lazy(() => import("../../modules/test-creation/sample-test/components/sampleTestTypes"));
const TestQuestionViewBySample = React.lazy(() => import("../../modules/test-creation/test-question-sample/component/testQuestionViewBySample"));
const SampleGroupCreatePage = React.lazy(() => import("../../modules/test-creation/sample-group-creation/components/sampleGroupPage"));
const TestRequestForm = React.lazy(() => import("../../modules/test-creation/test-request-form/components/createTestRequest"));
const TestRequestSummary = React.lazy(() => import("../../modules/test-creation/test-request-summary/components/testRequestSummary"));
const SampleGroupLabEquipment = React.lazy(() => import("../../modules/test-creation/sample-group-lab-equipments/components/sampleGroupLabEquipment"));
const LandingApproval = React.lazy(() => import("../../modules/review/approval-landing/components/summaryPages"));
const ReviewSamplePage = React.lazy(() => import("../../modules/review/samples-page/components/reviewSamples"));
const SampleGroupReview = React.lazy(() => import("../../modules/review/sample-group-review/components/sampleGroupReview"));
const AutoTestRequestConfiguration = React.lazy(() => import("../../modules/setup/auto-test-creation-mapping/components/index"));
const SkuDatabase = React.lazy(() => import("../../modules/setup/sku-database/components/summaryPages"));

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const PageLoader = () => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

const PageRouterView = props => {

    const isFeaturePermissionAvailable = (featureName) =>
        props.roles && props.roles?.featurePermissions.find(element => element.featureName === featureName);

    return (
        <React.Fragment>

            <Routes>
                <Route exact path="/" >
                    <Route exact path="/" element={<Navigate to="/dashboard" replace={true} />} />

                    <Route exact path="dashboard" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_DASHBOARD) ?
                                    <Dashboard /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/lab" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB) ?
                                    <Lab /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />


                    <Route exact path="/setup/sample-type" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_SAMPLE_TYPE) ?
                                    <SampleType /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/vendor" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_VENDOR) ?
                                    <Vendor /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/equipment" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_EQIUPMENT) ?
                                    <Equipment /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/lab-equipment-mapping" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB_EQUIPMENT_MAPPING) ?
                                    <LabEquipment /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/sku" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB_EQUIPMENT_MAPPING) ?
                                    <SkuSetup /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/header-field" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB_EQUIPMENT_MAPPING) ?
                                    <HeaderFieldSummary /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/test-type" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB_EQUIPMENT_MAPPING) ?
                                    <TestType /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/question" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB_EQUIPMENT_MAPPING) ?
                                    <Question /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/question-constraint" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_LAB_EQUIPMENT_MAPPING) ?
                                    <QuestionContrainst /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/sample-group" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.SAMPLE_GROUP) ?
                                    <SampleGroup /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/test-question-sample" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_TEST_QUESTION) ?
                                    <TestQuestionViewBySample /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/samplegroup/sample/tests" element={
                        <React.Suspense fallback={<PageLoader />}>
                            <SampleTestTypes />
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/samplegroup/samples" element={
                        <React.Suspense fallback={<PageLoader />}>
                            <Sample />
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/create-test" element={
                        <React.Suspense fallback={<PageLoader />}>
                            <SampleGroupCreatePage />
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/test-request-summary" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_TEST_REQUEST_SUMMARY) ?
                                    <TestRequestSummary /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/test-request-form" element={
                        <React.Suspense fallback={<PageLoader />}>
                            <TestRequestForm />
                        </React.Suspense>
                    } />

                    <Route exact path="/test-creation/samplegroup-lab-equipments" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.SAMPLE_GROUP) ?
                                    <SampleGroupLabEquipment /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/review/approval-landing" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_APPROVAL_LANDING) ?
                                    <LandingApproval /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />
                    <Route exact path="/setup/sku-database" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.APPROVED_SKU_DATABASE) ?
                                    <SkuDatabase /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />
                    <Route exact path="/review/sample-group-review" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_TEST_REVIEW) ?
                                    <SampleGroupReview /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/review/samples" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.GLS_APPROVAL_LANDING_SAMPLES) ?
                                    <ReviewSamplePage /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route exact path="/setup/auto-test-creation-mapping" element={
                        <React.Suspense fallback={<PageLoader />}>
                            {
                                isFeaturePermissionAvailable(Features.AUTO_TEST_REQUEST_MAPPING) ?
                                    <AutoTestRequestConfiguration /> : <AccessDenied />
                            }
                        </React.Suspense>
                    } />

                    <Route
                        path="*"
                        element={
                            <NoMatch />
                        }
                    />

                </Route>

            </Routes>

        </React.Fragment>
    );
};

export default PageRouterView;
