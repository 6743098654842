
import {
    LIST_ALL_HEADER_FIELD, LIST_ALL_UNIT_TYPE, LIST_ALL_DATA_TYPE,
    LIST_ALL_INPUT_TYPE, SELECTED_HEADER_FIELD, SELECTED_HEADER_FIELD_OPTION, HEADER_FIELD_MODAL_OPEN, PREDEFINED_OPTIONS, SELECTED_SAMPLE_TYPE, SAMPLE_TYPE_BY_HEADER, HEADER_SAMPLE_TYPE_MODAL_OPEN
} from '../../../../constant/actionType';

const initialState = {
    listAllHeaderField: [],
    unitTypes: [],
    dataTypes: [],
    inputTypes: [],
    selectedHeaderField: {},
    questionOptions: [],
    preDefinedDatasets: [],
    modelOpen: false,
    selectedSampleType: 0,
    sampleTypesByHeader: [],
    headerSampleTypeModelOpen: false,
};


function headerFieldReducers(state = initialState, action) {
    switch (action.type) {
        case PREDEFINED_OPTIONS:
            return {
                ...state,
                preDefinedDatasets: action.preDefinedDatasets
            };
        case LIST_ALL_HEADER_FIELD:
            return {
                ...state,
                listAllHeaderField: action.listAllHeaderField
            };
        case SELECTED_HEADER_FIELD:
            return {
                ...state,
                selectedHeaderField: action.selectedHeaderField
            };
        case SELECTED_HEADER_FIELD_OPTION:
            return {
                ...state,
                questionOptions: action.questionOptions
            };
        case LIST_ALL_UNIT_TYPE:
            return {
                ...state,
                unitTypes: action.unitTypes
            };
        case LIST_ALL_DATA_TYPE:
            return {
                ...state,
                dataTypes: action.dataTypes
            };
        case LIST_ALL_INPUT_TYPE:
            return {
                ...state,
                inputTypes: action.inputTypes
            };
        case HEADER_FIELD_MODAL_OPEN:
            return {
                ...state, modelOpen: action.modelOpen
            };

        case SELECTED_SAMPLE_TYPE:
            return {
                ...state, selectedSampleType: action.selectedSampleType
            };
        case SAMPLE_TYPE_BY_HEADER:
            return {
                ...state, sampleTypesByHeader: action.sampleTypesByHeader
            };
        case HEADER_SAMPLE_TYPE_MODAL_OPEN:
            return {
                ...state, headerSampleTypeModelOpen: action.headerSampleTypeModelOpen
            };
        default:
            return state;
    }
}

export default headerFieldReducers;