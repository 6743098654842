import {
    LAB_EQUIPMENT, LAB_EQUIPMENT_MODAL_OPEN, LABS, EQUIPMENTS, IS_LAB_EQUIPMENT_EXISTS, CONTACT_PERSON_EMAILS_MODEL, SAMPLE_TYPES, LIST_ALL_SKU, AUTO_TEST_REQUEST_MODAL_OPEN, LIST_ALL_ATRC_MAPPING, SEARCH_SKUS, SKU_SAMPLE_TYPES
} from "../../../../constant/actionType";
import { ON_BEHALF_OF_USERS, SAMPLE_TYPE } from "../../../../constant/appStringConstant";

const initialState = {
    sampleTypes: [],
    isModalOpen: false,
    labs: [],
    listAllATRCMapping: [],
    skuSampleTypes: [],
    searchSkus: [],
    onBehalfOfUsers: ""
};

function autoTestRequestReducers(state = initialState, action) {
    switch (action.type) { 
        case LIST_ALL_ATRC_MAPPING:
            return {
                ...state,
                listAllATRCMapping: action.listAllATRCMapping
            };
        case SEARCH_SKUS:
                return {
                    ...state,
                    searchSkus: action.searchSkus
                };
        case SKU_SAMPLE_TYPES:
            return {
                ...state,
                skuSampleTypes: action.skuSampleTypes
            };
        case AUTO_TEST_REQUEST_MODAL_OPEN:
            return {
                ...state,
                isModalOpen: action.isModalOpen
            };
        case LABS:
            return {
                ...state,
                labs: action.labs
            };
        case ON_BEHALF_OF_USERS:
            return {
                ...state,
                onBehalfOfUsers: action.onBehalfOfUsers
            };
        default:
            return state;
    }
}

export default autoTestRequestReducers;
